input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-text-fill-color: #000;
    transition: background-color 5000s ease-in-out 0s;
    background-color: red !important;
}

.custom_checkbox {
    display: block;
    input {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;
        &:checked+label:after {
            content: '';
            display: block;
            position: absolute;
            top: 2px;
            left: 7px;
            width: 5px;
            height: 10px;
            border: solid $white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }
    }
    label {
        position: relative;
        cursor: pointer;
        &:before {
            content: '';
            -webkit-appearance: none;
            background-color: $base1;
            border: 1px solid $base1;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
            padding: 8px;
            width: 3px;
            height: 3px;
            margin-right: 8px;
            margin-top: -2px;
            display: inline-block;
            position: relative;
            vertical-align: middle;
            cursor: pointer;
            @include border-radius(2px);
        }
    }
}

.form_row {
    .bottom-info-text {
        font-size: 12px;
        opacity: 0.6;
        display: block;
        margin: 3px 0px 0px;
        .material-icons {
            font-size: 15px;
        }
    }
    .input-border-with-icon {
        .input-icon-front {
            position: absolute;
            left: 1px;
            top: 0px;
            // background: #eee;
            width: 30px;
            height: 2.9rem;
            border-radius: 3px 0px 0px 3px;
            display: flex;
            align-items: center;
            justify-content: center;
            .material-icons {
                font-size: 15px;
            }
        }
        .input_fld {
            padding-left: 30px;
        }
    }
    .input-border-with-icon-end {
        .input-icon-front {
            position: absolute;
            right: 1px;
            top: 0px;
            color: $white;
            background: $grey-text;
            width: 30px;
            height: 100%;
            border-radius: 0px 3px 3px 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            .material-icons {
                font-size: 14px;
            }
        }
        .input_fld {
            padding-right: 40px;
            text-align: left;
        }
        &.copy-section {
            .input_fld {
                background-color: #F3F3F8;
                border: 0;
            }
        }
    }
}

.form_row .input_fld {
    border: solid 1px $input-border;
    @include border-radius(0px);
    width: 100%;
    height: 3rem;
    padding: 0px 15px;
    color: $black;
    font-size: 1rem;
    font-family: $base-font;
    outline: none !important;
    background: $white;
    border-radius: 3px;
    box-sizing: border-box;
    &.small-input {
        height: 2.571rem;
    }
    &.large-input {
        height: 3.571rem;
    }
    &:hover,
    &:focus,
    &:active {
        border-color: $green;
        outline: none !important;
    }
}

.form_row textarea.input_fld {
    height: 98px;
    padding-top: 2px;
    padding-bottom: 10px;
    font-size: 1rem;
    &.md-textarea {
        height: 68px;
    }
    @include breakpoint(small) {
        line-height: 1.6rem;
    }
}

.form_row .input_area {
    border: solid 1px #999999;
    @include border-radius(3px);
    width: 100%;
    padding: 15px;
    color: $text-color;
    font-size: 1.143rem;
    resize: none;
    font-family: $base-font;
    outline: none !important;
    &:hover,
    &:focus,
    &:active {
        border-color: $base1;
        outline: none !important;
    }
}

.form_row .input_effect {
    border: 0;
    padding: 14px 0;
    border-bottom: 1px solid #7f7f7f;
    width: 100%;
    font-size: 1.143rem;
    font-weight: 400;
    outline: none !important;
    &:hover,
    &:focus,
    &:active {
        outline: none !important;
    }
    &.ng-untouched {
        ~.focus-border {
            background-color: #7f7f7f;
            width: 100%;
            left: 0;
        }
    }
    &.has_error {
        ~.focus-border {
            background-color: $red;
            width: 100%;
            left: 0;
        }
    }
    &.ng-touched {
        &.ng-invalid {
            ~.focus-border {
                background-color: $red;
                width: 100%;
                left: 0;
            }
        }
    }
    ~.focus-border {
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0;
        height: 1px;
        background-color: #7f7f7f;
        transition: 0.4s;
    }
    &:focus {
        ~.focus-border {
            width: 100%;
            transition: 0.4s;
            left: 0;
            background-color: $base1;
        }
    }
}

.form_row .ng-untouched .input_fld {
    // border: solid 1px #999999;
    &:hover,
    &:focus,
    &:active {
        border: solid 1px #999999
    }
}

.mat-checkbox-inner-container {
    width: 20px;
    height: 20px;
    .mat-checkbox-frame {
        background-color: $white !important;
        border: 2px solid $grey-text !important;
    }
}

.error-input {
    position: absolute;
    bottom: -15px;
    left: 0px;
    font-size: 0.8571rem !important;
    color: #e94242;
}

.input-border {
    .mat-form-field-appearance-fill .mat-form-field-flex {
        background: none;
        padding: 0px;
    }
    .mat-form-field {
        .mat-form-field-infix {
            border-top: 0px;
        }
        .mat-form-field-wrapper {
            padding-bottom: 0px;
            .mat-form-field-infix {
                padding: 0;
            }
            .mat-form-field-subscript-wrapper {
                display: none;
            }
        }
    }
    .mat-form-field-label-wrapper {
        display: none;
    }
    .error-input {
        bottom: -20px;
    }
}

.mat-checkbox.small-check {
    .mat-checkbox-inner-container {
        height: 15px !important;
        width: 15px !important;
        border-radius: 2px;
        .mat-checkbox-frame {
            border-radius: 2px !important;
        }
    }
    .mat-checkbox-label {
        line-height: 18px;
        position: relative;
        top: 1px;
    }
}

.radio-pricing {
    .mat-radio-button {
        &:hover,
        &:active,
        &:active:hover {
            .mat-radio-label-content {
                color: $green;
                opacity: 1;
            }
        }
        .mat-radio-container {
            .mat-radio-outer-circle {
                border-width: 2px !important;
                border-color: rgba(255, 255, 255, .7);
            }
        }
        .mat-radio-label-content {
            color: $white;
            opacity: .7;
            font-size: 16px;
            font-weight: 500;
        }
    }
}

.mat-radio-label-content {
    font-size: 1rem;
}

// to override the overflow issue in inputs
.mat-input-element {
    box-sizing: border-box;
}