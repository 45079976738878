.heading1 {
    font-size: 1.7rem;
    font-weight: 500;
    color: $title-color;
    word-break: break-word;
    @include breakpoint(small) {
        font-size: 1.5rem;
        margin: 0px 0px 15px 0px;
    }
}

.heading2 {
    font-size: 1.714rem;
    font-weight: 500;
    color: $darkgray;
    margin: 0px 0px 5px 0px;
    word-break: break-word;
    @include breakpoint(xxxlarge) {
        // font-size: 1.3rem;
    }
    @include breakpoint(small) {
        display: block;
        margin: 0px 0px 10px 0px;
    }
}

.heading3 {
    font-size: 1.2rem;
    font-weight: 400;
    color: $d_black;
    margin: 0px 0px 5px 0px;
    word-break: break-word;
}

.para {
    color: $para;
    font-size: 1.071rem;
    font-weight: 400;
    @include breakpoint(small) {
        font-size: 1.143rem;
    }
}

.page_title {
    font-size: 2rem;
    color: $black;
    font-weight: 500;
    @include breakpoint(small) {
        font-size: 1.75rem;
    }
    @include breakpoint(x-small) {
        font-size: 1.714rem;
    }
}

.fw-700 {
    font-weight: 700 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-400 {
    font-weight: 400 !important;
}

.fw-300 {
    font-weight: 300 !important;
}

.heading3 {
    .info-icon {
        font-size: 1.6rem;
        position: relative;
        top: 6px;
        color: $light-blue-color;
        margin: 0px 0px 0px 3px;
    }
}