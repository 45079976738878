.full-loader circle {
    -webkit-animation: 1.4s ease-in-out infinite both circle-animation;
    animation: 1.4s ease-in-out infinite both circle-animation;
    display: block;
    fill: transparent;
    stroke: #fff;
    stroke-linecap: round;
    stroke-dasharray: 283;
    stroke-dashoffset: 280;
    stroke-width: 6px;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
}


/* // Circle animation. */

@-webkit-keyframes circle-animation {
    0%,
    25% {
        stroke-dashoffset: 280;
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    50%,
    75% {
        stroke-dashoffset: 75;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    100% {
        stroke-dashoffset: 280;
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes circle-animation {
    0%,
    25% {
        stroke-dashoffset: 280;
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    50%,
    75% {
        stroke-dashoffset: 75;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    100% {
        stroke-dashoffset: 280;
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.full-loader svg {
    -webkit-animation: 2s linear infinite svg-animation;
    animation: 2s linear infinite svg-animation;
    max-width: 60px;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    margin: auto;
    bottom: 0px;
}

@keyframes svg-animation {
    0% {
        -webkit-transform: rotateZ(0deg);
        -ms-transform: rotate(0deg);
        transform: rotateZ(0deg);
    }
    100% {
        -webkit-transform: rotateZ(360deg);
        -ms-transform: rotate(360deg);
        transform: rotateZ(360deg);
    }
}

.full-loader {
    position: fixed;
    background: #1e96d2;
    background: -webkit-gradient(left top, right top, color-stop(0%, #1e96d2), color-stop(100%, #541cba));
    background: linear-gradient(to right, #1e96d2 0%, #541cba 100%);
    // filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#1e96d2', endColorstr='#541cba', GradientType=1);
    height: 100vh;
    width: 100%;
    z-index: 9999;
    top: 0px;
    left: 0px;
    margin: auto;
    opacity: 0.6;
}

.loader-spinner {
    -webkit-animation: load 1s infinite linear;
    -moz-animation: load 1s infinite linear;
    animation: load 1s infinite linear;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    font-size: 1.286rem;
    margin: 0px 0px 0px 0px;
    position: relative;
    display: inline-block;
    border: 3px solid #808080;
    border-top: 0px;
    border-left: 0px;
}

@-webkit-keyframes load {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes load {
    from {
        -moz-transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(360deg);
    }
}

@keyframes load {
    from {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.block-loader {
    position: relative;
    width: 42px;
    height: 50px;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
}

.block-loader .line {
    width: 6px;
    position: absolute;
    border-radius: 5px;
    bottom: 0;
    background: $green;
}

.block-loader .line1 {
    left: 0;
    -webkit-animation: line-grow 0.5s ease alternate infinite;
    animation: line-grow 0.5s ease alternate infinite;
}

.block-loader .line2 {
    left: 18px;
    -webkit-animation: line-grow 0.5s 0.2s ease alternate infinite;
    animation: line-grow 0.5s 0.2s ease alternate infinite;
}

.block-loader .line3 {
    left: 36px;
    -webkit-animation: line-grow 0.5s 0.4s ease alternate infinite;
    animation: line-grow 0.5s 0.4s ease alternate infinite;
}

@-webkit-keyframes line-grow {
    0% {
        height: 0;
    }
    100% {
        height: 75%;
    }
}

@keyframes line-grow {
    0% {
        height: 0;
    }
    100% {
        height: 75%;
    }
}

.content-loader {
    display: flex;
    align-content: center;
    justify-content: center;
    line-height: 30px;
    margin: 20px 0px 0;
    span {
        margin: 0px 0px 0px 15px;
    }
}