.table-wrapper {
    width: 100%;
    overflow: auto;
    padding: 1rem;
    &.table-style-small {
        .table-main-view {
            .mat-row {
                min-height: 30px;
                background: transparent !important;
                .mat-header-cell {
                    padding: 2px 15px;
                    height: 30px;
                    font-size: 1rem;
                    &:first-child {
                        padding-left: 0;
                    }
                }
                .mat-cell {
                    padding: 6px 15px;
                    height: 40px;
                    border: 0;
                    white-space: inherit;
                    &:first-child {
                        padding-left: 0;
                    }
                }
            }
        }
    }
    &.table-style-2 {
        .table-main-view {
            border: 1px solid #EFEFEF;
            .mat-row {
                min-height: 40px;
                .mat-header-cell {
                    background: $gray-400 !important;
                    padding: 5px 15px;
                    height: 40px;
                }
                .mat-cell {
                    padding: 6px 15px;
                    height: 40px;
                }
            }
        }
    }
    &.main-table-view {
        padding: 0px 0px 10px;
        .table-main-view {
            box-shadow: 0 4px 5px -3px rgba(0, 0, 0, .2);
            border-radius: 0px 0px 4px 4px;
            overflow: hidden;
            .mat-row {
                &:first-child {
                    background: $light_base2 !important;
                    .mat-header-cell {
                        background: $main-inner-color !important;
                        border-bottom-color: #d9d9d9 !important;
                    }
                }
            }
        }
    }
    &.white-table-view {
        .table-main-view {
            .mat-row {
                background: $white !important;
                .mat-header-cell {
                    background: $white !important;
                }
                .mat-header-cell {
                    border-left: 0px;
                }
                .mat-cell {
                    border-left: 0px;
                }
            }
        }
    }
}

.table-main-view {
    display: table;
    // @include breakpoint(sm) {
    //     min-width: 800px;
    // }
    .mat-row {
        display: table-row;
        border-color: $table-border-color;
        &:first-child {
            background: transparent !important;
            .mat-header-cell {
                background: transparent !important;
            }
        }
        &:last-child {
            .mat-cell {
                border-bottom: 0px;
            }
        }
        &:hover {
            background: #fafcff !important;
            border-color: #fafcff;
            .table-action-col {
                .menu_user {
                    color: $text-color;
                }
            }
            .number-td {
                i {
                    color: $text-color;
                }
            }
            .td-eye-ic {
                i {
                    color: $d_black;
                }
            }
        }
    }
    .mat-header-cell {
        border-left: 1px solid $table-border-color;
        border-bottom: 2px solid $table-border-color !important;
        font-weight: 400;
        line-height: 1.42rem;
        height: 4.28rem;
        font-size: 1rem;
        padding: 0.714rem 1.071rem;
        color: $para;
        padding-right: 1.428rem;
        position: relative;
        white-space: nowrap;
        display: table-cell;
        vertical-align: middle;
        background: $light_base2;
        @extend .fw-500;
        // @include breakpoint(lg) {
        //     font-size: 1.1rem;
        // }
        &:first-child {
            border-left: 0px;
        }
        i {
            position: relative;
            font-size: 1.429rem;
            top: 0;
            left: 10px;
        }
        &.th-text-center {
            justify-content: center;
            text-align: center;
            .mat-sort-header-container {
                justify-content: center;
                .mat-sort-header-arrow {
                    // display: none;
                }
            }
        }
        // .mat-sort-header-arrow{
        //     position: absolute;
        //     right: 10px;
        // }
        .mat-sort-header-container {
            white-space: nowrap;
        }
    }
    .mat-cell {
        border-left: 1px solid $table-border-color;
        border-bottom: 1px solid $table-border-color;
        line-height: 1.42rem;
        height: 4.28rem;
        font-size: 1rem;
        padding: 0.714rem 1.071rem;
        white-space: nowrap;
        display: table-cell;
        vertical-align: middle;
        color: $light-grey;

        .btn.xs_small_btn {
            width: 100%!important;
            max-width: 100px;
        }
               
        @extend .fw-400;
        @include breakpoint(small) {
            border-left: 0;
        }
        strong {
            color: $black;
            @extend .fw-500;
            outline: none;
        }
        @include breakpoint(large) {
            font-size: 1.1rem;
        }
        &:first-child {
            border-left: 0px;
        }
        .td-eye-ic {
            cursor: pointer;
            @include flexbox;
            i {
                color: $gray-500;
                font-size: 1.428rem;
            }
        }
        &.td-text-center {
            justify-content: center;
            text-align: center;
            @include breakpoint(medium) {
                justify-content: flex-start;
                text-align: right;
                .status-text {
                    margin: 0;
                }
            }
        }
    }
    .mat-row {
        &:nth-child(odd) {
            background: #fafcff;
        }
        &.mat-table-sticky {
            position: relative !important;
            z-index: inherit !important;
        }
    }
}

.mat-table {
    width: 100%;
}

tr.mat-footer-row,
tr.mat-row {
    width: 100%;
}

.table-view {
    .btn {
        @extend .fw-500;
    }
}

// table-responsive
@media screen and (max-width: 960px) {
    .table-view {
        .white_box {
            background-color: #f1f1f1;
        }
        mat-row.mat-row:hover {
            border-color: #f0f0f0;
        }

       

    }
    .mat-table {
        border: 0;
        vertical-align: middle;
        mat-row.mat-table-sticky {
            display: none;
        }
        caption {
            font-size: 1em;
        }
        .mat-header-cell {
            border: 10px solid;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            padding: 0;
            position: absolute;
            width: 1px;
        }
        .mat-row {
            border-bottom: 10px solid #f0f0f0;
            display: block;
            &:after {
                display: none;
            }
            &:last-child {
                .mat-cell {
                    border-bottom: 1px solid #ddd;
                }
            }
        }
        .mat-cell {
            border-bottom: 1px solid #ddd;
            display: block;
            font-size: 1em;
            text-align: right;
            font-weight: bold;
            height: auto;
            margin-bottom: 2%;
            display: flex;
            align-items: center;
            justify-content: space-between;


            @include breakpoint(medium) {
                margin-bottom: 0;
            }
            .open-label {
                float: right;
            }
            div.user-pic-name {
                justify-content: flex-end;
            }
            &:before {
                content: attr(data-label);
                float: left;
                text-transform: uppercase;
                font-weight: 700;
                font-size: .85em;
                margin-right: 15px;
            }
            &:last-child {
                border-bottom: 0;
                margin-bottom: 0;
            }
            &:first-child {
                margin-top: 0;
                padding-top: 1rem;
            }
            &:hover {
                .action-icon-trash-edit {
                    width: auto;
                }
            }
        }
    }
}

.mat-table .mat-row .mat-cell:last-child {
    @include breakpoint(small) {
        border: 0;
    }
}

.mat-table .mat-row:last-child {
    margin: 0;
    border: 0;
}

.tbl_div {
    display: table;
    width: 100%;
    .tbl_cell {
        display: table-cell;
        vertical-align: middle;
        float: none;
    }
}

.no-shadow-table {
    .table-main-view {
        box-shadow: none;
    }
}

.table-main-view .number-td {
    max-width: 80px;
    width: 80px;
    min-width: 80px;
    &.mat-header-cell {
        padding-left: 45px !important;
        i {
            color: $light-blue-2;
        }
    }
    i {
        color: $light-blue-2;
        margin: 0px 15px 0px 0px;
        font-size: 1.2rem;
        position: relative;
        top: 4px;
    }
    span {
        color: #6a7783;
    }
}

.table-main-view .table-action-col {
    max-width: 140px;
    width: 140px;
    min-width: 140px;
    .menu_user {
        color: $light-blue-color;
    }
}

.table-header {
    @include flexbox;
    align-items: center;
    position: relative;
    margin: 0px 0px 15px 0px;
    .table-header-icon {
        width: 28px;
        height: 28px;
        border-radius: 100%;
        background: $green2;
        color: $white;
        @include flexbox;
        align-items: center;
        position: relative;
        justify-content: center;
        margin: 0px 8px 0px 0px;
        i {
            font-size: 18px;
        }
    }
    .result {
        font-size: 1.143rem;
    }
}