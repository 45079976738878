// font-family
$base-font: 'Roboto',
sans-serif;
$font-family: $base-font;
// base colors
$input-border: #ADADAD99;
$input-hover: #999;
$light_base1:#66c5ff;
$light_base2:#b7dff7;
$dark-base:#0b71e8;
$light-text: #9FA1AF;
$base2: #51af57;
$green2: #38BF38;
$hover_green:#1e901e;
$light-blue-color:#95adc3;
$mute-blue-color: #8aa1b5;
$bg:#F6F8FB;
$para: #4D5A66;
$gray-400: #FAFAFA;
$light-grey: #555555;
$chat-bg: #FCFBFB;
$grey-text:#888888;
$grey-text-333: #333;
$light-grey-2: #707070;
$dark-border-color:#c1c2c6;
$light-border-color:#cccccc;
$light-blue-border2:#E9ECF5;
$dark-blue-dorder: #c1cdd7;
$gray_bg: #f6f8fb;
$table-border-color:#f0f0f0;
$gray-500: #c3c6c9;
$dark-blue: #1C5FA1;
$light-blue: #14C0E4;
$border-color:#dddddd;
$input-border: #e7e7e7 !default;
$white: #fff !default;
$gray-100: #f7f7f7 !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #E9ECF5 !default;
$gray-500: #95adc3 !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
// $gray-800: #555 !default;
$gray-900: #212529 !default;
$lightblue: #fafafc !default;
$text-color-main:#0E0E10;
$black: #000 !default;
$d_black: #0F0F11;
$darkgray: #1D1D21 !default;
// $lightgray: #888 !default;
$grays: () !default;
$base1: $d_black;
$title-color: $d_black;
$text-color:#676B7A;
$label-color:#53616e;
$cancel-color:#E9E5EF;
$sky-base-1: #00C0E4;
$green-3: #3dc27e;
$main-inner-color:#fcfcfc;
$grey-border: #b2b2b2;
// colors
$red: #FF7070;
$white: #ffffff;
$d_black: #0F0F11;
$orange:#FFAF32;
$yellow: #f7a922;
$green:#41bc79;
$d_green:#008644;
$green-3: #3dc27e;
$blue:#1e99fc;
$purple: #CC6FD7;
$d_blue: #019fff;
$red_text: #ff2727;
$delete_ic: #f07062;
$help_ic: #ffbb32;
$profile-upload: #d9534f;
$row-type-bg: #F6F8FB;
$row-type-border: #eeeeee;
$edit-color: #bfbfbf;
$folder-bg-color: #a1d2ff;
$light-blue-2: #c3c6c9;
$pink: #ff4966;
$success-status: #e2fff0;
$inprogress-status: #ebf4ff;
$canceled-status: #fff8e2;
$primary: $green2 !default;
$secondary: $d_black !default;
$success: $green !default;
$info: $d_black !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $light-grey !default;
$dark: $light-grey-2 !default;
$dark-blue: #121230 !default;
// Grid columns
//
// Set the number of columns and specify the width of the gutters.
$grid-columns: 12 !default;
$grid-gutter-width: 20px !default;
// Components
$line-height-lg: 1.5 !default;
$line-height-sm: 1.5 !default;
$border-width: 1px !default;
$border-color: $gray-300 !default;
$border-radius: .25rem !default;
$border-radius-lg: .3rem !default;
$border-radius-sm: .2rem !default;
$box-shadow-sm: 0 .125rem .25rem rgba($black, .075) !default;
$box-shadow: 0 0 1rem rgba($black, .15) !default;
$box-shadow-lg: 0 0 2rem rgba($black, .175) !default;
$component-active-color: $white !default;
$component-active-bg: theme-color("primary") !default;
$caret-width: .3em !default;
$transition-base: all .2s ease-in-out !default;
$transition-fade: opacity .15s linear !default;
$transition-collapse: height .35s ease !default;
$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge( ( "primary": $primary, "secondary": $secondary, "success": $success, "info": $info, "warning": $warning, "danger": $danger, "light": $light-grey, "dark": $dark, "pink": $pink, "black": $black, "dark-blue": $dark-blue), $theme-colors);
// Set a specific jump point for requesting color jumps
$theme-color-interval: 8% !default;