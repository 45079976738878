@import './base/_variables.scss';
@import './base/_mixins.scss';
@import './base/_base.scss';
@import './base/_modal.scss';
@import './base/_button.scss';
@import './base/_typography.scss';
@import './base/_table.scss';
@import './base/_navbar.scss';
@import './base/_form.scss';
@import './base/_loader.scss';
@import './base/_global.scss';
@import './base/_custom.scss';
@import './base/_material_base.scss';
@import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

// @import "@angular/material/prebuilt-themes/indigo-pink.css";