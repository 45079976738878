.tick_icon {
    line-height: 15px;
    margin-right: 5px;
}

.border_dashed {
    border: dashed 1px #a1a1a1;
    &:hover {
        border-color: $base1;
    }
}

.white_box {
    background-color: $white;
    border-radius: 3px;
    position: relative;
}

.form_row {
    // float: left;
    width: 100%;
    margin-bottom: 1.786rem;
    position: relative;
    display: block;
    // &:last-child {
    //     margin-bottom: 0px;
    // }
    .label {
        margin: 0px 0px 5px 0px;
        display: block;
        width: 100%;
        .info-icon {
            font-size: 1.286rem;
            position: relative;
            top: 4px;
            color: $light-blue-color;
        }
    }
    .input_block {
        // float: left;
        display: block;
        width: 100%;
        .input_hover {
            // float: left;
            display: block;
            width: 100%;
            position: relative;
        }
        .error {
            color: $red;
            font-size: 1rem;
            // float: left;
            display: block;
            width: 100%;
            margin-top: 10px;
            line-height: normal;
        }
    }
    .remember_block {
        .remember_check {
            @include breakpoint(x-small) {
                float: left;
                width: 100%;
                margin-bottom: 15px;
            }
        }
        .forgot_sec {
            text-align: right;
            @include breakpoint(x-small) {
                float: left;
                width: 100%;
                text-align: left;
            }
            .forgot_pass {
                color: $base1;
                font-size: 1.071rem;
                &:hover {
                    color: $green2;
                }
            }
        }
    }
}

.sub_header {
    background-color: $white;
    @include box-shadow(0px 1px 0px 0px rgba(0, 0, 0, 0.12));
    padding: 1rem;
    float: left;
    width: 100%;
    h2 {
        color: $black;
        font-size: 1.286rem;
        margin-bottom: 5px;
    }
    .heading3 {
        margin-bottom: 0px;
    }
}

.user_profile {
    display: flex;
    align-items: center;
    width: auto;
    max-width: 270px;
    cursor: pointer;
    @include breakpoint(small) {
        width: auto;
        justify-content: right;
    }
    .user_img img {
        width: 3rem;
        height: 3rem;
        min-width: 3rem;
        @include border-radius(100px);
        align-items: center;
        display: flex;
        overflow: hidden;
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;
        object-fit: cover;
    }
    .user_name {
        color: $white;
        font-size: 1.071rem;
        font-weight: 500;
        padding: 0px 10px;
        width: auto;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        @include breakpoint(small) {
            width: auto;
            display: none;
        }
    }
}

.page_sub_header {
    @include breakpoint(small) {
        display: flex;
        flex-direction: column;
    }
    .page_sub_header_title {
        @include breakpoint(large) {
            margin: 0px 0px 1.428rem 0px;
            display: block;
        }
        @include breakpoint(small) {
            display: block;
            float: left;
            width: 100%;
            margin-bottom: 14px;
        }
        .pointer {
            margin: 0px 10px 0px 0px;
            @include breakpoint(small) {
                margin-bottom: 15px;
            }
        }
    }
    .page_sub_header_actions {
        @include breakpoint(small) {
            display: block;
            float: left;
            width: 100%;
        }
        .category_filter {
            max-width: 200px;
            width: 100%;
            float: right;
            @include breakpoint(small) {
                max-width: 100%;
                background: #fffc;
                padding: 0px 10px;
                border: solid 1px #ddddddb3;
            }
            .category_filter_left {
                @include breakpoint(small) {
                    text-align: left;
                }
            }
            .category_filter_right {
                @include breakpoint(small) {
                    text-align: right;
                }
            }
        }
    }
}

.cancel-textarea {
    height: 13.29rem !important;
}

.redund-total-area {
    background: #f6f6f6;
    padding: 0;
    display: flex;
    border-radius: 2px;
    margin: 0;
    position: relative;
    border-radius: 0px 0px 4px 4px;
    flex-direction: column;
    align-items: flex-start;
    &.partial-area {
        &:before {
            left: 138px;
        }
        &:after {
            left: 138px;
        }
    }
    &:before {
        content: "";
        border-left: 14px solid transparent;
        border-right: 14px solid transparent;
        border-bottom: 12px solid #f6f6f6;
        position: absolute;
        left: 20px;
        top: -11px;
        width: 0;
        height: 0;
        margin: 0px auto;
        z-index: 2;
    }
    &:after {
        content: "";
        border-left: 14px solid transparent;
        border-right: 14px solid transparent;
        border-bottom: 12px solid #f1ead6;
        position: absolute;
        left: 20px;
        top: -12px;
        width: 0;
        height: 0;
        margin: 0px auto;
        z-index: 1;
    }
    .redund-total-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 0px;
        padding: 15px 25px;
        border-bottom: 1px solid #e2e2e2;
        &:last-child {
            border: 0px;
        }
        label {
            color: $light-grey;
            font-size: 1rem;
            font-weight: 400;
            strong {
                color: $d_black;
                font-weight: 400;
            }
        }
        h5 {
            color: $d_black;
            font-size: 1.143rem;
            font-weight: 500;
        }
    }
}

.note-blue {
    background: $light_base2;
    border-radius: 3px;
    padding: 15px 20px;
    margin: 15px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    color: #646c77;
    i {
        color: $yellow;
        font-size: 17px;
    }
}

.sub-total-area {
    background: #fffdf7;
    padding: 20px 25px 10px 25px;
    display: flex;
    border-top: 1px solid #f1ead6;
    margin: 0px -20px;
    position: relative;
    border-radius: 0px 0px 4px 4px;
    flex-direction: column;
    align-items: flex-end;
    &:before {
        content: "";
        border-left: 14px solid transparent;
        border-right: 14px solid transparent;
        border-bottom: 12px solid #fffdf7;
        position: absolute;
        right: 38px;
        top: -11px;
        width: 0;
        height: 0;
        margin: 0px auto;
        z-index: 2;
    }
    &:after {
        content: "";
        border-left: 14px solid transparent;
        border-right: 14px solid transparent;
        border-bottom: 12px solid #f1ead6;
        position: absolute;
        right: 38px;
        top: -12px;
        width: 0;
        height: 0;
        margin: 0px auto;
        z-index: 1;
    }
    .sub-total-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 300px;
        margin: 0px 0px 10px 0px;
        label {
            color: $light-grey;
            font-size: 1rem;
            font-weight: 400;
            strong {
                color: $d_black;
                font-weight: 400;
            }
        }
        h5 {
            color: $d_black;
            font-size: 1.143rem;
            font-weight: 500;
        }
    }
}

.item-img {
    align-items: center;
    .img {
        width: 30px;
        height: 30px;
        border: 1px solid #ddd;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0px 8px 0px 0px;
    }
}

.check-td-with-info {
    align-items: center;
}

.customer-info-outer {
    .card-header {
        .card-header-icon {
            .material-icons {
                svg {
                    width: 22px;
                    height: 18px;
                    position: relative;
                    top: -1px;
                }
            }
        }
    }
    .customer-info-inner {
        display: flex;
        justify-content: space-between;
        text-align: left;
        @include breakpoint(large) {
            flex-wrap: wrap;
            margin: 0px !important;
            justify-content: flex-start;
        }
        .customer-info-list {
            @include breakpoint(large) {
                margin-bottom: 20px;
                min-width: 33.33%;
                max-width: 33.33%;
                padding-right: 10px;
                br {
                    display: none;
                }
            }
            @include breakpoint(small) {
                min-width: 50%;
                max-width: 50%;
            }
            @include breakpoint(x-small) {
                min-width: 100%;
                max-width: 100%;
            }
        }
        label {
            color: $label-color;
            font-size: 13px;
            display: block;
            margin: 0px 0px 8px 0px;
        }
        p {
            color: $d_black;
        }
    }
}

.view-td {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
        color: $mute-blue-color;
        font-size: 1.429rem;
    }
}

.status-text {
    font-size: 1rem;
    display: inline-flex;
    margin: 0px 15px 0px 0px;
    align-items: center;
    padding: 8px 10px;
    color: #ffffff;
    border-radius: 3px;
    min-width: 108px;
    justify-content: center;
    width: auto;
    &.md-status {
        min-width: 60px;
        padding: 2px 10px;
        font-size: 0.9rem;
        .material-icons {
            font-size: 1.1rem;
            margin-right: 5px;
        }
    }
    i {
        margin: 0px 7px 0px 0px;
        font-size: 1.286rem;
    }
    &.success-status {
        background: $success-status;
        color: #59876f;
        margin: 0;
    }
    &.inprogress-status {
        background: $inprogress-status;
        color: #3b73b4;
        margin: 0;
    }
    &.canceled-status {
        background: $canceled-status;
        color: #806208;
        margin: 0;
    }
    &.tag-label {
        background-color: #F3F3F8;
        @extend .text-grey-dark;
        border-radius: 21px;
    }
}

.no-box-shadow {
    box-shadow: none !important;
}

.date-input {
    .mat-datepicker-toggle {
        position: absolute;
        right: 14px;
        top: -33px;
        .mat-datepicker-toggle-default-icon {
            width: 30px !important;
        }
    }
}

.customer-info {
    display: flex;
    justify-content: space-between;
    @include breakpoint(large) {
        flex-wrap: wrap;
    }
    .common-info-area {
        @include breakpoint(large) {
            width: 50%;
            margin: 10px 0px;
        }
        label {
            font-size: 1.2rem;
            color: $text-color;
            font-weight: 400;
            margin: 0px 0px 5px 0px;
            display: block;
        }
        p {
            font-size: 1rem;
            color: $text-color;
            margin: 10px 0px 0px 0px;
            display: block;
        }
    }
}

.no-border-select {
    .mat-form-field {
        .mat-form-field-wrapper {
            .mat-form-field-infix {
                border-top: 0px;
                .mat-select-arrow {
                    border-top-color: $mute-blue-color !important;
                }
            }
        }
    }
}

.select-border-box-outer {
    .mat-form-field {
        .mat-form-field-wrapper {
            .mat-form-field-infix {
                border-top: 0px;
            }
        }
        .mat-form-field-subscript-wrapper {
            display: none;
        }
    }
}

.cdk-overlay-pane .mat-select-panel {
    min-width: 112px;
    max-width: 280px;
    width: 100% !important;
    box-shadow: 0px 5px 25px 0.1px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 28px 25px 0.1px rgba(0, 0, 0, 0.15);
    // top: 32px;
    position: relative;
    background: $white !important;
    padding-top: 0px;
    -webkit-transform: translate(0);
    transform: translate(0);
    transition: .2s opacity .1s ease-in-out, .2s -webkit-transform .1s ease-in-out;
    transition: .2s opacity .1s ease-in-out, .2s transform .1s ease-in-out;
    transition: .2s opacity .1s ease-in-out, .2s transform .1s ease-in-out, .2s -webkit-transform .1s ease-in-out;
    .mat-option {
        background: $white;
        &:hover {
            background: #ebf0f5;
        }
        &:first-child {
            border-radius: 4px 4px 0px 0px;
        }
        &.mat-selected {
            background: $green;
            color: $white;
            .mat-option-text {
                color: $white !important;
            }
        }
        &.mat-active {
            .mat-option-text {
                color: $light-blue-color;
            }
        }
    }
}

.confirm-btn {
    .btn {
        margin: 0px 10px;
        height: 38px;
        line-height: 26px !important;
        font-weight: 400;
    }
}

.confirm-msg-area {
    .confirm-img {
        margin: 0px 0px 15px 0px;
    }
    h3 {
        font-size: 1.429rem;
        color: $title-color;
        margin: 10px 0px;
        font-weight: 500;
    }
    p {
        font-size: 1rem;
        color: $title-color;
        margin: 0px 0px 20px 0px;
    }
}

.cdk-global-overlay-wrapper {
    pointer-events: initial !important;
    overflow: auto !important;
}

.cdk-global-scrollblock {
    overflow: hidden !important;
    .cdk-global-overlay-wrapper {
        pointer-events: initial !important;
        overflow: auto !important;
    }
}

i {
    &.icon-right {
        margin-left: 10px;
    }
    &.icon-left {
        margin-right: 6px;
        @include breakpoint(x-small) {
            margin-right: 3px;
        }
    }
}

.dialoge-btn-action {
    clear: both;
    margin: 0px 0px;
    align-items: center;
    justify-content: center;
}

.manage-content-type-inner {
    max-height: 400px;
    overflow: auto;
    clear: both;
    .form_row {
        margin-bottom: 15px;
    }
}

.not-editable-input {
    display: flex;
    position: absolute;
    right: 0px;
    top: 10px;
    align-items: center;
    .not-editable-input-btn {
        display: flex;
        align-items: center;
        border: 1px solid $light-border-color;
        border-radius: 30px;
        padding: 4px 11px;
        color: $grey-text;
        font-size: 1rem;
        margin: 0px 10px 0px 0px;
        i {
            margin: 0px 5px 0px 0px;
            font-size: 1.143rem;
        }
    }
    .help-icon {
        color: $help_ic;
        font-size: 1.5rem;
    }
}

.disabled-input {
    label {
        opacity: 0.4;
    }
    .input_effect {
        background: $white !important;
        opacity: 0.3;
        cursor: not-allowed;
    }
    .not-editable-input-btn {
        cursor: not-allowed;
    }
}

.no-data-come {
    display: block;
    position: relative;
    margin: 50px 0px;
    text-align: center;
    width: 100%;
    .label {
        margin: 0px 0px 30px 0px;
        line-height: 20px;
    }
    .btn {
        padding-left: 30px;
        padding-right: 30px;
        font-size: 1rem;
        font-weight: 300;
    }
}

.admin-menu-toggle {
    display: none !important;
    @include breakpoint(large) {
        display: block !important;
        height: 32px;
        margin: 27px 0px 0px 15px;
        cursor: pointer;
        padding: 5px;
        position: absolute;
    }
    &.open {
        margin: 26x 0px 0px 7px;
        @include breakpoint(x-small) {
            margin: 4px 0px 0px 7px;
        }
        span:nth-last-child(3) {
            opacity: 0;
            -webkit-transform: rotate(0deg) scale(0.2, 0.2);
            transform: rotate(0deg) scale(0.2, 0.2);
        }
        span:nth-last-child(2) {
            -webkit-transform: rotate(-45deg) translate(-9px, 9px);
            transform: rotate(-45deg) translate(-9px, 9px);
        }
        span {
            opacity: 1;
            -webkit-transform: rotate(45deg) translate(-7px, -5px);
            transform: rotate(45deg) translate(-7px, -5px);
            background: #fff;
        }
    }
}

.start-icon {
    height: 20px;
    display: flex;
    align-items: center;
}

.error-page-404 {
    .white_box {
        padding: 158px 30px !important;
    }
    .card_text {
        position: relative;
        h1 {
            color: $black;
            font-size: 5.714rem;
            margin-bottom: 20px !important;
        }
    }
    .btn {
        min-width: 180px;
    }
}

.super_profile_setting {
    margin-top: 0px !important;
}

.top-menu-header {
    display: flex !important;
    align-items: center;
    margin: 0px 40px 0px 0px;
    @include breakpoint(xxxlarge) {
        margin: 0px 20px 0px 0px;
    }
    @include breakpoint(large) {
        margin: 0px 0px 0px 0px;
    }
    li {
        height: 100%;
        margin: 0px 0px 0px 0px !important;
        a {
            color: $white;
            display: flex;
            align-items: center;
            height: 100%;
            padding: 0px 20px;
            font-size: 1.143rem;
            line-height: normal;
            font-weight: 400;
            position: relative;
            @include breakpoint(xxxlarge) {
                padding: 0px 20px;
                font-size: 1em;
                i {
                    font-size: 1.286rem;
                }
            }
            @include breakpoint(xxxlarge) {
                font-size: 1.143rem;
            }
            i {
                margin: 0px 10px 0px 0px;
                color: #edeeff;
            }
            &:hover {
                // background:rgba(0,0,0,0.2);
            }
            span {
                white-space: nowrap;
            }
        }
        &.active {
            a {
                background: rgba(0, 0, 0, 0.1);
                &:after {
                    content: "";
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                    border-bottom: 10px solid $white;
                    position: absolute;
                    left: 0px;
                    right: 0px;
                    bottom: -1px;
                    width: 0;
                    height: 0;
                    margin: 0px auto;
                }
            }
        }
    }
}

.t-rotate-90 {
    -ms-transform: rotate(90deg);
    /* IE 9 */
    -webkit-transform: rotate(90deg);
    /* Safari */
    transform: rotate(90deg);
    /* Standard syntax */
}

.t-rotate-180 {
    -ms-transform: rotate(180deg);
    /* IE 9 */
    -webkit-transform: rotate(180deg);
    /* Safari */
    transform: rotate(180deg);
    /* Standard syntax */
}

.mat-option-text {
    font-size: 1rem;
}

.right_sec {
    width: 100%;
    .main_inner {
        width: 100%;
        padding: 1.428rem;
        margin: 0 auto;
        @include breakpoint(large) {
            padding: 15px;
        }
        .white_box {
            padding: 1.428rem;
            float: left;
            width: 100%;
            margin-bottom: 10px;
            &.no-padding {
                padding: 0;
            }
            &.no-float {
                float: none;
                display: block;
            }
            h1 {
                float: left;
                width: 100%;
                font-size: 1.714rem;
                font-weight: 500;
                color: $d_black;
                @include breakpoint(large) {
                    font-size: 18px;
                    font-weight: 500;
                    line-height: normal;
                }
            }
            .user_profile {
                // background-color: $base1;
                padding: 10px 20px;
                .mat-button-ripple {
                    display: none !important;
                }
            }
            .card_blocks_sec {
                display: flex;
                // float: left;
                // width: 100%;
                margin-bottom: 20px;
                margin: 0px -15px;
                flex-wrap: wrap;
                @include breakpoint(xxxlarge) {
                    flex-wrap: wrap;
                }
                &.dash-block {
                    .card {
                        .card_info {
                            padding-bottom: 43px;
                        }
                    }
                }
                .card {
                    margin-bottom: 1.786rem;
                }
            }
        }
    }
}

.ca-profile-info {
    &:hover {
        button {
            .arrow {
                border-top: solid 7px $light_base2;
            }
        }
    }
    button {
        position: relative;
        width: 30px;
        height: 26px;
        background-color: transparent;
        border: none;
        .arrow {
            position: absolute;
            top: 50%;
            left: 6px;
            @include transform(translateY(-50%));
            border-top: solid 7px $white;
            border-left: solid 6px transparent;
            border-right: solid 6px transparent;
            border-bottom: none;
            @include breakpoint(x-small) {
                left: 50%;
                @include transform(translate(-50%, -50%));
            }
        }
        .mat-button-focus-overlay {
            display: none;
        }
    }
}

.ui_compoents {
    float: left;
    width: 100%;
    display: flex;
    justify-content: stretch;
    padding: 0;
    // padding:66px 0px 0px 0px;
    .card {
        width: 100%;
    }
}

.profile-dropdown {
    margin-top: 12px !important;
    position: relative;
    overflow: visible !important;
    &:before {
        right: 10px !important;
    }
}

@keyframes spinner {
    0% {
        transform: rotate(0deg)
    }
    100% {
        transform: rotate(360deg);
        // border-left: 5px solid $green;
    }
}

.setting-dropdown {
    margin-right: 0px !important;
    right: -8px !important;
    margin-top: 15px !important;
    position: relative;
    overflow: visible !important;
    &:before {
        right: 30px !important;
    }
}

.white_header {
    .header_btn {
        text-align: right;
        .close {
            background-color: transparent;
            border: none;
            font-size: 2.143rem;
            line-height: 14px;
            @include transition(all ease 0.5s);
            &:hover,
            &:focus,
            &:active {
                @include transition(all ease 0.5s);
                color: $para;
            }
        }
    }
}

.page_header_area {
    display: flex;
    justify-content: space-between;
    margin: 0;
    clear: both;
    @include breakpoint(medium) {
        &.no-bg-shadow {
            background: none !important;
            box-shadow: none !important;
            padding-bottom: 0 !important;
        }
        &.d-flex {
            background: #fff;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
            margin: 0px -15px 15px -15px !important;
            padding: 14px 15px;
        }
        .border_btn {
            border: 0 !important;
            box-shadow: none !important;
            margin: 0 !important;
        }
        .green_border_btn {
            font-size: 0 !important;
            min-width: 40px !important;
            width: 40px;
            .material-icons.icon-left {
                margin: 0 !important;
            }
        }
    }
    @include breakpoint(small) {
        margin: 0;
        .border_btn {
            .material-icons {
                font-size: 1.5rem;
            }
        }
    }
    .heading-with-info {
        h2 {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .back-icon {
                margin: 0px 5px 0px 0px;
                cursor: pointer;
            }
            small {
                color: $label-color;
                font-size: 14px;
                margin: 0px 10px;
                font-weight: 400;
                span {
                    margin: 0px 5px;
                }
            }
        }
    }
    &.page-heading-with-breadcrumb {
        @include breakpoint(large) {
            flex-direction: column;
        }
        .page_sub_header_title {
            @include breakpoint(large) {
                width: 100%;
            }
        }
    }
    .heading-breadcrumb {
        color: $base1;
        font-size: 1rem;
        display: inline-block;
        clear: both;
        display: flex;
        flex-wrap: wrap;
        a {
            color: $base1;
        }
        span {
            color: $grey-text;
            display: inline-block;
            margin: 0px 5px;
        }
    }
    &.over-heading {
        flex-wrap: wrap;
        .page_sub_header_actions {
            @include breakpoint(xlarge) {
                width: 100%;
                flex-wrap: wrap;
            }
            .green_btn.icon_btn {
                @include breakpoint(large) {
                    font-size: 0px;
                }
                @include breakpoint(small) {
                    margin-top: 10px;
                }
                .mat-icon {
                    @include breakpoint(large) {
                        margin: 0px 0px 0px 0px;
                    }
                }
            }
        }
    }
    &.over-left-heading {
        @include breakpoint(xxxxlarge) {
            flex-wrap: wrap;
        }
        .page_sub_header_title {
            @include breakpoint(xxlarge) {
                width: 100%;
                margin: 0px 0px 10px 0px;
                flex-wrap: wrap;
            }
            h1 {
                white-space: nowrap;
                @include breakpoint(small) {
                    width: 100%;
                }
            }
        }
        .page_sub_header_actions {
            @include breakpoint(xxlarge) {
                width: 100%;
            }
        }
    }
    .page_sub_header_title {
        display: flex;
        align-items: center;
        @include breakpoint(small) {
            margin: 0px 0px 0px 0px;
        }
        .pointer {
            margin: 0px 10px 0px 0px;
        }
    }
    .page_header_action {
        @include breakpoint(small) {
            margin: 15px 0px 0px 0px;
        }
    }
    .page_sub_header_actions {
        @include breakpoint(small) {
            margin-top: 10px;
        }
    }
}

.select-border-box.mat-select.multiselect {
    min-height: 3rem;
    height: auto;
    padding: 0.357rem 0.714rem;
    padding-bottom: 0;
    .mat-select-placeholder {
        height: 1.2rem;
    }
    .mat-select-arrow-wrapper .mat-select-arrow {
        top: 0 !important;
    }
    .mat-chip-list-wrapper {
        margin-top: 0.1rem;
    }
}

.cdk-overlay-pane .mat-select-panel-wrap {
    background: $white;
    .mat-select-search-input {
        padding: 5px 10px !important;
        background: $white !important;
        height: 2.857rem !important;
        position: relative !important;
        border: 1px solid $border-color;
        z-index: 1;
        margin: 10px !important;
        width: calc(100% - 20px);
        border-radius: 3px;
        font-size: 1rem;
    }
    .mat-select-panel .mat-option.mat-selected {
        background: $bg;
    }
    .mat-select-panel .mat-option {
        .mat-option-text {
            color: $grey-text-333 !important;
        }
    }
}

.mat-select-panel .mat-pseudo-checkbox.mat-pseudo-checkbox-checked {
    border-color: $green2;
    border-width: 1px;
}