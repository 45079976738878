.top_nav {
    display: flex;
    width: 100%;
    align-items: center;
    overflow: hidden;
    padding: 10px 10px 10px 15px;
    height: 66px;
    top: 0px;
    left: 0px;
    z-index: 3;
    @include breakpoint(small) {
        justify-content: space-between;
    }
    @include breakpoint(x-small) {
        padding: 10px 15px;
    }
    .left_links {
        width: 50%;
        display: flex;
        align-items: center;
        @include breakpoint(medium) {
            position: relative;
            left: 44px;
        }
        @include breakpoint(small) {
            width: auto;
        }
        .logo {
            @include breakpoint(small) {
                max-width: 150px;
            }
            img {
                max-height: 4rem;
                @include breakpoint(large) {
                    position: relative;
                    top: 2px;
                }
            }
        }
        .sidebar_header {
            width: 270px;
            text-align: left;
            @include breakpoint(large) {
                width: 180px;
            }
            @include breakpoint(small) {
                width: 120px;
            }
        }
    }
    .right_links {
        width: 50%;
        text-align: right;
        display: flex;
        justify-content: flex-end;
        @include breakpoint(small) {
            width: auto;
        }
        ul {
            display: flex;
            align-items: center;
            li {
                display: inline-block;
                margin-right: 40px;
                vertical-align: middle;
                @include breakpoint(medium) {
                    margin-right: 30px;
                }
                @include breakpoint(small) {
                    margin-right: 10px;
                    ;
                }
                &:last-child {
                    margin-right: 0px;
                }
                .user_profile {
                    .mat-button {
                        width: 30px !important;
                        min-width: inherit;
                        padding: 0;
                        .mat-button-ripple {
                            display: none;
                        }
                    }
                }
            }
            li {
                &.menu_icon {
                    display: none;
                    @include breakpoint(small) {
                        display: block;
                    }
                }
                .small_btn {
                    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
                }
                .btn {
                    white-space: nowrap;
                    font-size: 1.142rem !important;
                    @include breakpoint(small) {
                        padding: 0px 10px;
                        height: 35px;
                    }
                }
            }
        }
    }
}

.top_nav.super-admin-header {
    height: 5.714rem;
    background: $d_black;
    position: relative;
    .user_profile {
        line-height: 4.571rem;
        min-height: 5.714rem;
    }
    .container {
        display: flex;
        width: 100%;
        max-width: 100%;
        align-items: center;
        padding: 0;
        @include breakpoint(small) {
            justify-content: space-between;
        }
    }
}

.top_nav .right_links ul li .btn.green_btn {
    @include breakpoint(small) {
        span {
            font-size: 0;
            margin: 0;
        }
        .mat-icon {
            margin: 0;
        }
    }
}

.top_nav .right_links ul li>button {
    @include breakpoint(small) {
        margin: 0 !important;
    }
}

.mat-drawer-content.mat-sidenav-content {
    margin: 0 0 0 8.5rem!important;
    @include breakpoint(large) {
        margin-left: 0 !important;
    }
}

.mat-sidenav.sidenav-list {
    box-shadow: 15.97px 5.81px 42px rgba(0, 0, 0, .07);
    -webkit-box-shadow: 15.97px 5.81px 42px rgba(0, 0, 0, .07);
    .close-icon {
        display: none;
    }
    .user-pic-name {
        display: none !important;
    }
    .cancel_btn {
        display: none !important;
    }
    @include breakpoint(large) {
        position: fixed;
        top: 5.714rem;
        left: -999px;
        box-shadow: none !important;
        &.open {
            z-index: 4 !important;
            .cancel_btn {
                position: fixed;
                bottom: 10px;
                left: 20px;
                background: #eee;
                display: flex !important;
                .material-icons {
                    transform: rotate(180deg);
                    font-size: 1.714rem;
                }
            }
        }
        .close-icon {
            position: absolute;
            right: 10px;
            top: 10px;
            display: block;
            .material-icons {
                color: #333;
                opacity: 0.6;
                font-size: 1.571rem;
            }
        }
        .user-pic-name {
            padding: 20px 20px;
            border-bottom: 1px solid #eee;
            margin: 10px 0 10px 0px;
            display: flex !important;
            .user-images {
                width: 4.285rem;
                height: 4.285rem;
                min-width: 4.285rem;
            }
            strong {
                font-weight: 600;
                font-size: 1.142rem;
            }
        }
        &.open {
            left: 0;
            position: fixed;
            top: 0px;
            width: 80%;
            max-width: 280px;
            border-radius: 0 6px 0 0;
            border: 0;
            visibility: visible !important;
            &:after {
                content: "";
                right: 0px;
                position: fixed;
                background: #000;
                opacity: 0.6;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
            }
            .mat-drawer-inner-container {
                position: relative;
                z-index: 2;
                background: #fff;
                .ng-scroll-content {
                    height: 100vh;
                    padding-bottom: 60px;
                }
            }
        }
    }
    .mat-nav-list {
        @extend .pt-0;
        a {
            padding: 1.5rem 0;
            height: auto;
            font-size: 1rem;
            width: 100%;
            border-left: 3px solid transparent;
            color: $d_black;
            @extend .fw-500;
            @extend .position-relative;
            transition: inherit;
            @include breakpoint(large) {
                @include flexbox;
                width: 100%;
                padding: 1.071rem 0;
                opacity: 0.8;
                &:after {
                    display: none;
                }
                .mat-list-item-content {
                    width: 100%;
                    @include flexbox;
                    flex-direction: inherit;
                    flex-wrap: initial !important;
                    span {
                        width: auto;
                        margin: 0 10px 0 0;
                        font-size: 1.071rem;
                    }
                    .material-icons {
                        font-size: 1.714rem;
                    }
                }
            }
            .nuc-arrow {
                width: 0;
                height: 0;
                border-top: 10px solid transparent;
                border-bottom: 10px solid transparent;
                border-left: 10px solid #b1a6a6;
                position: absolute;
                left: -7px;
                top: 50px;
                &:before {
                    width: 0;
                    height: 0;
                    border-top: 10px solid transparent;
                    border-bottom: 10px solid transparent;
                    border-left: 10px solid #fafafa;
                    position: absolute;
                    left: -12px;
                    top: -11px;
                    content: '';
                }
            }
            span.material-icons {
                @extend .pb-1;
            }
            span {
                svg {
                    width: 24px;
                    height: 24px;
                    path {
                        fill: $d_black;
                    }
                }
            }
            &:after {
                @extend .position-absolute;
                content: '';
                bottom: 0;
                width: 100%;
                height: 1px;
                background-color: rgba(233, 236, 245, .6);
                left: 0px;
            }
            &:hover,
            &:active,
            &:active:hover {
                border-left: 3px solid $green;
                background-color: $gray-400;
                color: $green2;
                span.material-icons {
                    color: $green2;
                }
                span {
                    svg {
                        path {
                            fill: $green2;
                        }
                    }
                }
            }
            .mat-ripple {
                display: none;
            }
        }
        .mat-list {
            &.child-nav {
                a.mat-list-item {
                    padding: .8rem 0;
                    width: 100%;
                    border-left: 0;
                    @extend .fw-500;
                    font-size: 13px;
                    &:after {
                        display: none;
                    }
                    &:hover,
                    &:active,
                    &:active:hover {
                        border-left: 0;
                        color: $green2;
                    }
                    .mat-list-item-content {
                        width: 100%;
                        flex-wrap: nowrap;
                        span {
                            width: auto;
                        }
                        span.material-icons {
                            width: auto;
                            font-size: 16px;
                            @extend .pb-0;
                        }
                    }
                }
            }
        }
    }
    .mat-list-item-content {
        @extend .flex-wrap;
        span {
            width: 100%;
            text-align: center;
        }
    }
}

.has-child {
    position: relative;
    .child-nav {
        display: none;
        min-width: 17rem;
        background-color: $white;
        left: 8.7rem;
        // top: 0px;
        top: 5.7rem;
        position: fixed;
        height: calc(100vh - 5.7rem);
        @include box-shadow(15.97px 5.81px 42px 0 rgba(0, 0, 0, 0.07));
        @include breakpoint(large) {
            &.open {
                background-color: #eaeaea;
                left: 0;
                top: 0;
                position: relative;
                height: auto;
                padding-top: 0;
            }
            background-color: #eaeaea;
            left: 0;
            top: 0;
            position: relative;
            height: auto;
            padding-top: 0;
        }
    }
    &:hover {
        .child-nav {
            display: block;
        }
    }
}

.sidenav-menu-open {
    z-index: 5 !important;
}

.has-child .child-nav {
    z-index: 999;
}

.sidenav-list {
    width: 100%;
    max-width: 8.5rem;
}

mat-sidenav.mat-drawer.mat-sidenav.sidenav-list {
    z-index: 1001 !important;
    @include breakpoint(large) {
        z-index: 999 !important;
    }
}

.navicon-button {
    display: inline-block;
    position: relative;
    padding: 2.0625rem 1.5rem;
    transition: 0.3s;
    cursor: pointer;
    user-select: none;
    opacity: .8;
    @include breakpoint(small) {
        padding: 0 15px;
    }
    &.open {
        .navicon:before {
            transform: rotate(35deg);
            transform-origin: left top;
        }
        .navicon:after {
            transform: rotate(-35deg);
            transform-origin: left bottom;
        }
        .navicon:before,
        .navicon:after {
            width: 0.8rem;
            top: 0 !important;
        }
    }
    .navicon {
        position: relative;
        width: 22px;
        height: 2px;
        background: $title-color;
        transition: 0.3s;
        border-radius: 2.5rem;
    }
    .navicon:before {
        top: .525rem;
    }
    .navicon:after {
        top: -.525rem;
    }
    .navicon:before,
    .navicon:after {
        display: block;
        content: "";
        width: 22px;
        height: 2px;
        background: $title-color;
        position: absolute;
        z-index: -1;
        transition: 0.3s 0.3s;
        border-radius: 1rem;
    }
}

.main-menu {
    .back-opacity {
        content: "";
        left: 0px;
        position: fixed;
        width: 100%;
        top: 5.714rem;
        background: rgba(0, 0, 0, 0.4);
        height: calc(100% - 66px);
        visibility: hidden;
        opacity: 0;
        z-index: 1;
        @include transition(all ease 0.2s);
    }
    &.open {
        .back-opacity {
            visibility: visible;
            opacity: 1;
        }
        @include breakpoint(large) {
            right: 0px;
        }
        ul {
            flex-direction: column;
            margin: 0px;
            background: $base1;
            z-index: 2;
            position: relative;
            min-height: 100vh;
            &.top-menu-header {
                flex-direction: inherit;
                min-height: inherit;
            }
        }
        li {
            @include breakpoint(large) {
                height: auto !important;
                width: 100%;
            }
        }
    }
}