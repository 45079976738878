.full-width-popup {
    max-width: 100% !important;
    width: 100% !important;
    top: 0px;
    height: 100%;
    .modal {
        background: $gray_bg;
        min-height: 100vh;
        .file_boxes_area {
            margin: 0px -15px;
            margin-bottom: 80px;
        }
    }
    .mat-dialog-container {
        border-radius: 0px;
        background: #f6f8fb !important;
    }
}

.cdk-overlay-pane {
    &.modal-xs {
        width: 530px !important;
        max-width: 90% !important;
        .main-modal {
            .modal-body {
                padding: 20px 20px;
            }
        }
    }
    &.modal-sm {
        width: 690px !important;
        max-width: 90% !important;
    }
    &.modal-md {
        width: 784px !important;
        max-width: 90% !important;
    }
    &.modal-ex-lg {
        width: 100% !important;
        max-width: 100% !important;
        background-color: #F6F8FB;
        height: 100%;
        .mat-dialog-container {
            border-radius: 0;
            box-shadow: none !important;
            background: none !important;
        }
    }
}

// modal style
.main-modal {
    &.confirm-modal {
        .modal_header {
            padding-bottom: 0px;
            border: 0px;
        }
        h3 {
            color: #1D1D21;
            font-size: 22px !important;
            margin: 20px 0 20px 0;
            font-weight: 400;
        }
        p {
            word-break: break-word;
            font-size: 16px;
            margin: 0;
            a {
                color: $green2;
            }
        }
        .download-icon {
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;
            margin: 0 0 10px 0;
            span {
                width: 38px;
                height: 38px;
                border: 1px solid $green2;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100%;
                color: $green2;
            }
        }
        .modal-footer {
            border-top: 0px;
            margin-top: 0px;
            margin-bottom: 20px;
            justify-content: center;
            .confirm-btn {
                button {
                    text-transform: uppercase;
                    width: 95px;
                    box-shadow: none;
                }
            }
        }
    }
    .sub_header {
        .header_title {
            .heading3 {
                font-size: 1.429rem;
                font-weight: 500;
                @include breakpoint(large) {
                    font-size: 1.229rem;
                }
            }
        }
    }
    .modal_header {
        padding: 14px 30px;
        // float: left;
        display: block;
        width: 100%;
        border-bottom: solid 1px #e5e5e5;
        position: relative;
        min-height: 34px;
        .modal_title {
            font-size: 18px !important;
            font-weight: 500;
            color: $d_black;
            margin: 0;
            .status-text {
                height: 24px;
                margin: 0 0 5px 0;
                position: relative;
                top: -2px;
            }
            span.small-text {
                text-transform: uppercase;
                font-size: 10px;
                color: $grey-text;
            }
        }
        p {
            margin: 0;
        }
        .close_modal {
            position: absolute;
            color: $title-color;
            font-size: 1.8rem;
            font-weight: 300;
            border: 0px;
            background: none;
            float: right;
            cursor: pointer;
            width: 24px;
            height: 24px;
            margin: auto;
            top: 15px;
            right: 25px;
            @include transition(all ease 0.5s);
            &:hover,
            &:focus,
            &:active {
                @include transition(all ease 0.5s);
                color: $title-color;
            }
        }
    }
    .modal-body {
        padding: 20px 30px;
        float: left;
        width: 100%;
        @include breakpoint(x-small) {
            padding: 20px;
        }
        .action_bar {
            padding: 0px 0px 20px 0px;
            @include breakpoint(x-small) {
                padding: 0px 0px 0px 0px;
            }
            ul {
                li {
                    @media(max-width: 515px) {
                        width: 100%;
                        text-align: center;
                    }
                    &:last-child {
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }
    .modal-footer {
        clear: both;
        padding: 20px 30px;
        display: block;
        display: flex;
        justify-content: flex-end;
        width: 100%;
        border-top: 1px solid #eaeaea;
        @include breakpoint(small) {
            .btn {
                width: 100%;
            }
        }
        .large_btn.green_btn {
            min-width: 242px;
        }
        .transparent_btn {
            margin: 0 10px 0 0;
        }
    }
}

.modal.have-met-popup {
    background: url("/assets/images/have-meet-bg.png") no-repeat;
    display: block;
    float: left;
    width: 100%;
    .modal_header {
        border-bottom: 0px;
    }
}