.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  $font-family: $base-font;
}

.mat-badge-small .mat-badge-content {
  font-size: 6px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-caption,
.mat-small {
  font: 400 12px/20px $d_black;
}

.mat-display-4,
.mat-typography .mat-display-4 {
  font: 300 112px/112px $d_black;
  margin: 0 0 56px;
  letter-spacing: -0.05em;
}

.mat-display-3,
.mat-typography .mat-display-3 {
  font: 400 56px/56px $d_black;
  margin: 0 0 64px;
  letter-spacing: -0.02em;
}

.mat-display-2,
.mat-typography .mat-display-2 {
  font: 400 45px/48px $d_black;
  margin: 0 0 64px;
  letter-spacing: -0.005em;
}

.mat-display-1,
.mat-typography .mat-display-1 {
  font: 400 34px/40px $d_black;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px/20px$d_black;
}

.mat-button-toggle,
.mat-card {
  $font-family: $base-font;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-content,
.mat-card-subtitle {
  font-size: 14px;
}

.mat-checkbox {
  $font-family: $base-font;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
  border-radius: 10rem;
  padding: 0 1.2rem;
  overflow: hidden;
  .mat-chip-remove.mat-icon,
  .mat-chip-trailing-icon.mat-icon {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.mat-table {
  $font-family: $base-font;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell,
.mat-footer-cell {
  font-size: 14px;
}

.mat-calendar {
  $font-family: $base-font;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px/32px $d_black;
}

.mat-expansion-panel-header {
  $font-family: $base-font;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px/20px $d_black;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 2.18;
  $font-family: $base-font;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}

.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}

.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0;
  // border-top: .84375em solid transparent;
  width: 100% !important;
  min-width: 100% !important;
  box-sizing: border-box;
}

.mat-form-field-can-float {
  .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label,
  &.mat-form-field-should-float .mat-form-field-label {
    transform: translateY(-1.34375em) scale(0.75);
    width: 133.33333%;
  }
  .mat-input-server[label]:not(:label-shown)
    + .mat-form-field-label-wrapper
    .mat-form-field-label {
    transform: translateY(-1.34374em) scale(0.75);
    width: 133.33334%;
  }
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
  color: #888;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 85%;
  margin-top: 0.66667em;
  top: calc(100% - 1.79167em);
}

.mat-form-field-appearance-legacy {
  .mat-form-field-wrapper {
    padding-bottom: 1.25em;
  }
  .mat-form-field-infix {
    padding: 0.8rem 0;
  }
  &.mat-form-field-can-float {
    .mat-input-server:focus
      + .mat-form-field-label-wrapper
      .mat-form-field-label,
    &.mat-form-field-should-float .mat-form-field-label {
      transform: translateY(-1.28125em) scale(0.75) perspective(100px)
        translateZ(0.001px);
      -ms-transform: translateY(-1.28125em) scale(0.75);
      width: 133.33333%;
    }
    .mat-form-field-autofill-control:-webkit-autofill
      + .mat-form-field-label-wrapper
      .mat-form-field-label {
      transform: translateY(-1.28125em) scale(0.75) perspective(100px)
        translateZ(0.00101px);
      -ms-transform: translateY(-1.28124em) scale(0.75);
      width: 133.33334%;
    }
    .mat-input-server[label]:not(:label-shown)
      + .mat-form-field-label-wrapper
      .mat-form-field-label {
      transform: translateY(-1.28125em) scale(0.75) perspective(100px)
        translateZ(0.00102px);
      -ms-transform: translateY(-1.28123em) scale(0.75);
      width: 133.33335%;
    }
  }
  .mat-form-field-label {
    top: 1.28125em;
  }
  .mat-form-field-underline {
    bottom: 1.25em;
  }
  .mat-form-field-subscript-wrapper {
    margin-top: 0.54167em;
    top: calc(100% - 1.66667em);
  }
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float {
    .mat-input-server:focus
      + .mat-form-field-label-wrapper
      .mat-form-field-label,
    &.mat-form-field-should-float .mat-form-field-label {
      transform: translateY(-1.28122em) scale(0.75);
    }
    .mat-form-field-autofill-control:-webkit-autofill
      + .mat-form-field-label-wrapper
      .mat-form-field-label {
      transform: translateY(-1.28121em) scale(0.75);
    }
    .mat-input-server[label]:not(:label-shown)
      + .mat-form-field-label-wrapper
      .mat-form-field-label {
      transform: translateY(-1.2812em) scale(0.75);
    }
  }
}

.mat-form-field-appearance-fill {
  .mat-form-field-infix {
    padding: 0.25em 0 0.75em 0;
  }
  .mat-form-field-label {
    top: 1.09375em;
    margin-top: -0.5em;
  }
  &.mat-form-field-can-float {
    .mat-input-server:focus
      + .mat-form-field-label-wrapper
      .mat-form-field-label,
    &.mat-form-field-should-float .mat-form-field-label {
      transform: translateY(-0.59375em) scale(0.75);
      width: 133.33333%;
    }
    .mat-input-server[label]:not(:label-shown)
      + .mat-form-field-label-wrapper
      .mat-form-field-label {
      transform: translateY(-0.59374em) scale(0.75);
      width: 133.33334%;
    }
  }
}

.mat-form-field-appearance-outline {
  .mat-form-field-infix {
    padding: 1em 0 1em 0;
  }
  .mat-form-field-label {
    top: 2.4em;
    margin-top: -0.25em;
  }
  input.mat-input-element {
    position: relative;
    top: 3px;
  }
  &.mat-form-field-can-float {
    .mat-input-server:focus
      + .mat-form-field-label-wrapper
      .mat-form-field-label,
    &.mat-form-field-should-float .mat-form-field-label {
      transform: translateY(-1.89375em) scale(0.75);
      width: 133.33333%;
      @include breakpoint(xxxlarge) {
        transform: translateY(-1.39375em) scale(0.75);
      }
    }
    .mat-input-server[label]:not(:label-shown)
      + .mat-form-field-label-wrapper
      .mat-form-field-label {
      transform: translateY(-1.89375em) scale(0.75);
      width: 133.33334%;
    }
  }
}

.mat-grid-tile-footer,
.mat-grid-tile-header {
  font-size: 1rem;
}

.mat-grid-tile-footer .mat-line,
.mat-grid-tile-header .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}

.mat-grid-tile-footer .mat-line:nth-child(n + 2),
.mat-grid-tile-header .mat-line:nth-child(n + 2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
  font-size: 1.142rem;
  box-sizing: border-box;
}

.mat-menu-item {
  $font-family: $base-font;
  font-size: 1rem;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  $font-family: $base-font;
  font-size: 12px;
}

.mat-radio-button,
.mat-select {
  $font-family: $base-font;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  $font-family: $base-font;
}

.mat-slider-thumb-label-text {
  $font-family: $base-font;
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-horizontal,
.mat-stepper-vertical {
  $font-family: $base-font;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: 400;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  $font-family: $base-font;
  .mat-tab-header {
    margin-bottom: 1rem;
    border-bottom: 1px solid #d7d7d7;
    padding: 0;
    .mat-tab-label {
      padding: 15px !important;
      min-width: inherit;
      margin-right: 1.5rem;
      opacity: 1;
      font-size: 1rem;
      &.mat-tab-label-active {
        color: $d_black;
      }
    }
    .mat-ink-bar {
      height: 2px;
    }
  }
}

.mat-tab-label,
.mat-tab-link {
  $font-family: $base-font;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar {
  font: 500 20px/32px $d_black;
  margin: 0;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font: 500 20px/32px $d_black;
    margin: 0;
  }
}

.mat-tooltip {
  $font-family: $base-font;
  font-size: 14px;
  padding-top: 6px;
  padding-bottom: 6px;
  line-height: 1.4;
  text-align: center;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item,
.mat-list-option {
  $font-family: $base-font;
}

.mat-list .mat-list-item,
.mat-nav-list .mat-list-item,
.mat-selection-list .mat-list-item {
  font-size: 16px;
}

.mat-list .mat-list-item .mat-line,
.mat-nav-list .mat-list-item .mat-line,
.mat-selection-list .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}

.mat-list .mat-list-item .mat-line:nth-child(n + 2),
.mat-nav-list .mat-list-item .mat-line:nth-child(n + 2),
.mat-selection-list .mat-list-item .mat-line:nth-child(n + 2) {
  font-size: 14px;
}

.mat-list .mat-list-option,
.mat-nav-list .mat-list-option,
.mat-selection-list .mat-list-option {
  font-size: 16px;
}

.mat-list .mat-list-option .mat-line,
.mat-nav-list .mat-list-option .mat-line,
.mat-selection-list .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}

.mat-list .mat-list-option .mat-line:nth-child(n + 2),
.mat-nav-list .mat-list-option .mat-line:nth-child(n + 2),
.mat-selection-list .mat-list-option .mat-line:nth-child(n + 2) {
  font-size: 14px;
}

.mat-list .mat-subheader,
.mat-nav-list .mat-subheader,
.mat-selection-list .mat-subheader {
  $font-family: $base-font;
  font-size: 14px;
  font-weight: 500;
}

.mat-list[dense] .mat-list-item,
.mat-nav-list[dense] .mat-list-item,
.mat-selection-list[dense] .mat-list-item {
  font-size: 12px;
}

.mat-list[dense] .mat-list-item .mat-line,
.mat-nav-list[dense] .mat-list-item .mat-line,
.mat-selection-list[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}

.mat-list[dense] .mat-list-item .mat-line:nth-child(n + 2),
.mat-nav-list[dense] .mat-list-item .mat-line:nth-child(n + 2),
.mat-selection-list[dense] .mat-list-item .mat-line:nth-child(n + 2),
.mat-list[dense] .mat-list-option,
.mat-nav-list[dense] .mat-list-option,
.mat-selection-list[dense] .mat-list-option {
  font-size: 12px;
}

.mat-list[dense] .mat-list-option .mat-line,
.mat-nav-list[dense] .mat-list-option .mat-line,
.mat-selection-list[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}

.mat-list[dense] .mat-list-option .mat-line:nth-child(n + 2),
.mat-nav-list[dense] .mat-list-option .mat-line:nth-child(n + 2),
.mat-selection-list[dense] .mat-list-option .mat-line:nth-child(n + 2) {
  font-size: 12px;
}

.mat-list[dense] .mat-subheader,
.mat-nav-list[dense] .mat-subheader,
.mat-selection-list[dense] .mat-subheader {
  $font-family: $base-font;
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  $font-family: $base-font;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px/24px $d_black;
}

.mat-simple-snackbar {
  $font-family: $base-font;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  $font-family: $base-font;
}

.mat-nested-tree-node,
.mat-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
  &.mat-ripple-unbounded {
    overflow: visible;
  }
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0s cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
}

@media screen and (-ms-high-contrast: active) {
  .mat-ripple-element {
    display: none;
  }
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1000;
  &:empty {
    display: none;
  }
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
  &.cdk-overlay-backdrop-showing {
    opacity: 1;
  }
}

@media screen and (-ms-high-contrast: active) {
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0.6;
  }
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  opacity: 0;
  &.cdk-overlay-backdrop-showing {
    opacity: 0;
  }
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

@keyframes cdk-text-field-autofill-start {
  /*!*/
}

@keyframes cdk-text-field-autofill-end {
  /*!*/
}

.cdk-text-field-autofill-monitored {
  &:-webkit-autofill {
    animation-name: cdk-text-field-autofill-start;
  }
  &:not(:-webkit-autofill) {
    animation-name: cdk-text-field-autofill-end;
  }
}

textarea {
  &.cdk-textarea-autosize {
    resize: none;
  }
  &.cdk-textarea-autosize-measuring {
    height: auto !important;
    overflow: hidden !important;
    padding: 2px 0 !important;
    box-sizing: content-box !important;
  }
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-option {
  color: rgba(0, 0, 0, 0.87);
  &:focus:not(.mat-option-disabled),
  &:hover:not(.mat-option-disabled),
  &.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
    background: rgba(0, 0, 0, 0.04);
  }
  &.mat-active {
    background: rgba(0, 0, 0, 0.04);
    color: rgba(0, 0, 0, 0.87);
  }
  &.mat-option-disabled {
    color: rgba(0, 0, 0, 0.38);
  }
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: $green2;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: $red;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: $red;
}

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
  &::after {
    color: #fafafa;
    top: 2px !important;
    left: 1px !important;
    width: 10px !important;
    height: 5px !important;
  }
}

.mat-accent {
  .mat-pseudo-checkbox-checked,
  .mat-pseudo-checkbox-indeterminate {
    background: $red;
  }
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate {
  background: $red;
}

.mat-primary {
  .mat-pseudo-checkbox-checked,
  .mat-pseudo-checkbox-indeterminate {
    background: $green2;
  }
}

.mat-warn {
  .mat-pseudo-checkbox-checked,
  .mat-pseudo-checkbox-indeterminate {
    background: $red;
  }
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

// .mat-elevation-z0 {
//   @include box-shadow(0 0 0 0 rgba(0, 0, 0, 0.2), 0 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12));
// }
// .mat-elevation-z1 {
//   @include box-shadow(0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12));
// }
// .mat-elevation-z2 {
//   @include box-shadow(0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12));
// }
// .mat-elevation-z3 {
//   @include box-shadow(0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12));
// }
// .mat-elevation-z4 {
//   box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
// }
// .mat-elevation-z5 {
//   box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 5px 8px 0 rgba(0, 0, 0, 0.14), 0 1px 14px 0 rgba(0, 0, 0, 0.12);
// }
// .mat-elevation-z6 {
//   box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
// }
// .mat-elevation-z7 {
//   box-shadow: 0 4px 5px -2px rgba(0, 0, 0, 0.2), 0 7px 10px 1px rgba(0, 0, 0, 0.14), 0 2px 16px 1px rgba(0, 0, 0, 0.12);
// }
// .mat-elevation-z8 {
//   box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
// }
// .mat-elevation-z9 {
//   box-shadow: 0 5px 6px -3px rgba(0, 0, 0, 0.2), 0 9px 12px 1px rgba(0, 0, 0, 0.14), 0 3px 16px 2px rgba(0, 0, 0, 0.12);
// }
// .mat-elevation-z10 {
//   box-shadow: 0 6px 6px -3px rgba(0, 0, 0, 0.2), 0 10px 14px 1px rgba(0, 0, 0, 0.14), 0 4px 18px 3px rgba(0, 0, 0, 0.12);
// }
// .mat-elevation-z11 {
//   box-shadow: 0 6px 7px -4px rgba(0, 0, 0, 0.2), 0 11px 15px 1px rgba(0, 0, 0, 0.14), 0 4px 20px 3px rgba(0, 0, 0, 0.12);
// }
// .mat-elevation-z12 {
//   box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 5px 22px 4px rgba(0, 0, 0, 0.12);
// }
// .mat-elevation-z13 {
//   box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(0, 0, 0, 0.12);
// }
// .mat-elevation-z14 {
//   box-shadow: 0 7px 9px -4px rgba(0, 0, 0, 0.2), 0 14px 21px 2px rgba(0, 0, 0, 0.14), 0 5px 26px 4px rgba(0, 0, 0, 0.12);
// }
// .mat-elevation-z15 {
//   box-shadow: 0 8px 9px -5px rgba(0, 0, 0, 0.2), 0 15px 22px 2px rgba(0, 0, 0, 0.14), 0 6px 28px 5px rgba(0, 0, 0, 0.12);
// }
// .mat-elevation-z16 {
//   box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2), 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12);
// }
// .mat-elevation-z17 {
//   box-shadow: 0 8px 11px -5px rgba(0, 0, 0, 0.2), 0 17px 26px 2px rgba(0, 0, 0, 0.14), 0 6px 32px 5px rgba(0, 0, 0, 0.12);
// }
// .mat-elevation-z18 {
//   box-shadow: 0 9px 11px -5px rgba(0, 0, 0, 0.2), 0 18px 28px 2px rgba(0, 0, 0, 0.14), 0 7px 34px 6px rgba(0, 0, 0, 0.12);
// }
// .mat-elevation-z19 {
//   box-shadow: 0 9px 12px -6px rgba(0, 0, 0, 0.2), 0 19px 29px 2px rgba(0, 0, 0, 0.14), 0 7px 36px 6px rgba(0, 0, 0, 0.12);
// }
// .mat-elevation-z20 {
//   box-shadow: 0 10px 13px -6px rgba(0, 0, 0, 0.2), 0 20px 31px 3px rgba(0, 0, 0, 0.14), 0 8px 38px 7px rgba(0, 0, 0, 0.12);
// }
// .mat-elevation-z21 {
//   box-shadow: 0 10px 13px -6px rgba(0, 0, 0, 0.2), 0 21px 33px 3px rgba(0, 0, 0, 0.14), 0 8px 40px 7px rgba(0, 0, 0, 0.12);
// }
// .mat-elevation-z22 {
//   box-shadow: 0 10px 14px -6px rgba(0, 0, 0, 0.2), 0 22px 35px 3px rgba(0, 0, 0, 0.14), 0 8px 42px 7px rgba(0, 0, 0, 0.12);
// }
// .mat-elevation-z23 {
//   box-shadow: 0 11px 14px -7px rgba(0, 0, 0, 0.2), 0 23px 36px 3px rgba(0, 0, 0, 0.14), 0 9px 44px 8px rgba(0, 0, 0, 0.12);
// }
// .mat-elevation-z24 {
//   box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
// }
.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-autocomplete-panel {
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
  &:not([class*="mat-elevation-z"]) {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  }
  .mat-option.mat-selected:not(.mat-active):not(:hover) {
    background: #fff;
    &:not(.mat-option-disabled) {
      color: rgba(0, 0, 0, 0.87);
    }
  }
}

.mat-badge-content {
  color: #fff;
  background: $green2;
}

.mat-badge-accent .mat-badge-content {
  background: $red;
  color: #fff;
}

.mat-badge-warn .mat-badge-content {
  color: #fff;
  background: $red;
}

.mat-badge {
  position: relative;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 0.2s ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
  &.mat-badge-active {
    transform: none;
  }
}

.mat-badge-small {
  .mat-badge-content {
    width: 16px;
    height: 16px;
    line-height: 16px;
  }
  &.mat-badge-above .mat-badge-content {
    top: -8px;
  }
  &.mat-badge-below .mat-badge-content {
    bottom: -8px;
  }
  &.mat-badge-before .mat-badge-content {
    left: -16px;
  }
}

@media screen and (-ms-high-contrast: active) {
  .mat-badge-small .mat-badge-content {
    outline: solid 1px;
    border-radius: 0;
  }
}

[dir="rtl"] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}

.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}

[dir="rtl"] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}

.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}

[dir="rtl"]
  .mat-badge-small.mat-badge-overlap.mat-badge-before
  .mat-badge-content {
  left: auto;
  right: -8px;
}

.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}

[dir="rtl"]
  .mat-badge-small.mat-badge-overlap.mat-badge-after
  .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium {
  .mat-badge-content {
    width: 22px;
    height: 22px;
    line-height: 22px;
  }
  &.mat-badge-above .mat-badge-content {
    top: -11px;
  }
  &.mat-badge-below .mat-badge-content {
    bottom: -11px;
  }
  &.mat-badge-before .mat-badge-content {
    left: -22px;
  }
}

@media screen and (-ms-high-contrast: active) {
  .mat-badge-medium .mat-badge-content {
    outline: solid 1px;
    border-radius: 0;
  }
}

[dir="rtl"] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}

.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}

[dir="rtl"] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}

.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}

[dir="rtl"]
  .mat-badge-medium.mat-badge-overlap.mat-badge-before
  .mat-badge-content {
  left: auto;
  right: -11px;
}

.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}

[dir="rtl"]
  .mat-badge-medium.mat-badge-overlap.mat-badge-after
  .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large {
  .mat-badge-content {
    width: 28px;
    height: 28px;
    line-height: 28px;
  }
  &.mat-badge-above .mat-badge-content {
    top: -14px;
  }
  &.mat-badge-below .mat-badge-content {
    bottom: -14px;
  }
  &.mat-badge-before .mat-badge-content {
    left: -28px;
  }
}

@media screen and (-ms-high-contrast: active) {
  .mat-badge-large .mat-badge-content {
    outline: solid 1px;
    border-radius: 0;
  }
}

[dir="rtl"] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}

.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}

[dir="rtl"] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}

.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}

[dir="rtl"]
  .mat-badge-large.mat-badge-overlap.mat-badge-before
  .mat-badge-content {
  left: auto;
  right: -14px;
}

.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}

[dir="rtl"]
  .mat-badge-large.mat-badge-overlap.mat-badge-after
  .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-bottom-sheet-container {
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2),
    0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
}

.mat-button.mat-primary,
.mat-icon-button.mat-primary,
.mat-stroked-button.mat-primary {
  color: $green2;
}

.mat-button.mat-accent,
.mat-icon-button.mat-accent,
.mat-stroked-button.mat-accent {
  color: $red;
}

.mat-button.mat-warn,
.mat-icon-button.mat-warn,
.mat-stroked-button.mat-warn {
  color: $red;
}

.mat-button {
  &.mat-accent[disabled],
  &.mat-primary[disabled],
  &.mat-warn[disabled],
  &[disabled][disabled] {
    color: rgba(255, 255, 255, 0.7);
  }
}

.mat-icon-button {
  &.mat-accent[disabled],
  &.mat-primary[disabled],
  &.mat-warn[disabled],
  &[disabled][disabled] {
    color: rgba(255, 255, 255, 0.7);
  }
}

.mat-stroked-button {
  &.mat-accent[disabled],
  &.mat-primary[disabled],
  &.mat-warn[disabled],
  &[disabled][disabled] {
    color: rgba(0, 0, 0, 0.26);
  }
}

.mat-button.mat-primary .mat-button-focus-overlay,
.mat-icon-button.mat-primary .mat-button-focus-overlay,
.mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: $green2;
}

.mat-button.mat-accent .mat-button-focus-overlay,
.mat-icon-button.mat-accent .mat-button-focus-overlay,
.mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: $red;
}

.mat-button.mat-warn .mat-button-focus-overlay,
.mat-icon-button.mat-warn .mat-button-focus-overlay,
.mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: $red;
}

.mat-button[disabled] .mat-button-focus-overlay,
.mat-icon-button[disabled] .mat-button-focus-overlay,
.mat-stroked-button[disabled] .mat-button-focus-overlay {
  background-color: transparent;
}

.mat-button.mat-primary .mat-ripple-element,
.mat-icon-button.mat-primary .mat-ripple-element,
.mat-stroked-button.mat-primary .mat-ripple-element {
  background-color: rgba(63, 81, 181, 0.1);
}

.mat-button.mat-accent .mat-ripple-element,
.mat-icon-button.mat-accent .mat-ripple-element,
.mat-stroked-button.mat-accent .mat-ripple-element {
  background-color: rgba(255, 64, 129, 0.1);
}

.mat-button.mat-warn .mat-ripple-element,
.mat-icon-button.mat-warn .mat-ripple-element,
.mat-stroked-button.mat-warn .mat-ripple-element {
  background-color: rgba(244, 67, 54, 0.1);
}

.mat-button-focus-overlay {
  background: #000;
}

.mat-stroked-button:not([disabled]) {
  border-color: rgba(0, 0, 0, 0.12);
}

.mat-fab,
.mat-flat-button,
.mat-mini-fab,
.mat-raised-button {
  color: rgba(0, 0, 0, 0.87);
  background-color: #fff;
}

.mat-fab.mat-primary,
.mat-flat-button.mat-primary,
.mat-mini-fab.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-accent,
.mat-flat-button.mat-accent,
.mat-mini-fab.mat-accent,
.mat-raised-button.mat-accent,
.mat-fab.mat-warn,
.mat-flat-button.mat-warn,
.mat-mini-fab.mat-warn,
.mat-raised-button.mat-warn {
  color: #fff;
}

.mat-fab {
  &.mat-accent[disabled],
  &.mat-primary[disabled],
  &.mat-warn[disabled],
  &[disabled][disabled] {
    color: rgba(0, 0, 0, 0.26);
  }
}

.mat-flat-button {
  &.mat-accent[disabled],
  &.mat-primary[disabled],
  &.mat-warn[disabled],
  &[disabled][disabled] {
    color: rgba(0, 0, 0, 0.26);
  }
}

.mat-mini-fab {
  &.mat-accent[disabled],
  &.mat-primary[disabled],
  &.mat-warn[disabled],
  &[disabled][disabled] {
    color: rgba(0, 0, 0, 0.26);
  }
}

.mat-raised-button {
  &.mat-accent[disabled],
  &.mat-primary[disabled],
  &.mat-warn[disabled],
  &[disabled][disabled] {
    color: rgba(0, 0, 0, 0.26);
  }
}

.mat-fab.mat-primary,
.mat-flat-button.mat-primary,
.mat-mini-fab.mat-primary,
.mat-raised-button.mat-primary {
  background-color: $green2;
}

.mat-fab.mat-accent,
.mat-flat-button.mat-accent,
.mat-mini-fab.mat-accent,
.mat-raised-button.mat-accent {
  background-color: $white;
}

.mat-fab.mat-warn,
.mat-flat-button.mat-warn,
.mat-mini-fab.mat-warn,
.mat-raised-button.mat-warn {
  background-color: $red;
}

.mat-fab {
  &.mat-accent[disabled],
  &.mat-primary[disabled],
  &.mat-warn[disabled],
  &[disabled][disabled] {
    background-color: rgba(0, 0, 0, 0.12);
  }
}

.mat-flat-button {
  &.mat-accent[disabled],
  &.mat-primary[disabled],
  &.mat-warn[disabled],
  &[disabled][disabled] {
    background-color: rgba(0, 0, 0, 0.12);
  }
}

.mat-mini-fab {
  &.mat-accent[disabled],
  &.mat-primary[disabled],
  &.mat-warn[disabled],
  &[disabled][disabled] {
    background-color: rgba(0, 0, 0, 0.12);
  }
}

.mat-raised-button {
  &.mat-accent[disabled],
  &.mat-primary[disabled],
  &.mat-warn[disabled],
  &[disabled][disabled] {
    background-color: rgba(0, 0, 0, 0.12);
  }
}

.mat-fab.mat-primary .mat-ripple-element,
.mat-flat-button.mat-primary .mat-ripple-element,
.mat-mini-fab.mat-primary .mat-ripple-element,
.mat-raised-button.mat-primary .mat-ripple-element,
.mat-fab.mat-accent .mat-ripple-element,
.mat-flat-button.mat-accent .mat-ripple-element,
.mat-mini-fab.mat-accent .mat-ripple-element,
.mat-raised-button.mat-accent .mat-ripple-element,
.mat-fab.mat-warn .mat-ripple-element,
.mat-flat-button.mat-warn .mat-ripple-element,
.mat-mini-fab.mat-warn .mat-ripple-element,
.mat-raised-button.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-icon-button {
  &.mat-primary .mat-ripple-element {
    background-color: rgba(63, 81, 181, 0.2);
  }
  &.mat-accent .mat-ripple-element {
    background-color: rgba(255, 64, 129, 0.2);
  }
  &.mat-warn .mat-ripple-element {
    background-color: rgba(244, 67, 54, 0.2);
  }
}

.mat-flat-button:not([class*="mat-elevation-z"]),
.mat-stroked-button:not([class*="mat-elevation-z"]) {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 0 0 0 rgba(0, 0, 0, 0.14),
    0 0 0 0 rgba(0, 0, 0, 0.12);
}

.mat-raised-button {
  &:not([class*="mat-elevation-z"]) {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }
  &:not([disabled]):active:not([class*="mat-elevation-z"]) {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  }
  &[disabled]:not([class*="mat-elevation-z"]) {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 0 0 0 rgba(0, 0, 0, 0.14),
      0 0 0 0 rgba(0, 0, 0, 0.12);
  }
}

.mat-fab:not([class*="mat-elevation-z"]),
.mat-mini-fab:not([class*="mat-elevation-z"]) {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
}

.mat-fab:not([disabled]):active:not([class*="mat-elevation-z"]),
.mat-mini-fab:not([disabled]):active:not([class*="mat-elevation-z"]) {
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2),
    0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-fab[disabled]:not([class*="mat-elevation-z"]),
.mat-mini-fab[disabled]:not([class*="mat-elevation-z"]) {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 0 0 0 rgba(0, 0, 0, 0.14),
    0 0 0 0 rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-group,
.mat-button-toggle-standalone {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-group-appearance-standard,
.mat-button-toggle-standalone.mat-button-toggle-appearance-standard {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
  .mat-button-toggle-focus-overlay {
    background-color: rgba(0, 0, 0, 0.12);
  }
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: #fff;
  .mat-button-toggle-focus-overlay {
    background-color: #000;
  }
}

.mat-button-toggle-group-appearance-standard
  .mat-button-toggle
  + .mat-button-toggle {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
}

[dir="rtl"]
  .mat-button-toggle-group-appearance-standard
  .mat-button-toggle
  + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical
  .mat-button-toggle
  + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
  &.mat-button-toggle-appearance-standard {
    color: rgba(0, 0, 0, 0.87);
  }
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eee;
  &.mat-button-toggle-appearance-standard {
    background: #fff;
  }
  &.mat-button-toggle-checked {
    background-color: #bdbdbd;
  }
}

.mat-button-toggle-group-appearance-standard,
.mat-button-toggle-standalone.mat-button-toggle-appearance-standard {
  border: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-card {
  height: 95%;
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
  &:not([class*="mat-elevation-z"]) {
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
      0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  }
  &.mat-card-flat:not([class*="mat-elevation-z"]) {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 0 0 0 rgba(0, 0, 0, 0.14),
      0 0 0 0 rgba(0, 0, 0, 0.12);
  }
}

.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
  fill: #fafafa;
}

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}

@media screen and (-ms-high-contrast: black-on-white) {
  .mat-checkbox-checkmark-path {
    stroke: #000 !important;
  }
}

.mat-checkbox-mixedmark {
  background-color: #fafafa;
}

.mat-checkbox-checked.mat-primary .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background {
  background-color: $green2;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: $green2 !important;
}

.mat-checkbox-checked.mat-warn .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background {
  background-color: $green2;
}

.mat-checkbox-disabled {
  &.mat-checkbox-checked:not(.mat-checkbox-indeterminate)
    .mat-checkbox-background {
    background-color: #b0b0b0;
  }
  &:not(.mat-checkbox-checked) .mat-checkbox-frame {
    border-color: #b0b0b0;
  }
  .mat-checkbox-label {
    color: #b0b0b0;
  }
  .mat-checkbox-inner-container {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

@media screen and (-ms-high-contrast: active) {
  .mat-checkbox-disabled {
    opacity: 0.5;
  }
}

@media screen and (-ms-high-contrast: active) {
  .mat-checkbox-background {
    background: 0 0;
  }
}

.mat-checkbox:not(.mat-checkbox-disabled) {
  &.mat-primary .mat-checkbox-ripple .mat-ripple-element {
    background-color: $green2;
  }
  &.mat-accent .mat-checkbox-ripple .mat-ripple-element {
    background-color: $green2;
  }
  &.mat-warn .mat-checkbox-ripple .mat-ripple-element {
    background-color: $green2;
  }
}

.mat-chip.mat-standard-chip {
  background-color: #ececec;
  color: $light-grey;
  min-height: 26px !important;
  border-radius: 3px;
  .mat-chip-remove {
    color: $light-grey;
    opacity: 1;
    &:hover {
      opacity: 0.54;
    }
  }
  &:focus {
    box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2),
      0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
  }
  &.mat-chip-selected {
    &.mat-primary {
      background-color: $green2;
      color: $white;
      .mat-chip-remove {
        color: $white;
        opacity: 0.4;
        &:hover {
          opacity: 0.54;
        }
      }
    }
    &.mat-warn {
      background-color: $red;
      color: $white;
      .mat-chip-remove {
        color: $white;
        opacity: 0.4;
        &:hover {
          opacity: 0.54;
        }
      }
    }
    &.mat-accent {
      background-color: $red;
      color: $white;
      .mat-chip-remove {
        color: $white;
        opacity: 0.4;
        &:hover {
          opacity: 0.54;
        }
      }
    }
  }
}

.mat-table {
  background: $white;
  box-shadow: none;
  tbody,
  tfoot,
  thead {
    background: inherit;
  }
}

.mat-paginator {
  position: sticky;
  width: 100%;
  left: 0px;
  right: 0px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  background: $white;
  float: revert;
  display: flex;
  justify-content: flex-end;
}

.mat-table-sticky,
[mat-footer-row],
[mat-header-row],
[mat-row],
mat-footer-row,
mat-header-row,
mat-row {
  background: inherit;
}

mat-footer-row,
mat-header-row,
mat-row {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

td {
  &.mat-cell,
  &.mat-footer-cell {
    border-bottom-color: rgba(0, 0, 0, 0.12);
  }
}

th.mat-header-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}

.mat-cell,
.mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mat-calendar-arrow {
  border-top-color: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-content {
  .mat-calendar-next-button,
  .mat-calendar-previous-button {
    color: rgba(0, 0, 0, 0.54);
  }
}

.mat-datepicker-toggle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  color: rgba(0, 0, 0, 0.38);
}

.cdk-keyboard-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-program-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  background-color: rgba(0, 0, 0, 0.04);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled
  > .mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-selected {
  background-color: $green2;
  color: #fff;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(63, 81, 181, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px #fff;
}

.mat-datepicker-content {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  &.mat-accent {
    .mat-calendar-body-selected {
      background-color: $red;
      color: #fff;
    }
    .mat-calendar-body-disabled > .mat-calendar-body-selected {
      background-color: rgba(255, 64, 129, 0.4);
    }
    .mat-calendar-body-today.mat-calendar-body-selected {
      box-shadow: inset 0 0 0 1px #fff;
    }
  }
  &.mat-warn {
    .mat-calendar-body-selected {
      background-color: $red;
      color: #fff;
    }
    .mat-calendar-body-disabled > .mat-calendar-body-selected {
      background-color: rgba(244, 67, 54, 0.4);
    }
    .mat-calendar-body-today.mat-calendar-body-selected {
      box-shadow: inset 0 0 0 1px #fff;
    }
  }
}

.mat-datepicker-content-touch {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 0 0 0 rgba(0, 0, 0, 0.14),
    0 0 0 0 rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: $d_black;
  &.mat-accent {
    color: $red;
  }
  &.mat-warn {
    color: $red;
  }
}

.mat-dialog-container {
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
  &:not([class*="mat-elevation-z"]) {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel:not(.mat-expanded)
  .mat-expansion-panel-header:not([aria-disabled="true"]) {
  &.cdk-keyboard-focused,
  &.cdk-program-focused,
  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled="true"])
    .mat-expansion-panel-header:hover {
    background: #fff;
  }
}

.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-indicator::after,
.mat-expansion-panel-header-description {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled="true"] {
  color: rgba(0, 0, 0, 0.26);
  .mat-expansion-panel-header-description,
  .mat-expansion-panel-header-title {
    color: inherit;
  }
}

.mat-form-field-label,
.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: $green2;
  &.mat-accent {
    color: $red;
  }
  &.mat-warn {
    color: $red;
  }
}

.mat-focused .mat-form-field-required-marker {
  color: $red;
}

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-form-field {
  width: 100%;
  // min-width: 180px;
  &.mat-focused .mat-form-field-ripple {
    background-color: $green2;
    &.mat-accent {
      background-color: $red;
    }
    &.mat-warn {
      background-color: $red;
    }
  }
  &.mat-form-field-invalid {
    .mat-form-field-label {
      color: $red;
      .mat-form-field-required-marker,
      &.mat-accent {
        color: $red;
      }
    }
    .mat-form-field-ripple {
      background-color: $red;
      &.mat-accent {
        background-color: $red;
      }
    }
  }
  &.button_type {
    .mat-form-field-wrapper {
      padding: 0;
    }
    .mat-form-field-underline {
      display: none;
    }
    .mat-form-field-infix {
      padding: 0.4375em 0 !important;
    }
    .mat-select-arrow {
      top: 0 !important;
    }
    mat-select {
      padding: 0.8rem;
      background: #fff;
      @include border-radius(2px);
      @include box-shadow(0px 1px 3px 0px rgba(0, 0, 0, 0.12));
    }
    .mat-form-field-label-wrapper {
      .mat-form-field-empty {
        top: 28px;
        left: 12px;
      }
    }
    &.mat-focused {
      .mat-form-field-label-wrapper {
        .mat-form-field-empty {
          top: 1.28125em;
          left: 0;
        }
      }
    }
  }
}

.mat-error {
  color: $red;
}

.mat-form-field-appearance-legacy {
  .mat-form-field-label,
  .mat-hint {
    color: rgba(0, 0, 0, 0.54);
  }
  .mat-form-field-underline {
    background-color: $gray-400;
  }
  &.mat-form-field-disabled .mat-form-field-underline {
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.42) 0,
      rgba(0, 0, 0, 0.42) 33%,
      transparent 0
    );
    background-size: 4px 100%;
    background-repeat: repeat-x;
  }
}

.mat-form-field-appearance-standard {
  .mat-form-field-underline {
    background-color: rgba(0, 0, 0, 0.42);
  }
  &.mat-form-field-disabled .mat-form-field-underline {
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.42) 0,
      rgba(0, 0, 0, 0.42) 33%,
      transparent 0
    );
    background-size: 4px 100%;
    background-repeat: repeat-x;
  }
}

.mat-form-field-appearance-fill {
  .mat-form-field-flex {
    background-color: rgba(0, 0, 0, 0.04);
  }
  &.mat-form-field-disabled .mat-form-field-flex {
    background-color: rgba(0, 0, 0, 0.02);
  }
  .mat-form-field-underline::before {
    background-color: rgba(0, 0, 0, 0.42);
  }
  &.mat-form-field-disabled {
    .mat-form-field-label {
      color: rgba(0, 0, 0, 0.38);
    }
    .mat-form-field-underline::before {
      background-color: transparent;
    }
  }
}

.mat-form-field-appearance-outline {
  .mat-form-field-outline {
    color: rgba(0, 0, 0, 0.12);
  }
  .mat-form-field-outline-thick {
    color: rgba(0, 0, 0, 0.87);
  }
  &.mat-focused {
    .mat-form-field-outline-thick {
      color: $green2;
    }
    &.mat-accent .mat-form-field-outline-thick {
      color: $red;
    }
    &.mat-warn .mat-form-field-outline-thick {
      color: $red;
    }
  }
  &.mat-form-field-invalid.mat-form-field-invalid
    .mat-form-field-outline-thick {
    color: $red;
  }
  &.mat-form-field-disabled {
    .mat-form-field-label {
      color: rgba(0, 0, 0, 0.38);
    }
    .mat-form-field-outline {
      color: rgba(0, 0, 0, 0.06);
    }
  }
}

.mat-icon {
  &.mat-primary {
    color: $d_black;
  }
  &.mat-accent {
    color: $red;
  }
  &.mat-warn {
    color: $red;
  }
}

.mat-form-field-type-mat-native-select {
  .mat-form-field-infix::after {
    color: rgba(0, 0, 0, 0.54);
  }
  &.mat-form-field-disabled .mat-form-field-infix::after {
    color: rgba(0, 0, 0, 0.38);
  }
}

.mat-input-element {
  &:disabled {
    color: rgba(0, 0, 0, 0.38);
  }
  caret-color: $green2;
  &::placeholder,
  &::-moz-placeholder,
  &::-webkit-input-placeholder,
  &:-ms-input-placeholder {
    color: $d_black;
  }
}

.mat-accent .mat-input-element {
  caret-color: $red;
}

.mat-form-field-invalid .mat-input-element,
.mat-warn .mat-input-element {
  caret-color: $red;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid
  .mat-form-field-infix::after {
  color: $red;
}

.mat-list .mat-list-item,
.mat-nav-list .mat-list-item,
.mat-selection-list .mat-list-item,
.mat-list .mat-list-option,
.mat-nav-list .mat-list-option,
.mat-selection-list .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}

.mat-list .mat-subheader,
.mat-nav-list .mat-subheader,
.mat-selection-list .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}

.mat-list-item-disabled {
  background-color: #eee;
}

.mat-list-option {
  &:focus,
  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }
}

.mat-nav-list .mat-list-item {
  &:focus,
  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }
}

.mat-menu-panel {
  background: #fff;
  min-height: auto !important;
  &:not([class*="mat-elevation-z"]) {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  }
}

.mat-menu-item {
  background: 0 0;
  color: rgba(0, 0, 0, 0.87);
  &[disabled] {
    color: rgba(0, 0, 0, 0.38);
    &::after {
      color: rgba(0, 0, 0, 0.38);
    }
  }
  .mat-icon:not([color]) {
    color: rgba(0, 0, 0, 0.54);
  }
}

.mat-menu-item-submenu-trigger::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-menu-item {
  &.cdk-keyboard-focused:not([disabled]),
  &.cdk-program-focused:not([disabled]),
  &:hover:not([disabled]) {
    background: rgba(0, 0, 0, 0.04);
    color: $gray-900;
  }
}

.mat-paginator {
  background: #fff;
  color: rgba(0, 0, 0, 0.54);
}

.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-icon-button[disabled] {
  .mat-paginator-decrement,
  .mat-paginator-first,
  .mat-paginator-increment,
  .mat-paginator-last {
    border-color: rgba(0, 0, 0, 0.38);
  }
}

.mat-progress-bar-background {
  fill: #c5cae9;
}

.mat-progress-bar-buffer {
  background-color: #c5cae9;
}

.mat-progress-bar-fill::after {
  background-color: $green2;
}

.mat-progress-bar {
  &.mat-accent {
    .mat-progress-bar-background {
      fill: #ff80ab;
    }
    .mat-progress-bar-buffer {
      background-color: #ff80ab;
    }
    .mat-progress-bar-fill::after {
      background-color: $red;
    }
  }
  &.mat-warn {
    .mat-progress-bar-background {
      fill: #ffcdd2;
    }
    .mat-progress-bar-buffer {
      background-color: #ffcdd2;
    }
    .mat-progress-bar-fill::after {
      background-color: $red;
    }
  }
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: $green2;
}

.mat-progress-spinner.mat-accent circle,
.mat-spinner.mat-accent circle {
  stroke: $red;
}

.mat-progress-spinner.mat-warn circle,
.mat-spinner.mat-warn circle {
  stroke: $red;
}

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-radio-button {
  &.mat-primary {
    &.mat-radio-checked .mat-radio-outer-circle {
      border-color: $green2;
    }
    .mat-radio-inner-circle,
    .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
    &.mat-radio-checked .mat-radio-persistent-ripple,
    &:active .mat-radio-persistent-ripple {
      background-color: $green2;
    }
  }
  &.mat-accent {
    &.mat-radio-checked .mat-radio-outer-circle {
      border-color: $green2;
    }
    .mat-radio-inner-circle,
    .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
    &.mat-radio-checked .mat-radio-persistent-ripple,
    &:active .mat-radio-persistent-ripple {
      background-color: $green2;
    }
  }
  &.mat-warn {
    &.mat-radio-checked .mat-radio-outer-circle {
      border-color: $green2;
    }
    .mat-radio-inner-circle,
    .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
    &.mat-radio-checked .mat-radio-persistent-ripple,
    &:active .mat-radio-persistent-ripple {
      background-color: $green2;
    }
  }
  &.mat-radio-disabled {
    .mat-radio-outer-circle,
    &.mat-radio-checked .mat-radio-outer-circle {
      border-color: rgba(0, 0, 0, 0.38);
    }
    .mat-radio-inner-circle,
    .mat-radio-ripple .mat-ripple-element {
      background-color: rgba(0, 0, 0, 0.38);
    }
    .mat-radio-label-content {
      color: rgba(0, 0, 0, 0.38);
    }
  }
  .mat-ripple-element {
    background-color: #000;
  }
}

.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
  .mat-select-value-text {
    color: $d_black;
  }
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
  // border: 0 !important;
  // margin: 0 !important;
  // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='11px' height='11px'%3E%3Cpath fill-rule='evenodd' opacity='0.702' fill='rgb(95, 95, 107)' d='M8.615,5.213 L7.151,6.517 L7.151,1.663 C7.151,1.021 6.567,0.500 5.846,0.500 C5.126,0.500 4.541,1.021 4.541,1.663 L4.541,6.517 L3.077,5.213 C2.567,4.758 1.741,4.758 1.231,5.213 C0.721,5.667 0.721,6.404 1.231,6.858 L4.923,10.150 L4.923,10.150 C5.178,10.377 5.512,10.491 5.846,10.491 C6.013,10.491 6.180,10.462 6.337,10.405 C6.495,10.349 6.642,10.263 6.769,10.150 L10.461,6.859 C10.971,6.405 10.971,5.667 10.461,5.213 C9.952,4.758 9.125,4.758 8.615,5.213 Z'/%3E%3C/svg%3E");
  // background-repeat: no-repeat;
  // width: 12px !important;
  // height: 11px !important;
  // display: inline-block !important;
  // top: -4px !important;
  // position: relative;
}

.mat-select-panel {
  // margin-top: 8px !important;
  background: #fff;
  &:not([class*="mat-elevation-z"]) {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  }
  .mat-option.mat-selected:not(.mat-option-multiple) {
    background: rgba(202, 202, 202, 1);
  }
}

.mat-form-field {
  &.mat-focused {
    &.mat-primary .mat-select-arrow {
      color: $green2;
    }
    &.mat-accent .mat-select-arrow {
      color: $red;
    }
    &.mat-warn .mat-select-arrow {
      color: $red;
    }
  }
  .mat-select {
    &.mat-select-invalid .mat-select-arrow {
      color: $red;
    }
    &.mat-select-disabled .mat-select-arrow {
      color: rgba(0, 0, 0, 0.38);
    }
  }
}

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  &.mat-drawer-push {
    background-color: #fff;
  }
  &:not(.mat-drawer-side) {
    box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2),
      0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12);
  }
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
  &.mat-drawer-end {
    border-left: solid 1px rgba(0, 0, 0, 0.12);
    border-right: none;
  }
}

[dir="rtl"] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
  &.mat-drawer-end {
    border-left: none;
    border-right: solid 1px rgba(0, 0, 0, 0.12);
  }
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-slide-toggle {
  &.mat-checked:not(.mat-disabled) {
    .mat-slide-toggle-thumb {
      background-color: $white;
    }
    .mat-slide-toggle-bar {
      background-color: rgba(62, 203, 62, 1);
    }
    .mat-ripple-element {
      background-color: $red;
    }
  }
  &.mat-primary.mat-checked:not(.mat-disabled) {
    .mat-slide-toggle-thumb {
      background-color: $d_black;
    }
    .mat-slide-toggle-bar {
      background-color: rgba(63, 81, 181, 0.54);
    }
    .mat-ripple-element {
      background-color: $green2;
    }
  }
  &.mat-warn.mat-checked:not(.mat-disabled) {
    .mat-slide-toggle-thumb {
      background-color: $red;
    }
    .mat-slide-toggle-bar {
      background-color: rgba(244, 67, 54, 0.54);
    }
    .mat-ripple-element {
      background-color: $red;
    }
  }
  &:not(.mat-checked) .mat-ripple-element {
    background-color: #000;
  }
}

.mat-disabled {
  .mat-slide-toggle-thumb {
    background-color: #bdbdbd;
  }
  .mat-slide-toggle-bar {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.mat-slide-toggle-thumb {
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: #d2e9ff;
}

.mat-slider-track-background {
  background-color: #e7f1fb;
}

.mat-primary {
  .mat-slider-thumb,
  .mat-slider-thumb-label,
  .mat-slider-track-fill {
    background-color: $green2;
  }
  .mat-slider-thumb-label-text {
    color: #fff;
  }
}

.mat-accent {
  .mat-slider-thumb {
    background-color: #fff;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  }
  .mat-slider-thumb-label,
  .mat-slider-track-fill {
    background-color: $green2;
  }
  .mat-slider-thumb-label-text {
    color: #fff;
  }
}

.mat-warn {
  .mat-slider-thumb,
  .mat-slider-thumb-label,
  .mat-slider-track-fill {
    background-color: $green2;
  }
  .mat-slider-thumb-label-text {
    color: #fff;
  }
}

.mat-slider-focus-ring {
  background-color: rgba(255, 64, 129, 0.2);
}

.cdk-focused .mat-slider-track-background,
.mat-slider:hover .mat-slider-track-background {
  background-color: #d7eafe;
}

.mat-slider-disabled {
  .mat-slider-thumb,
  .mat-slider-track-background,
  .mat-slider-track-fill,
  &:hover .mat-slider-track-background {
    background-color: rgba(0, 0, 0, 0.26);
  }
}

.mat-slider-min-value {
  .mat-slider-focus-ring {
    background-color: rgba(0, 0, 0, 0.12);
  }
  &.mat-slider-thumb-label-showing {
    .mat-slider-thumb {
      background-color: rgba(255, 255, 255, 1);
    }
    .mat-slider-thumb-label {
      background-color: rgba(0, 0, 0, 0.87);
    }
    &.cdk-focused {
      .mat-slider-thumb,
      .mat-slider-thumb-label {
        background-color: rgba(0, 0, 0, 0.26);
      }
    }
  }
  &:not(.mat-slider-thumb-label-showing) {
    .mat-slider-thumb {
      border-color: rgba(0, 0, 0, 0.26);
      background-color: transparent;
    }
    &.cdk-focused .mat-slider-thumb,
    &:hover .mat-slider-thumb {
      border-color: rgba(0, 0, 0, 0.38);
    }
    &.cdk-focused.mat-slider-disabled .mat-slider-thumb,
    &:hover.mat-slider-disabled .mat-slider-thumb {
      border-color: rgba(0, 0, 0, 0.26);
    }
  }
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}

.mat-slider {
  display: block !important;
  padding: 0 !important;
  &.mat-slider-horizontal {
    .mat-slider-ticks {
      background-image: repeating-linear-gradient(
        to right,
        rgba(0, 0, 0, 0.7),
        rgba(0, 0, 0, 0.7) 2px,
        transparent 0,
        transparent
      );
      background-image: -moz-repeating-linear-gradient(
        0.0001deg,
        rgba(0, 0, 0, 0.7),
        rgba(0, 0, 0, 0.7) 2px,
        transparent 0,
        transparent
      );
      height: 5px !important;
    }
    .mat-slider-wrapper {
      left: 0 !important;
      right: 0 !important;
      .mat-slider-track-wrapper {
        height: 5px !important;
        border-radius: 5px;
        div {
          height: 5px !important;
          border-radius: 5px;
        }
      }
      .mat-slider-thumb-container {
        .mat-slider-thumb {
          bottom: -11px !important;
        }
      }
    }
  }
  &.mat-slider-vertical {
    .mat-slider-ticks {
      background-image: repeating-linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.7),
        rgba(0, 0, 0, 0.7) 2px,
        transparent 0,
        transparent
      );
    }
  }
}

.mat-step-header {
  &.cdk-keyboard-focused,
  &.cdk-program-focused,
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
  .mat-step-label,
  .mat-step-optional {
    color: rgba(0, 0, 0, 0.38);
  }
  .mat-step-icon {
    background-color: rgba(0, 0, 0, 0.38);
    color: #fff;
  }
  .mat-step-icon-selected,
  .mat-step-icon-state-done,
  .mat-step-icon-state-edit {
    background-color: $green2;
    color: #fff;
  }
  .mat-step-icon-state-error {
    background-color: transparent;
    color: $red;
  }
  .mat-step-label {
    &.mat-step-label-active {
      color: rgba(0, 0, 0, 0.87);
    }
    &.mat-step-label-error {
      color: $red;
    }
  }
}

.mat-stepper-horizontal,
.mat-stepper-vertical {
  background-color: #fff;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-tab-header,
.mat-tab-nav-bar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-tab-group-inverted-header {
  .mat-tab-header,
  .mat-tab-nav-bar {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom: none;
  }
}

.mat-tab-label,
.mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tab-label.mat-tab-disabled,
.mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-tab-group[class*="mat-background-"] .mat-tab-header,
.mat-tab-nav-bar[class*="mat-background-"] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary {
  .mat-tab-label {
    &.cdk-keyboard-focused:not(.mat-tab-disabled),
    &.cdk-program-focused:not(.mat-tab-disabled) {
      background-color: rgba(197, 202, 233, 0.3);
    }
  }
  .mat-tab-link {
    &.cdk-keyboard-focused:not(.mat-tab-disabled),
    &.cdk-program-focused:not(.mat-tab-disabled) {
      background-color: rgba(197, 202, 233, 0.3);
    }
  }
}

.mat-tab-nav-bar.mat-primary {
  .mat-tab-label {
    &.cdk-keyboard-focused:not(.mat-tab-disabled),
    &.cdk-program-focused:not(.mat-tab-disabled) {
      background-color: rgba(197, 202, 233, 0.3);
    }
  }
  .mat-tab-link {
    &.cdk-keyboard-focused:not(.mat-tab-disabled),
    &.cdk-program-focused:not(.mat-tab-disabled) {
      background-color: rgba(197, 202, 233, 0.3);
    }
  }
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: $d_black;
}

.mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: #fff;
}

.mat-tab-group.mat-accent {
  .mat-tab-label {
    &.cdk-keyboard-focused:not(.mat-tab-disabled),
    &.cdk-program-focused:not(.mat-tab-disabled) {
      background-color: rgba(255, 128, 171, 0.3);
    }
  }
  .mat-tab-link {
    &.cdk-keyboard-focused:not(.mat-tab-disabled),
    &.cdk-program-focused:not(.mat-tab-disabled) {
      background-color: rgba(255, 128, 171, 0.3);
    }
  }
}

.mat-tab-nav-bar.mat-accent {
  .mat-tab-label {
    &.cdk-keyboard-focused:not(.mat-tab-disabled),
    &.cdk-program-focused:not(.mat-tab-disabled) {
      background-color: rgba(255, 128, 171, 0.3);
    }
  }
  .mat-tab-link {
    &.cdk-keyboard-focused:not(.mat-tab-disabled),
    &.cdk-program-focused:not(.mat-tab-disabled) {
      background-color: rgba(255, 128, 171, 0.3);
    }
  }
}

.mat-tab-group.mat-accent .mat-ink-bar,
.mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: $red;
}

.mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar,
.mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
  background-color: #fff;
}

.mat-tab-group.mat-warn {
  .mat-tab-label {
    &.cdk-keyboard-focused:not(.mat-tab-disabled),
    &.cdk-program-focused:not(.mat-tab-disabled) {
      background-color: rgba(255, 205, 210, 0.3);
    }
  }
  .mat-tab-link {
    &.cdk-keyboard-focused:not(.mat-tab-disabled),
    &.cdk-program-focused:not(.mat-tab-disabled) {
      background-color: rgba(255, 205, 210, 0.3);
    }
  }
}

.mat-tab-nav-bar.mat-warn {
  .mat-tab-label {
    &.cdk-keyboard-focused:not(.mat-tab-disabled),
    &.cdk-program-focused:not(.mat-tab-disabled) {
      background-color: rgba(255, 205, 210, 0.3);
    }
  }
  .mat-tab-link {
    &.cdk-keyboard-focused:not(.mat-tab-disabled),
    &.cdk-program-focused:not(.mat-tab-disabled) {
      background-color: rgba(255, 205, 210, 0.3);
    }
  }
}

.mat-tab-group.mat-warn .mat-ink-bar,
.mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: $red;
}

.mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar,
.mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
  background-color: #fff;
}

.mat-tab-group.mat-background-primary {
  .mat-tab-label {
    &.cdk-keyboard-focused:not(.mat-tab-disabled),
    &.cdk-program-focused:not(.mat-tab-disabled) {
      background-color: rgba(197, 202, 233, 0.3);
    }
  }
  .mat-tab-link {
    &.cdk-keyboard-focused:not(.mat-tab-disabled),
    &.cdk-program-focused:not(.mat-tab-disabled) {
      background-color: rgba(197, 202, 233, 0.3);
    }
  }
}

.mat-tab-nav-bar.mat-background-primary {
  .mat-tab-label {
    &.cdk-keyboard-focused:not(.mat-tab-disabled),
    &.cdk-program-focused:not(.mat-tab-disabled) {
      background-color: rgba(197, 202, 233, 0.3);
    }
  }
  .mat-tab-link {
    &.cdk-keyboard-focused:not(.mat-tab-disabled),
    &.cdk-program-focused:not(.mat-tab-disabled) {
      background-color: rgba(197, 202, 233, 0.3);
    }
  }
}

.mat-tab-group.mat-background-primary {
  .mat-tab-header,
  .mat-tab-links {
    background-color: $green2;
  }
}

.mat-tab-nav-bar.mat-background-primary {
  .mat-tab-header,
  .mat-tab-links {
    background-color: $green2;
  }
}

.mat-tab-group.mat-background-primary {
  .mat-tab-label,
  .mat-tab-link {
    color: #fff;
  }
}

.mat-tab-nav-bar.mat-background-primary {
  .mat-tab-label,
  .mat-tab-link {
    color: #fff;
  }
}

.mat-tab-group.mat-background-primary {
  .mat-tab-label.mat-tab-disabled,
  .mat-tab-link.mat-tab-disabled {
    color: rgba(255, 255, 255, 0.4);
  }
}

.mat-tab-nav-bar.mat-background-primary {
  .mat-tab-label.mat-tab-disabled,
  .mat-tab-link.mat-tab-disabled {
    color: rgba(255, 255, 255, 0.4);
  }
}

.mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
  border-color: #fff;
}

.mat-tab-group.mat-background-primary
  .mat-tab-header-pagination-disabled
  .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary
  .mat-tab-header-pagination-disabled
  .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}

.mat-tab-group.mat-background-primary .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}

.mat-tab-group.mat-background-accent {
  .mat-tab-label {
    &.cdk-keyboard-focused:not(.mat-tab-disabled),
    &.cdk-program-focused:not(.mat-tab-disabled) {
      background-color: rgba(255, 128, 171, 0.3);
    }
  }
  .mat-tab-link {
    &.cdk-keyboard-focused:not(.mat-tab-disabled),
    &.cdk-program-focused:not(.mat-tab-disabled) {
      background-color: rgba(255, 128, 171, 0.3);
    }
  }
}

.mat-tab-nav-bar.mat-background-accent {
  .mat-tab-label {
    &.cdk-keyboard-focused:not(.mat-tab-disabled),
    &.cdk-program-focused:not(.mat-tab-disabled) {
      background-color: rgba(255, 128, 171, 0.3);
    }
  }
  .mat-tab-link {
    &.cdk-keyboard-focused:not(.mat-tab-disabled),
    &.cdk-program-focused:not(.mat-tab-disabled) {
      background-color: rgba(255, 128, 171, 0.3);
    }
  }
}

.mat-tab-group.mat-background-accent {
  .mat-tab-header,
  .mat-tab-links {
    background-color: $red;
  }
}

.mat-tab-nav-bar.mat-background-accent {
  .mat-tab-header,
  .mat-tab-links {
    background-color: $red;
  }
}

.mat-tab-group.mat-background-accent {
  .mat-tab-label,
  .mat-tab-link {
    color: #fff;
  }
}

.mat-tab-nav-bar.mat-background-accent {
  .mat-tab-label,
  .mat-tab-link {
    color: #fff;
  }
}

.mat-tab-group.mat-background-accent {
  .mat-tab-label.mat-tab-disabled,
  .mat-tab-link.mat-tab-disabled {
    color: rgba(255, 255, 255, 0.4);
  }
}

.mat-tab-nav-bar.mat-background-accent {
  .mat-tab-label.mat-tab-disabled,
  .mat-tab-link.mat-tab-disabled {
    color: rgba(255, 255, 255, 0.4);
  }
}

.mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
  border-color: #fff;
}

.mat-tab-group.mat-background-accent
  .mat-tab-header-pagination-disabled
  .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent
  .mat-tab-header-pagination-disabled
  .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}

.mat-tab-group.mat-background-accent .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}

.mat-tab-group.mat-background-warn {
  .mat-tab-label {
    &.cdk-keyboard-focused:not(.mat-tab-disabled),
    &.cdk-program-focused:not(.mat-tab-disabled) {
      background-color: rgba(255, 205, 210, 0.3);
    }
  }
  .mat-tab-link {
    &.cdk-keyboard-focused:not(.mat-tab-disabled),
    &.cdk-program-focused:not(.mat-tab-disabled) {
      background-color: rgba(255, 205, 210, 0.3);
    }
  }
}

.mat-tab-nav-bar.mat-background-warn {
  .mat-tab-label {
    &.cdk-keyboard-focused:not(.mat-tab-disabled),
    &.cdk-program-focused:not(.mat-tab-disabled) {
      background-color: rgba(255, 205, 210, 0.3);
    }
  }
  .mat-tab-link {
    &.cdk-keyboard-focused:not(.mat-tab-disabled),
    &.cdk-program-focused:not(.mat-tab-disabled) {
      background-color: rgba(255, 205, 210, 0.3);
    }
  }
}

.mat-tab-group.mat-background-warn {
  .mat-tab-header,
  .mat-tab-links {
    background-color: $red;
  }
}

.mat-tab-nav-bar.mat-background-warn {
  .mat-tab-header,
  .mat-tab-links {
    background-color: $red;
  }
}

.mat-tab-group.mat-background-warn {
  .mat-tab-label,
  .mat-tab-link {
    color: #fff;
  }
}

.mat-tab-nav-bar.mat-background-warn {
  .mat-tab-label,
  .mat-tab-link {
    color: #fff;
  }
}

.mat-tab-group.mat-background-warn {
  .mat-tab-label.mat-tab-disabled,
  .mat-tab-link.mat-tab-disabled {
    color: rgba(255, 255, 255, 0.4);
  }
}

.mat-tab-nav-bar.mat-background-warn {
  .mat-tab-label.mat-tab-disabled,
  .mat-tab-link.mat-tab-disabled {
    color: rgba(255, 255, 255, 0.4);
  }
}

.mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
  border-color: #fff;
}

.mat-tab-group.mat-background-warn
  .mat-tab-header-pagination-disabled
  .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn
  .mat-tab-header-pagination-disabled
  .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}

.mat-tab-group.mat-background-warn .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}

.mat-toolbar {
  background: #f5f5f5;
  color: rgba(0, 0, 0, 0.87);
  &.mat-primary {
    background: $green2;
    color: #fff;
  }
  &.mat-accent {
    background: $red;
    color: #fff;
  }
  &.mat-warn {
    background: $red;
    color: #fff;
  }
  .mat-focused .mat-form-field-ripple,
  .mat-form-field-ripple,
  .mat-form-field-underline {
    background-color: currentColor;
  }
  .mat-focused .mat-form-field-label,
  .mat-form-field-label,
  .mat-form-field.mat-focused .mat-select-arrow,
  .mat-select-arrow,
  .mat-select-value {
    color: inherit;
  }
  .mat-input-element {
    caret-color: currentColor;
  }
}

.mat-tooltip {
  background: rgba(0, 0, 0, 0.9);
}

.mat-tree {
  background: #fff;
}

.mat-nested-tree-node,
.mat-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: $red;
}

// Custom checkbox
.mat-checkbox {
  .mat-checkbox-inner-container {
    height: 18px !important;
    width: 18px !important;
    .mat-checkbox-frame {
      border-color: $green2;
      border-width: 2px !important;
      border-radius: 0 !important;
    }
  }
  &:hover {
    .mat-checkbox-inner-container {
      .mat-checkbox-frame {
        border-color: $green2;
      }
    }
  }
}

.mat-radio-button {
  .mat-radio-container {
    .mat-radio-outer-circle {
      border-width: 2px !important;
      @extend .text-grey-light;
    }
  }
  &:hover {
    .mat-radio-container {
      .mat-radio-outer-circle {
        border-color: $green2;
      }
    }
  }
}

.form_row .input_fld.ng-pristine.ng-touched.ng-invalid {
  border-color: $red;
}

.form_row .input_fld.ng-dirty.ng-invalid {
  border-color: $red;
}

.form_row .input_fld.ng-dirty.ng-valid.ng-touched {
  border: solid 1px #999999;
}

.input_fld.ng-touched {
  border: solid 1px #999999;
}

.error {
  color: $red;
  font-size: 1rem;
  float: left;
  width: 100%;
  margin-top: 10px;
}

.profile-details .mat-tab-group {
  $font-family: $base-font;
  .mat-tab-header {
    margin-bottom: 2rem;
    border-bottom: 1px solid #fff;
    padding: 0;
    .mat-tab-label {
      padding: 15px 0px !important;
      min-width: inherit;
      margin-right: 1.5rem;
      opacity: 1;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      &.mat-tab-label-active {
        color: $d_black;
      }
    }

    .mat-ink-bar {
      height: 2px;
    }
  }
}

.profile-details .mat-tab-label {
  color: #707070;
}

.profile-details .mat-tab-group.mat-primary .mat-ink-bar {
  background-color: #3ecb3e;
}
