// Import library functions for theme creation.
@use '@angular/material' as mat;
@import "@angular/material/theming";
html,
body {
  height: 100%;
}

html {
  font-size: 14px;
  @include breakpoint(xxxxlarge) {
    font-size: 13px;
  }
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
}

// heading style
.display-3 {
  @include fluid-font(300px, 1920px, 24px, 30px);
}

.heading {
  @include fluid-font(300px, 1920px, 22px, 26px);
}

h1,
.mat-typography h1.heading1 {
  @include fluid-font(300px, 1920px, 20px, 24px);
}

h1.heading1 {
  @extend .fw-700;
}

h2 {
  @include fluid-font(300px, 1920px, 14px, 18px);
}

h3 {
  @include fluid-font(300px, 1920px, 14px, 16px);
}

h4 {
  font-size: 1.429rem;
}

h5 {
  font-size: 1.286rem;
}

h6 {
  font-size: 1.143rem;
}

.f-22 {
  @include fluid-font(300px, 1920px, 18px, 22px);
}

.f-18 {
  @include fluid-font(300px, 1920px, 16px, 18px);
}

.f-16 {
  @include fluid-font(300px, 1920px, 14px, 16px);
}

.f-15 {
  @include fluid-font(300px, 1920px, 14px, 15px);
}

.f-14 {
  @include fluid-font(300px, 1920px, 13px, 14px);
}

.f-12 {
  @include fluid-font(300px, 1920px, 11px, 12px);
}

.black {
  color: $black;
}

.d-black {
  color: $d_black;
}

.text-grey-dark {
  color: $grey-text-333;
}

.text-grey-medium {
  color: $light-grey;
}

.text-grey-light {
  color: $grey-text;
}

// margin
.mb0 {
  margin-bottom: 0 !important;
}

.mt0 {
  margin-top: 0 !important;
}

.ml0 {
  margin-left: 0 !important;
}

.mr0 {
  margin-right: 0 !important;
}

// text align
.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

p {
  margin: 0;
  font-size: 1rem;
}

//Padding
.p-0 {
  @include padding(0, 0, 0, 0);
}

.p-1 {
  @include padding(0.25rem, 0.25rem, 0.25rem, 0.25rem);
}

.p-2 {
  @include padding(0.5rem, 0.5rem, 0.5rem, 0.5rem);
}

.p-3 {
  @include padding(1rem, 1rem, 1rem, 1rem);
}

.p-4 {
  @include padding(2rem, 2rem, 2rem, 2rem);
}

.p-5 {
  @include padding(4rem, 4rem, 4rem, 4rem);
}

.p-6 {
  @include padding(8rem, 8rem, 8rem, 8rem);
}

.pt-0 {
  @include padding(0, null, null, null);
}

.pt-1 {
  @include padding(0.25rem, null, null, null);
}

.pt-2 {
  @include padding(0.5rem, null, null, null);
}

.pt-3 {
  @include padding(1rem, null, null, null);
}

.pt-4 {
  @include padding(2rem, null, null, null);
}

.pt-5 {
  @include padding(4rem, null, null, null);
}

.pt-6 {
  @include padding(8rem, null, null, null);
}

.pb-0 {
  @include padding(null, null, 0, null);
}

.pb-1 {
  @include padding(null, null, 0.25rem, null);
}

.pb-2 {
  @include padding(null, null, 0.5rem, null);
}

.pb-3 {
  @include padding(null, null, 1rem, null);
}

.pb-4 {
  @include padding(null, null, 2rem, null);
}

.pb-5 {
  @include padding(null, null, 4rem, null);
}

.pb-6 {
  @include padding(null, null, 8rem, null);
}

.py-0 {
  @include padding(0, null, 0, null);
}

.py-1 {
  @include padding(0.25rem, null, 0.25rem, null);
}

.py-2 {
  @include padding(0.5rem, null, 0.5rem, null);
}

.py-3 {
  @include padding(1rem, null, 1rem, null);
}

.py-4 {
  @include padding(2rem, null, 2rem, null);
}

.py-5 {
  @include padding(4rem, null, 4rem, null);
}

.py-6 {
  @include padding(8rem, null, 8rem, null);
}

.pl-0 {
  @include padding(null, null, null, 0);
}

.pl-1 {
  @include padding(null, null, null, 0.25rem);
}

.pl-2 {
  @include padding(null, null, null, 0.5rem);
}

.pl-3 {
  @include padding(null, null, null, 1rem);
}

.pl-4 {
  @include padding(null, null, null, 2rem);
}

.pl-5 {
  @include padding(null, null, null, 4rem);
}

.pl-6 {
  @include padding(null, null, null, 8rem);
}

.pr-0 {
  @include padding(null, 0, null, null);
}

.pr-1 {
  @include padding(null, 0.25rem, null, null);
}

.pr-2 {
  @include padding(null, 0.5rem, null, null);
}

.pr-3 {
  @include padding(null, 1rem, null, null);
}

.pr-4 {
  @include padding(null, 2rem, null, null);
}

.pr-5 {
  @include padding(null, 4rem, null, null);
}

.pr-6 {
  @include padding(null, 8rem, null, null);
}

.px-0 {
  @include padding(null, 0, null, 0);
}

.px-1 {
  @include padding(null, 0.25rem, null, 0.25rem);
}

.px-2 {
  @include padding(null, 0.5rem, null, 0.5rem);
}

.px-3 {
  @include padding(null, 1rem, null, 1rem);
}

.px-4 {
  @include padding(null, 2rem, null, 2rem);
}

.px-5 {
  @include padding(null, 4rem, null, 4rem);
}

.px-6 {
  @include padding(null, 8rem, null, 8rem);
}

//Margin
.m-0 {
  @include margin(0, 0, 0, 0);
}

.m-1 {
  @include margin(0.25rem, 0.25rem, 0.25rem, 0.25rem);
}

.m-2 {
  @include margin(0.5rem, 0.5rem, 0.5rem, 0.5rem);
}

.m-3 {
  @include margin(1rem, 1rem, 1rem, 1rem);
}

.m-4 {
  @include margin(2rem, 2rem, 2rem, 2rem);
}

.m-5 {
  @include margin(4rem, 4rem, 4rem, 4rem);
}

.m-6 {
  @include margin(8rem, 8rem, 8rem, 8rem);
}

.mt-0 {
  @include margin(0, null, null, null);
}

.mt-1 {
  @include margin(0.25rem, null, null, null);
}

.mt-2 {
  @include margin(0.5rem, null, null, null);
}

.mt-3 {
  @include margin(1rem, null, null, null);
}

.mt-4 {
  @include margin(2rem, null, null, null);
}

.mt-5 {
  @include margin(4rem, null, null, null);
}

.mt-6 {
  @include margin(8rem, null, null, null);
}

.mb-0 {
  @include margin(null, null, 0, null);
}

.mb-1 {
  @include margin(null, null, 0.25rem, null);
}

.mb-2 {
  @include margin(null, null, 0.5rem, null);
}

.mb-3 {
  @include margin(null, null, 1rem, null);
}

.mb-4 {
  @include margin(null, null, 2rem, null);
}

.mb-5 {
  @include margin(null, null, 4rem, null);
}

.mb-6 {
  @include margin(null, null, 8rem, null);
}

.my-0 {
  @include margin(0, null, 0, null);
}

.my-1 {
  @include margin(0.25rem, null, 0.25rem, null);
}

.my-2 {
  @include margin(0.5rem, null, 0.5rem, null);
}

.my-3 {
  @include margin(1rem, null, 1rem, null);
}

.my-4 {
  @include margin(2rem, null, 2rem, null);
}

.my-5 {
  @include margin(4rem, null, 4rem, null);
}

.my-6 {
  @include margin(8rem, null, 8rem, null);
}

.ml-0 {
  @include margin(null, null, null, 0);
}

.ml-1 {
  @include margin(null, null, null, 0.25rem);
}

.ml-2 {
  @include margin(null, null, null, 0.5rem);
}

.ml-3 {
  @include margin(null, null, null, 1rem);
}

.ml-4 {
  @include margin(null, null, null, 2rem);
}

.ml-5 {
  @include margin(null, null, null, 4rem);
}

.ml-6 {
  @include margin(null, null, null, 8rem);
}

.mr-0 {
  @include margin(null, 0, null, null);
}

.mr-1 {
  @include margin(null, 0.25rem, null, null);
}

.mr-2 {
  @include margin(null, 0.5rem, null, null);
}

.mr-3 {
  @include margin(null, 1rem, null, null);
}

.mr-4 {
  @include margin(null, 2rem, null, null);
}

.mr-5 {
  @include margin(null, 4rem, null, null);
}

.mr-6 {
  @include margin(null, 8rem, null, null);
}

.mx-0 {
  @include margin(null, 0, null, 0);
}

.mx-1 {
  @include margin(0.25rem, null, 0.25rem, null);
}

.mx-2 {
  @include margin(0.5rem, null, 0.5rem, null);
}

.mx-3 {
  @include margin(1rem, null, 1rem, null);
}

.mx-4 {
  @include margin(2rem, null, 2rem, null);
}

.mx-5 {
  @include margin(4rem, null, 4rem, null);
}

.mx-6 {
  @include margin(8rem, null, 8rem, null);
}

@media (min-width: 1300px) {
  .container {
    max-width: 1240px;
    &.md-container {
      max-width: 1560px;
    }
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1340px;
  }
}

body {
  .container {
    @include breakpoint(medium) {
      max-width: 100%;
    }
  }
}

a:hover {
  text-decoration: none;
}

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

.mat-checkbox-frame {
  border: none !important;
  background-color: $green !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: $green !important;
}

.mat-checkbox-checkmark-path {
  stroke: #ffffff !important;
}

.mat-menu-panel {
  background: #fff !important;
  @include box-shadow(0px 7px 27px 0 rgba(0, 0, 0, 0.38));
  &.action-dropdown {
    min-height: 3.428rem;
  }
}

.mat-menu-panel.profile-dropdown {
  border-top: 3px solid $white;
  &:before {
    border-bottom-color: $white !important;
  }
}

.mat-menu-panel.setting-dropdown {
  min-width: 260px;
}

.mat-menu-panel.profile-dropdown,
.setting-dropdown {
  background: #fff !important;
  // max-width: 263px !important;
  // min-width: 263px !important;
  width: 100% !important;
  @include box-shadow(0px 2px 49.5px 0.5px rgba(0, 0, 0, 0.28));
  // margin-top:30px;
  &:before {
    content: "";
    position: absolute;
    top: -10px;
    right: 20px;
    border-bottom: solid 10px #fff;
    border-left: solid 10px transparent;
    border-right: solid 10px transparent;
  }
}

.top-menu-header {
  .mat-button {
    min-width: 26px;
  }
  .min-height-button-header {
    min-height: 5.7rem;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    .mat-button-wrapper {
      position: relative;
    }
  }
}

.mat-menu-content:not(:empty) {
  // padding: 0 !important;
  float: left;
  width: 100%;
}

div.notification-menu {
  max-width: 433px;
  .mat-menu-content {
    width: 433px;
    min-height: 140px;
    max-height: 430px;
    overflow-y: scroll;
  }
  .drop_list_items {
    padding: 0 15px;
    .mat-list-item {
      width: 100%;
      display: inline-block;
      box-shadow: 0px 1px 2px 0 rgba(0, 0, 0, 0.15);
      -moz-box-shadow: 0px 1px 2px 0 rgba(0, 0, 0, 0.15);
      border: 1px solid rgba(0, 0, 0, 0.04);
      &.unread {
        @extend .position-relative;
        &:after {
          @extend .position-absolute;
          height: 7px;
          width: 7px;
          content: "";
          border-radius: 7px;
          background-color: $red;
          left: 7px;
          top: 8px;
        }
      }
    }
    .notification-wrapper {
        .imager-wrapper img{
            width: 50px;
            height: 50px;
            border-radius: 50px;
            @include flexbox;
            @include justify-content(center);
            @include align-items(center);
            background-color: #c9c9d4;
            overflow: hidden;
            padding: 1px;
            object-fit: cover;
        }
        .notification-content {
            color: $d_black;
            @extend .fw-500;
            font-size: 13px;
            span {
                @extend .text-grey-light;
            }
            .notification-link {
                font-size: 14px;
                text-decoration: underline;
            }
            .notification-time {
                color: #a4a4bd;
                font-size: 12px;
                @extend .fw-400;
            }
            .notificationRouting{
                margin-left: 100px;
                margin-top: 20px;
                border: 1px solid silver;
                text-decoration: underline;
                color: #007bff;
                font-weight: bold;
                font-size: 11px;
                padding: 4px;
                border-radius: 3px;
              }
        }
    }
    .notification-content {
      color: $d_black;
      @extend .fw-500;
      font-size: 13px;
      span {
        @extend .text-grey-light;
      }
      .notification-link {
        font-size: 14px;
        text-decoration: underline;
      }
      .notification-time {
        color: #a4a4bd;
        font-size: 12px;
        @extend .fw-400;
      }
      .notificationRouting {
        margin-left: 100px;
        margin-top: 20px;
        border: 1px solid silver;
        text-decoration: underline;
        color: silver;
        font-size: 11px;
        padding: 4px;
        border-radius: 3px;
      }
    }
  }
}

.user_profile_details {
  text-align: center;
  border-bottom: solid 1px #eee;
  padding-bottom: 2.142rem !important;
  margin-bottom: 1.428rem !important;
  .user_pic img {
    width: 6.785rem;
    height: 6.785rem;
    @include border-radius(100px);
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin: 0px auto;
    float: none;
    object-fit: cover;
  }
  .user_info {
    h2 {
      // font-size:1.071rem;
      @include fluid-font(300px, 1920px, 14px, 16px);
      font-weight: 500;
      margin: 10px 0px 2px 0px;
      color: $d_black;
    }
    p {
      font-size: 13px;
      color: #676b7a;
      font-weight: 400;
    }
  }
}

.drop_list_items {
  float: left;
  width: 100%;
  margin: 0px !important;
  li {
    margin-bottom: 0;
    margin-right: 0px;
    width: 100%;
    display: inline-block;
    &:last-child {
      margin-bottom: 0px;
    }
    a {
      padding: 10px 0px;
      display: inline-block;
      width: 100%;
      color: #1d1d21;
      font-size: 1rem;
      text-decoration: none;
      @include transition(all ease 0.4s);
      &:hover,
      &:focus,
      &:active {
        @include transition(all ease 0.4s);
        color: $green;
        i {
          color: $green2;
        }
      }
      i {
        color: $d_black;
        display: inline-block;
        position: relative;
        top: 7px;
        margin-right: 11px;
      }
    }
  }
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  @extend .text-grey-dark;
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #181818 !important;
}

.mat-select-panel {
  background: #fff !important;
}

.mat-option.mat-active {
  color: $green !important;
}

.mat-form-field-underline {
  display: none !important;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #111010 !important;
}

.mat-form-field-infix {
  width: auto !important;
}

.mat-select-value-text {
  white-space: initial !important;
  overflow: visible !important;
  text-overflow: initial !important;
}

.mat-form-field-subscript-wrapper {
  position: relative !important;
  overflow: visible !important;
}

.label-effect-input {
  .mat-form-field-label-wrapper {
    position: absolute !important;
    .mat-form-field-label {
      color: $black !important;
    }
  }
}

.mat-form-field-hide-placeholder .mat-select-placeholder {
  color: #111 !important;
  -webkit-text-fill-color: initial !important;
}

.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0px !important;
}

.mat-select-arrow {
  border-top: 5px solid $grey-text-333 !important;
}

.mat-select-placeholder {
  position: relative;
  width: 100%;
  text-align: right;
  float: right;
  &::before {
    content: "Select";
    color: #111;
    content: "Select";
    width: 100%;
    height: 100%;
    display: inline-block;
    left: 0;
    position: absolute;
  }
}

.mat-option {
  color: #3f3f3f;
}

.border_btn {
  &:hover,
  &:focus,
  &:active {
    @include transition(all ease 0.5s);
    .right_arrow {
      margin: 0px 0px 0px 8px !important;
      @include transition(all ease 0.5s);
    }
  }
}

.right_arrow {
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 18px;
  height: 12px;
  display: inline-block;
  // margin-bottom: -1px !important;
  @include transition(all ease 0.5s);
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

// hamburger
#menuToggle {
  display: block;
  position: absolute;
  left: 15px;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  top: 0px;
  bottom: 0px;
  margin: auto;
  padding: 0;
  height: 26px;
  &.open {
    top: -5px;
  }
  &.open span {
    opacity: 1;
    transform: rotate(45deg) translate(-8px, -9px);
  }
  &.open span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }
  &.open span:nth-last-child(2) {
    transform: rotate(-45deg) translate(-6px, 9px);
  }
  span {
    display: block;
    width: 26px;
    height: 3px;
    margin: 5px 0;
    position: relative;
    background: #6f6f6f;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
    @include breakpoint(small) {
    }
    &:first-child {
      transform-origin: 0% 0%;
    }
    &:nth-last-child(2) {
      transform-origin: 0% 100%;
    }
  }
}

.grid1 {
  .card {
    flex-basis: calc(100% / 1);
    padding: 0px !important;
    @include transition(all ease 0.5s);
    &.post {
      padding: 0px 12px !important;
      margin-bottom: 20px !important;
    }
  }
}

.grid2 {
  .card {
    flex-basis: calc(100% / 2);
    padding: 0px 8px !important;
    @include transition(all ease 0.5s);
    &.post {
      padding: 0px 12px !important;
      margin-bottom: 20px !important;
    }
  }
}

.grid3 {
  .card {
    flex-basis: calc(100% / 3);
    padding: 0px 8px !important;
    @include transition(all ease 0.5s);
    &.post {
      padding: 0px 12px !important;
      margin-bottom: 20px !important;
    }
  }
}

.grid4 {
  .card {
    flex-basis: calc(100% / 4);
    padding: 0px 15px !important;
    @include transition(all ease 0.5s);
    &.post {
      padding: 0px 12px !important;
      margin-bottom: 20px !important;
    }
  }
}

.card {
  .white_box {
    padding: 1.428rem;
    display: block;
    width: 100%;
    @include transition(all ease 0.5s);
    @include border-radius(5px);
  }
}

.container {
  margin: 0px auto !important;
  width: 100%;
  padding: 0px 1.071rem;
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 0px !important;
}

.mat-form-field .mat-form-field-wrapper {
  padding-bottom: 0px !important;
}

.mat-select-value {
  @extend .text-grey-dark;
  text-align: right;
  overflow: visible !important;
  text-overflow: initial !important;
  white-space: normal !important;
  max-width: initial !important;
  // min-width: 109px;
  min-width: 100%;
}

.padding_btm100 {
  padding-bottom: 100px !important;
}

.mat-dialog-container {
  background-color: #fff !important;
  color: $d_black !important;
  padding: 0 !important;
}

.loader-view {
  .form_row {
    margin-bottom: 0;
  }
  .white_box {
    min-height: 100%;
    text-align: center;
  }
}

.rotate-right {
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.carousel-slide-overlay {
  display: none;
}

.carousel button {
  top: inherit !important;
  bottom: -7px;
}

.box-container-outer {
  padding: 0 !important;
  background: transparent !important;
  .card {
    .white_box {
      padding: 2.142rem;
    }
  }
}

.gray_bg {
  background: $gray_bg;
}

.text-white {
  color: $white;
}

.loading-container {
  @include flexbox;
  flex-wrap: wrap;
}

.loading {
  position: relative;
  padding: 15px;
  border: 1px #ccc solid;
  border-radius: 4px;
}

.custom-class {
  top: 0;
  z-index: 2002;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  @include flexbox;
  @include justify-content(center);
  @include align-items(center);
  flex-direction: column;
  background-color: #000000;
  border-radius: 3px;
}

.custom-class label {
  color: white;
  font-size: 1rem;
}

.mat-form-field-type-mat-select .mat-form-field-label-wrapper {
  position: relative !important;
  left: 0 !important;
  overflow: visible !important;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: #3fb53f !important;
}

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin candy-carousel-theme($theme) {
  // Extract the palettes you need from the theme definition.
  $base2: map-get($theme, primary);
  $green: map-get($theme, accent);
  // Define any styles affected by the theme.
  .candy-carousel {
    // Use mat-color to extract individual colors from a palette.
    background-color: mat.get-color-from-palette($base2);
    border-color: mat.get-color-from-palette($green, A400);
  }
}

// login
.login {
  .heading {
    color: $white;
    font-weight: 500;
  }
}

.notification {
  position: relative;
  .badge {
    position: absolute;
    border-radius: 50%;
    background: $red;
    color: white;
    display: inline-block;
    height: 9px;
    width: 9px;
    right: 2px;
    top: -2px;
  }
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

// login
.login-content-wrapper {
  max-width: 41.42rem;
  margin: 0 auto 3rem;
  @include breakpoint(small) {
    max-width: calc(100% - 30px);
  }
  &.login-content-wrapper-pricing {
    max-width: 50rem;
  }
  &.registration-area {
    max-width: 101.42rem;
    padding: 0 1rem;
    .register-btn-area {
      @include flexbox;
      @include align-items(center);
      @include justify-content(space-between);
      width: 100%;
      margin: 1rem 0 0;
      // flex-wrap: wrap;
      p {
        margin: 0;
        a {
          color: $green-3;
        }
      }
    }
    .login-content {
      padding: 2rem !important;
      form {
        max-width: 100%;
        width: 100%;
        padding: 0;
      }
    }
    .file-input-field .choose-input .choose-btn {
      margin: 4px 0 0;
      height: 4rem;
      line-height: normal;
    }
    .input_block.select-border-box-outer {
      .mat-select {
        height: 4rem;
        margin: 5px 0 0;
        &.mat-select-empty {
          .mat-select-placeholder {
            opacity: 0;
          }
        }
      }
      .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float
        .mat-form-field-label {
        transform: translateY(-1.68125em) scale(0.75) perspective(100px)
          translateZ(0.001px);
        mat-label {
          padding: 0 5px;
          background: $white;
        }
      }
      .mat-form-field-label-wrapper {
        position: absolute !important;
        box-sizing: border-box;
        top: 0px;
        left: 0.75rem !important;
      }
    }
    .input-heading {
      display: inline-flex;
      font-weight: 500;
      margin: 0.5rem 0 1rem 0;
      width: 100%;
      font-size: 1.142rem;
      .material-icons {
        color: $green-3;
        margin: 0 8px 0 0;
        position: relative;
        top: 0rem;
        @include breakpoint(xxxlarge) {
          top: 0.3rem;
        }
      }
    }
  }
  .login-content {
    background-color: $white;
    border-radius: 10px;
    min-height: 250px;
    @extend .px-3;
    @extend .py-5;
    form,
    .form {
      @extend .mx-auto;
      max-width: 32.857rem;
      width: 100%;
    }
  }
}

.container-inner {
  max-width: 101.42rem;
  width: 100%;
  margin: 0 auto;
  @include breakpoint(small) {
    &.mb-6 {
      margin-bottom: 3rem !important;
    }
  }
}

.login-head {
  @include breakpoint(small) {
    flex-wrap: wrap;
    justify-content: center !important;
  }
  div {
    color: $light-text;
    @include fluid-font(300px, 1920px, 14px, 17px);
    @include breakpoint(small) {
      width: 100%;
      text-align: center;
    }
    a {
      color: $green;
      text-decoration: underline;
    }
  }
}

.forgot-password {
  color: $para;
  span {
    font-weight: 400;
    color: $para;
  }
  span + span {
    @include fluid-font(300px, 1920px, 13px, 15px);
    color: $para;
  }
}

.sign-up {
  @extend .text-grey-medium;
  a {
    color: $para;
  }
}

button.mat-mini-fab {
  &.white-transparent {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 0;
    @include flexbox;
    @include justify-content(center);
    @include align-items(center);
    width: 2.857rem;
    height: 2.857rem;
  }
}

.corner-ribbon {
  width: 200px;
  background: #e43;
  position: absolute;
  text-align: center;
  line-height: 26px;
  letter-spacing: 1px;
  color: #f0f0f0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  position: absolute;
  font-size: 10px;
  text-transform: uppercase;
  &.shadow {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  }
  &.top-right {
    top: 12px;
    right: -70px;
    left: auto;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  &.corner-ribbon.orange {
    background: $orange;
  }
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

// modal custom style
div.mat-dialog-content {
  margin: 0;
  @extend .pt-4;
  @extend .pb-4;
  @extend .px-4;
}

.full-screen-modal {
  position: fixed;
  // position: absolute;
  left: 0px;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
  overflow: auto;
  background: #fafafa;
  .main_inner {
    min-height: 100vh;
    overflow: auto;
    .white_box {
      float: none;
      display: inline-block;
      clear: both;
      width: 100%;
      position: relative;
      h1 {
        @include breakpoint(small) {
          font-size: 18px;
          font-weight: 500;
        }
      }
    }
  }
}

div.mat-dialog-content {
  &.content-modal {
    @extend .py-2;
  }
}

div.mat-dialog-actions {
  @extend .pb-4;
  @extend .px-4;
}

.type-outer {
  label {
    .label-content {
      max-width: 210px;
      min-width: 210px;
      min-height: 207px;
      border-radius: 3px;
      width: 100%;
    }
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked {
      ~ .label-content {
        border: 2px solid $green;
        .radio-select {
          display: inline-block;
        }
      }
    }
  }
  input ~ .label-content .radio-select {
    display: none;
    right: 11px;
    top: 11px;
  }
}

button.btn-sm-width {
  max-width: 15rem;
  width: 100%;
}

// dashboard
.container-height {
  height: calc(100% - 5.7rem);
  @include breakpoint(xxxlarge) {
    height: calc(100% - 4rem);
  }
}

.empty-wrapper {
  @extend .d-flex;
  @extend .justify-content-center;
  @extend .align-items-center;
  height: 100%;
}

.no-data-come {
  p {
    @extend .text-grey-medium;
  }
}

.icon-circle {
  width: 2.5rem;
  min-width: 2.5rem;
  height: 2.5rem;
  border-radius: 2.5rem;
  color: $white;
  @extend .d-flex;
  @extend .align-items-center;
  @extend .position-relative;
  @extend .justify-content-center;
  margin: 0px 10px 0px 0px;
  font-size: 1rem;
  text-transform: uppercase;
  i.material-icons {
    font-size: 1.5rem;
  }
}

.bg-ic-yellow {
  background-color: #fcf0c9;
  i {
    color: #806208;
  }
}

.bg-ic-blue {
  background-color: #e7f1fb;
  i {
    color: #1c5fa1;
  }
}

.bg-ic-lightgray {
  background-color: lightgray;
  i {
    color: #1c5fa1;
  }
}

.bg-ic-lightgray {
  background-color: lightgray;
}

.bg-ic-red {
  background-color: #ffc5c5;
  i {
    color: #c16868;
  }
}

.bg-green {
  background-color: $green2;
}

.bg-purple {
  background-color: $purple;
}

.bg-light {
  background-color: $row-type-bg;
}

.bg-blue {
  background-color: $sky-base-1;
}

.bg-grey-aaa {
  background-color: $gray-400;
}
.inline-block{
  display: inline-block !important;
}

.mat-radio-group {
  &.radio-group-3 {
    .mat-radio-button {
      // min-width: 111px;
      &:nth-child(2) {
        position: relative;
        &::after {
          position: absolute;
          right: 2px;
          background: #cccccc;
          width: 2px;
          height: 15px;
          content: "";
          top: 2px;
        }
      }
    }
  }
  .mat-radio-button {
    &:nth-child(1) {
      position: relative;
    }
  }
}

.mat-typography {
  .select-misc-option {
    h2 {
      font-size: 1rem !important;
    }
  }
}

.select-misc-option {
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked {
      ~ .misc-button {
        border: 1px solid $green2;
        background-color: $green2;
        color: $white;
        .icon-end {
          &.icon-check {
            display: none;
          }
          &.icon-uncheck {
            display: inline-block;
          }
        }
      }
    }
  }
  .misc-button {
    border-radius: 3px;
    border: 1px dashed #b7b7b7;
    @extend .text-grey-light;
    padding-left: 2.857rem;
    padding-right: 2rem;
    height: 2.714rem;
    h2 {
      @extend .fw-400;
    }
    .icon-start {
      @extend .position-absolute;
      left: 12px;
      top: 0.642rem;
      font-size: 1.5rem;
    }
    .icon-end {
      @extend .position-absolute;
      right: 0.6rem;
      top: 0.785rem;
      font-size: 1.285rem;
      &.icon-uncheck {
        display: none;
      }
    }
  }
}

.mat-list-base {
  &.select-misc-wrapper {
    margin-bottom: 1rem !important;
    .mat-list-item {
      display: inline-block;
      cursor: pointer;
      margin-bottom: 0 !important;
      .misc-button {
        cursor: pointer;
      }
      @extend .w-auto;
      .mat-list-item-content {
        @extend .px-0;
      }
    }
    &.select-misc-wrapper-md {
      .mat-list-item {
        height: 40px;
      }
      .misc-button {
        padding-right: 15px;
      }
    }
  }
}

.input_block_width {
  .input_block {
    min-width: 90px;
    max-width: 90px;
    width: 100%;
  }
}

.chip-style {
  padding: 0px 15px;
  .mat-form-field-label-wrapper {
    display: none;
  }
  .mat-chip-list-wrapper {
    margin-top: -2px;
  }
}

.form_row {
  .datepicker-wrap {
    .input_fld {
      padding-right: 28px;
    }
    .mat-form-field-suffix {
      position: absolute;
      right: 9px;
      top: 4px;
      .mat-datepicker-toggle-default-icon {
        width: 2rem;
        fill: #333;
      }
    }
    .date-icon {
      position: absolute;
      right: 9px;
      top: 11px;
      pointer-events: none;
    }
  }
  .search-wrap {
    .input_fld {
      padding-right: 28px;
    }
    .mat-form-field-suffix {
      position: absolute;
      right: 9px;
      top: 4px;
    }
    .search-icon {
      position: absolute;
      right: 9px;
      top: 8px;
      pointer-events: none;
    }
  }
}

.hint {
  color: $light-text;
  font-size: 11px;
}

// scrollbar
//  ::-webkit-scrollbar-track {
//     background-color: rgba(15, 15, 17, 0);
//     border-radius: 3px;
// }
//  ::-webkit-scrollbar {
//     width: 6px;
//     height: 6px;
//     background-color: rgba(15, 15, 17, 0);
//     border-radius: 3px;
// }
//  ::-webkit-scrollbar-thumb {
//     background-color: rgba(15, 15, 17, 0.1);
//     border-radius: 3px;
// }
.open-label {
  background-color: #fff8e2;
  height: 32px;
  display: inline-flex;
  @extend .align-items-center;
  @extend .justify-content-center;
  border-radius: 3px;
  color: #806208;
  &.green-label {
    background-color: $green2;
    color: #fff;
  }
  &.blue-label {
    background-color: $dark-blue;
    color: #fff;
  }
}

.active-label {
  background-color: #e7f2fb;
  height: 32px;
  display: inline-flex;
  @extend .align-items-center;
  @extend .justify-content-center;
  border-radius: 3px;
  color: $dark-base;
  &.md-active-label {
    height: 24px;
    font-size: 12px;
    padding: 0 10px;
  }
}

.assigned-label {
  background: #e2fff0;
  height: 32px;
  display: inline-flex;
  @extend .align-items-center;
  @extend .justify-content-center;
  border-radius: 3px;
  color: #59876f;
  &.md-active-label {
    height: 24px;
    font-size: 12px;
    padding: 0 10px;
  }
}

.unknown-label {
  background-color: #f4f4f4;
  height: 32px;
  display: inline-flex;
  @extend .align-items-center;
  @extend .justify-content-center;
  border-radius: 3px;
  color: #898989;
}

.action-cell {
  .action-icon-dots {
    justify-content: center;
    @include flexbox;
    @include breakpoint(small) {
      display: inline-flex;
    }
  }
  .action-icon-trash-edit {
    display: none;
    a {
      display: inline-block;
      img {
        margin-right: 7px;
        width: 15px;
        &.img-trash {
          width: 13px;
        }
      }
    }
  }
  &:hover {
    .action-icon-trash-edit {
      display: inline-flex;
      width: 100%;
    }
    .action-icon-dots {
      display: none;
    }
  }
}

.filter-section {
  position: fixed;
  top: 80px;
  background: #fff;
  right: -999px;
  z-index: 9;
  height: calc(100vh - 49px);
  max-width: 420px;
  width: 100%;
  @include box-shadow(0px 3px 47px 0 rgba(0, 0, 0, 0.24));
  transition: all 0.3s;
  @include breakpoint(xxlarge) {
    top: 48px;
  }
  .close-filter {
    cursor: pointer;
  }
  &.open {
    right: 0;
  }
  .filter-header {
    padding: 1.2rem 1.5rem;
    background-color: #19191c;
    color: $white;
    .font-20 {
      @include fluid-font(300px, 1920px, 16px, 20px);
    }
    .input_fld {
      padding-left: 40px;
    }
    .input-icon-front {
      @extend .text-grey-light;
      &:after {
        position: absolute;
        content: "";
        right: 0;
        top: 6px;
        height: 27px;
        width: 1px;
        background-color: rgba(0, 0, 0, 0.4);
      }
      .material-icons {
        font-size: 24px;
      }
    }
    input {
      @extend .fw-500;
    }
  }
  .filter-content {
    padding: 1.5rem 2rem;
    height: calc(100% - 68px);
    overflow-y: auto;
    .bg-light {
      border: 1px solid #f3f3f8;
      border-radius: 3px;
    }
  }
  .input-small {
    width: 100px;
  }
  .slider-outer {
    padding: 0 13px;
    width: 100%;
  }
}

.show-applied-filter {
  cursor: pointer;
  &:hover {
    span {
      opacity: 1;
    }
  }
  .filter-badge {
    background-color: $green2;
    color: $white;
    border-radius: 3px;
    height: 16px;
    padding: 0 4px;
    @extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-center;
    opacity: 1;
  }
  span {
    font-size: 13px;
    color: $grey-text;
    opacity: 0.7;
  }
  .material-icons {
    font-size: 14px;
  }
}

.text-description-888 {
  color: $grey-text;
}

.small-font {
  font-size: 12px;
  height: 22px;
}

.label-badge {
  background-color: #f6f8fb;
  padding-top: 5px;
  padding-bottom: 5px;
  &.status-text {
    min-width: auto;
  }
  @extend .text-grey-dark;
}

.posted-date {
  span.material-icons {
    font-size: 17px;
  }
}

input {
  @include placeholder {
    color: #111;
    opacity: 1;
  }
}

.input-phone {
  .input-phone-select {
    max-width: 79px;
    position: relative;
    top: -1px;
    margin-right: 8px;
  }
}

div {
  .btn.small_btn {
    min-height: 2.57rem;
    min-width: 95px;
  }
  .btn:focus,
  .btn.focus {
    box-shadow: none;
  }
}

.close-icon-modal {
  right: 10px;
  top: -20px;
  cursor: pointer;
  font-size: 20px;
}

.mat-dialog-title {
  .close-icon-modal {
    right: 15px;
    top: 15px;
  }
}

.button-right {
  .input_fld {
    padding-right: 75px;
  }
  .mat-flat-button {
    right: 0;
    top: -1px;
    border-radius: 0 3px 3px 0;
    min-height: 2.857rem;
  }
}

div {
  .mat-slide-toggle-label {
    flex-direction: row-reverse;
  }
  span.mat-slide-toggle-content {
    @extend .mr-3;
  }
  &.mat-slide-toggle-bar {
    width: 45px;
    height: 24px;
    border-radius: 40px;
  }
  &.mat-slide-toggle-thumb-container {
    width: 11px;
    height: 11px;
    top: 7px;
    left: 10px;
    .mat-slide-toggle-thumb {
      width: 10px;
      height: 10px;
    }
  }
}

.cancel-div {
  right: -5px;
  top: -4px;
  font-size: 17px;
  cursor: pointer;
}

mat-list.mat-list-from-to {
  .mat-list-item {
    display: inline-block;
    width: auto;
    @extend .pr-2;
    position: relative;
    &:not(:last-child) {
      &:after {
        content: "";
        height: 2px;
        width: 6px;
        background-color: $grey-text-333;
        position: absolute;
        right: 7px;
        top: 10px;
      }
    }
    .mat-list-item-content {
      display: inline-block;
      padding-left: 0;
    }
    p {
      @extend .pb-0;
    }
  }
}

.address-link {
  padding-left: 70px !important;
  position: relative;
  @extend .pt-0;
  &.mat-list-base .mat-list-item {
    display: inline-block;
    width: auto;
    height: auto;
    &:not(:last-child) {
      &:after {
        background-color: #ccc;
      }
    }
    .mat-list-item-content {
      padding-left: 0;
      @include fluid-font(300px, 1920px, 13px, 14px);
    }
    &.flex-wrap > .mat-list-item-content {
      @extend .flex-wrap;
    }
  }
  &.mat-list-base .mat-list-item {
    &.inner-list {
      @extend .position-relative;
      &:after {
        @extend .position-absolute;
        content: "place";
        font-family: "Material Icons";
        top: 0;
        left: -57px;
        @include flexbox;
        @include justify-content(center);
        @include align-items(center);
        color: $green2;
        z-index: 9;
        background: none;
        height: 40px;
        width: 40px;
        border-radius: 40px;
        background-color: #fff;
        border: 2px solid #e1e1e1;
      }
    }
    &.inner-list:not(:last-child) {
      @extend .pb-4;
      &:before {
        @extend .position-absolute;
        content: "";
        width: 2px;
        height: 97%;
        background: #ddd;
        top: 0;
        left: -37px;
      }
    }
  }
}

.shipment-items {
  padding-left: 50px;
  .basket-icon {
    left: 30px;
  }
}

.mat-list.mat-list-from-to {
  &.inner-list {
    .mat-list-item {
      height: auto;
      @extend .mb-3;
      &:after {
        background-color: #ccc;
      }
    }
  }
}

.mat-list-base.mat-list {
  &.load-information-list {
    .mat-list-item {
      position: relative;
      padding-left: 15px;
      font-size: 1.071rem;
      @extend .fw-500;
      .material-icons {
        position: absolute;
        left: -12px;
        font-size: 1.285rem;
      }
    }
  }
}

.badge-label {
  color: $white;
  padding: 2px 7px;
  border-radius: 10px;
  height: 21px;
}

.bid-section {
  // padding-left: 80px !important;
  .avatar-div {
    // @extend .position-absolute;
    height: 55px;
    width: 55px;
    border-radius: 50%;
    overflow: hidden;
    @include flexbox;
    @include justify-content(center);
    @include align-items(center);
    // left: 12px;
    img {
      width: 100%;
    }
  }
  button {
    font-size: 12px;
    .material-icons {
      font-size: 14px;
    }
  }
}

.full-screen-modal .main_inner .white_box.bid-section {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.fav-carrier {
  padding: 5px;
  border: 1px solid #f2f2f2;
  border-radius: 2px;
  color: $d_black;
  max-width: 287px;
  min-width: 180px;
  width: 100%;
  .material-icons {
    color: $d_black;
    font-size: 17px;
  }
  .carrier-img {
    background-color: #e5e5e5;
    width: 30px;
    height: 30px;
    border-radius: 3px;
    @include flexbox;
    img {
      width: 100%;
    }
  }
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #111 !important;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #111 !important;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #111 !important;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #111 !important;
}

.detail-main-heading {
  flex-wrap: wrap;
  white-space: nowrap;
  margin: 0 0 5px 0;
}

mat-slider.mat-slider-horizontal {
  height: 38px;
  .mat-slider-wrapper {
    top: 16px;
  }
  .mat-slider-ticks-container {
    display: none;
  }
  .mat-slider-wrapper::after {
    display: none;
  }
  &.cdk-focused {
    .mat-slider-thumb-label {
      transform: translateY(2px) scale(1) rotate(0deg) !important;
    }
  }
  .mat-slider-thumb-label {
    transform: translateY(2px) scale(0) rotate(0deg) !important;
    min-width: 40px !important;
    padding: 0 5px;
    border-radius: 3px !important;
    .mat-slider-thumb-label-text {
      transform: rotate(0deg);
    }
  }
}

.slider-outer {
  .slider-text {
    position: relative;
    top: -10px;
    span {
      font-size: 12px;
    }
  }
}

.rating {
  span {
    font-size: 12px;
    color: $d_black;
    &.material-icons {
      font-size: 16px;
      line-height: 16px;
    }
  }
}

.title-border {
  border-bottom: 1px solid #d8d8d8;
}

.btn.min-width-btn {
  min-width: 242px;
}

.mx-300 {
  max-width: 300px;
  width: 100%;
}

.accepted-declined {
  opacity: 0.5;
}

.text-center {
  .mat-sort-header-container {
    justify-content: center;
  }
}

.load-detail-section {
  @include flexbox;
  position: relative;
  padding: 0px;
  @include breakpoint(medium) {
    flex-direction: column;
  }
  .load-detail-section-left {
    width: 100%;
    // @include breakpoint(xxlarge) {
    //     width: calc(100% - 350px);
    // }
    @include breakpoint(medium) {
      width: 100%;
    }
    .load-detail-section-repeat {
      border-bottom: 1px solid #e0e0e0;
      padding: 2.142rem 2.857rem;
      @include breakpoint(medium) {
        padding: 20px;
      }
      &:last-child {
        border: 0;
      }
    }
    .status-label-text {
      @media only screen and (max-width: 600px) {
        float: none !important;
        margin-bottom: 12px;
      }
    }
  }
  .load-detail-section-right {
    min-width: 31.285rem;
    max-width: 31.285rem;
    background: #fafafa;
    box-shadow: -1px -0.07px 0px #00000017;
    border-radius: 0px 3px 3px 0px;
    padding: 2.142rem 2.857rem;
    @include breakpoint(xxlarge) {
      min-width: 25rem;
      max-width: 25rem;
    }
    @include breakpoint(medium) {
      width: 100%;
      padding: 20px;
      max-width: 100%;
      border-radius: 0;
    }
  }
}

.load-detail-top-status {
  @include flexbox;
  justify-content: space-between;
  flex-wrap: wrap;
  .load-detail-top-right {
    @include flexbox;
    @include align-items(center);
    @include breakpoint(small) {
      margin: 10px 0 0px;
      width: 100%;
    }
    .link-with-icon {
      margin: 0;
      position: relative;
      white-space: nowrap;
    }
  }
  .divide {
    width: 1px;
    height: 15px;
    background: #707070;
    margin: 0 20px;
    opacity: 0.3;
    @include breakpoint(small) {
      margin: 0 10px;
    }
  }
}

.mat-typography h2 {
  font-size: 1.428rem;
  line-height: 2.285rem;
}

.link-with-icon {
  border: 0;
  color: $green2;
  background: none;
  @include flexbox;
  @include align-items(center);
  font-size: 0.857rem;
  text-transform: uppercase;
  font-weight: 500;
  span {
    font-size: 1.285rem;
    margin: 0 5px 0 0;
  }
}

.mat-typography h2 {
  @include breakpoint(small) {
    font-size: 18px;
  }
}

.green-text {
  color: $green2 !important;
  vertical-align: middle;
}

.load-detail-section .load-detail-heading {
  color: $d_black;
  margin: 0 0 5px 0;
  @include breakpoint(small) {
    font-size: 20px;
  }
  span {
    color: #c9cad5;
    font-size: 30px;
    position: relative;
    top: 6px;
    right: 8px;
  }
  span.left-direction {
    transform: rotate(180deg);
    left: 8px;
    right: 0;
  }
}

.posted-time {
  font-size: 1rem;
  .material-icons {
    display: inline;
    margin: 0 5px 0 10px;
    font-size: 1.285rem;
    position: relative;
    top: 4px;
  }
  span {
    margin: 0 5px 0 0;
  }
  strong {
    font-weight: 500;
  }
}

.tag-area {
  @include flexbox;
  flex-wrap: wrap;
  margin: 10px 0 20px 0;
  @include breakpoint(small) {
    margin: 0;
  }
  .tag-list {
    height: 2.571rem;
    background: #f3f3f8;
    border-radius: 21px;
    padding: 0 1.428rem;
    white-space: nowrap;
    font-size: 1rem;
    line-height: 1.428rem;
    margin: 0 6px 6px 0;
    @include flexbox;
    @include justify-content(center);
    @include align-items(center);
    strong {
      font-weight: 500;
    }
    span {
      display: inline-block;
      margin: 0 5px 0 0;
      font-size: 1.285rem;
    }
    .badge-label {
      font-size: 1rem;
    }
    .material-icons {
      font-size: 1.285rem;
      position: relative;
      top: -1px;
      margin: 0 6px 0 0;
    }
  }
}

.load-status-area {
  @include flexbox;
  position: relative;
  background: #fbfbfb;
  padding: 20px;
  width: 100%;
  flex-wrap: wrap;
  .load-status-list {
    min-width: 20%;
    @include flexbox;
    flex-direction: column;
    max-width: 20%;
    margin: 0 0 10px 0;
    @include breakpoint(small) {
      max-width: 50%;
      min-width: 50%;
    }
    .btn.xs_small_btn {
      width: 120px;
      @include flexbox;
      color: #fff;
    }
  }
  span {
    color: #333333;
    font-size: 12px;
    margin: 0 0 3px 0;
  }
  label {
    color: $text-color-main;
    font-size: 12px;
    font-weight: 500;
    margin: 0;
    &.btn {
      clear: both;
      width: auto;
      display: inline-block;
      margin: 0 2.5rem 0 0;
    }
  }
  .load-status-btn {
    margin: 10px 0 0;
    .btn {
      margin: 0 15px 0 0;
      padding: 0 25px;
      width: auto;
      &.mat-dark-blue {
        margin-bottom: 0.6rem;
      }
      span {
        color: #fff;
        margin: 0;
        font-weight: 400;
      }
    }
  }
}

.active-lead-iframe {
  display: block;
  margin: 10px 0;
  iframe {
    margin: 0;
    border: 0;
    width: 100%;
  }
}

.load-detail-services {
  @include flexbox;
  flex-direction: column;
  margin: 10px 0 30px;
  padding: 0 0 30px 0;
  border-bottom: 1px solid #e0e0e0;
  &.rfp-detail-services {
    .load-detail-services-list {
      margin: 0 10px 15px 0;
      @include flexbox;
      align-items: center;
      .material-icons {
        width: 32px;
        height: 32px;
        background: #fafafa;
        border-radius: 16px;
        @include flexbox;
        @include justify-content(center);
        @include align-items(center);
      }
    }
  }
  &.load-detail-services-col {
    flex-direction: initial;
    flex-wrap: wrap;
    border: 0;
    padding: 0;
    margin-bottom: 0;
    .load-detail-services-list {
      min-width: calc(33.33% - 10px);
      max-width: calc(33.33% - 10px);
      white-space: nowrap;
      .text-grey-light {
        white-space: initial;
        word-break: break-word;
      }
    }
  }
  .load-detail-services-list {
    @include flexbox;
    margin: 0 0.714rem 1.428rem 0;
    font-size: 1.071rem;
    color: $d_black;
    font-weight: 500;
    @include breakpoint(small) {
      justify-content: space-between;
      width: 100%;
      margin: 0 0px 1.071rem 0;
      padding: 0 0 0 2.5rem;
      position: relative;
      .material-icons {
        position: absolute !important;
        left: -4px;
        top: -8px !important;
      }
    }
    .material-icons {
      width: 20px;
      color: $d_black;
      height: 18px;
      font-size: 1.285rem;
      margin: 0 1.071rem 0 0;
      font-weight: 400;
      position: relative;
      top: 1px;
    }
    .text-grey-light {
      font-weight: 400;
      margin: 0 0 0 5px;
    }
  }
}

.profile-box-area {
  display: block;
  position: relative;
  background-color: $white;
  padding: 20px 20px 20px 6.428rem;
  box-shadow: 0px 1px 1px #0000000f;
  border-radius: 5px;
  .profile-box-img {
    position: absolute;
    left: 20px;
    top: 20px;
    width: 3.928rem;
    height: 3.928rem;
    border-radius: 100%;
    overflow: hidden;
    border: 1px solid $row-type-border;
    img {
      width: 100%;
      height: 100%;
    }
  }
  h3 {
    font-weight: 500;
    margin: 0;
    font-size: 1.142rem;
  }
  h5 {
    font-size: 15px;
    color: #888888;
    font-weight: 400;
    margin: 0;
  }
  .reviews-number {
    font-size: 0.857rem;
    color: rgba(#0f0f11, 0.5);
    display: block;
    margin: 0px 0 15px 0;
  }
  label {
    font-size: 1rem;
    color: $text-color-main;
    display: block;
    margin: 0px;
    font-weight: 400;
    strong {
      font-weight: 500;
    }
  }
  p {
    font-size: 12px;
    color: $grey-text;
    display: block;
    margin: 15px 0 0px 0;
    font-weight: 300;
  }
  .like-area {
    position: absolute;
    right: 8px;
    top: 8px;
    width: 24px;
    height: 24px;
    border: 1px solid #e3e3e3;
    border-radius: 100%;
    text-align: center;
    z-index: 1;
    cursor: pointer;
    span {
      color: #e3e3e3;
      font-size: 14px;
      line-height: 22px;
    }
    &.active {
      background-color: $green2;
      border: 1px solid $green2;
      span {
        color: $white;
      }
    }
  }
}

.like-area {
  right: 8px;
  top: 8px;
  width: 24px;
  height: 24px;
  border: 1px solid #e3e3e3;
  border-radius: 100%;
  text-align: center;
  z-index: 1;
  cursor: pointer;

  span {
    color: #e3e3e3;
    font-size: 14px;
    line-height: 22px;
  }
  &.active {
    background-color: $green2;
    border: 1px solid $green2;
    span {
      color: $white;
    }
  }
}

@media only screen and (max-width: 980px) {
  .like-area-parent {
    position: relative;
  }
  .like-area {
    right: 8px;
    position: absolute;
  }
}
.rating-star {
  @include flexbox;
  @include align-items(center);
  width: 100%;
  position: relative;
  .material-icons {
    font-size: 14px;
    margin: 0 2px 0 0;
    color: $green2;
  }
  .number {
    color: #0f0f11;
    font-size: 12px;
  }
}

.small-icon {
  font-size: 18px !important;
}

.info-section-top {
  @include flexbox;
  @include justify-content(space-between);
  @include align-items(center);
  flex-wrap: wrap;
  @include breakpoint(small) {
    margin: 10px 0 !important;
  }
  .info-section-top-right {
    @include flexbox;
    @include align-items(center);
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    @include breakpoint(small) {
      width: 100%;
      margin: 5px 0 10px 0;
    }
    label {
      margin: 0 8px 0 0;
    }
    .divide {
      width: 1px;
      height: 13px;
      background: $light-grey-2;
      margin: 0 20px;
      opacity: 0.4;
    }
    span {
      display: flex;
      align-items: center;
    }
    i {
      font-size: 18px;
      font-weight: 400;
    }
  }
}

.person-info-section {
  display: flex;
  align-items: center;
  margin: 10px 0;
  width: 100%;
  position: relative;
  flex-wrap: wrap;
  justify-content: space-between; //center
  &.modal-pperson-info {
    padding: 10px 30px 10px;
    border-bottom: solid 1px #e5e5e5;
  }
  .main-info-area {
    display: block;
    position: relative;
    padding: 0 0 0 5.714rem;
    margin: 0 1.428rem 1.428rem 0;
    min-width: 20%;
    min-height: 4.285rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .img-area {
      width: 4.285rem;
      min-width: 4.285rem;
      height: 4.285rem;
      border-radius: 100%;
      overflow: hidden;
      margin: 0 10px 0 0;
      position: absolute;
      left: 0px;
      top: 0;
      img {
        width: 100%;
        height: 100%;
      }
    }
    h4 {
      font-weight: 600;
      font-size: 1.142rem;
      margin: 0;
    }
    h5 {
      font-weight: 400;
      font-size: 0.857rem;
      color: $grey-text;
      margin: 0 0 3px 0;
    }
  }
  .profile-info-list {
    display: flex;
    flex-direction: column;
    margin: 0px 1.428rem 1.428rem 0;
    min-width: 12%;
    span {
      font-weight: 300;
      font-size: 10px;
      color: $grey-text;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      i {
        font-size: 1rem;
        font-weight: 400;
      }
    }
    label {
      font-weight: 400;
      font-size: 0.857rem;
      color: $d_black;
    }
  }
}

.route-timeline-area {
  display: block;
  margin: 10px 0;
  position: relative;
  &.hide-route-line {
    .route-timeline {
      li {
        padding-left: 0 !important;
        &:before {
          display: none !important;
        }
        &:after {
          display: none !important;
        }
      }
    }
  }
  ul.route-timeline {
    list-style-type: none;
    position: relative;
    margin: 1.428rem 0 0;
    padding: 0;
    li:last-child {
      &:after {
        display: none;
      }
    }
    li {
      margin: 0px 0;
      padding-left: 2.857rem;
      width: 100%;
      position: relative;
      &:before {
        content: " ";
        display: inline-block;
        position: absolute;
        border-radius: 50%;
        left: -5px;
        width: 2.142rem;
        height: 2.142rem;
        background: $green2;
      }
      &:after {
        content: " ";
        background: #dddddd;
        display: inline-block;
        position: absolute;
        left: 10px;
        width: 1px;
        height: calc(100% - 14px);
        top: 2.285rem;
      }
      .icon {
        position: absolute;
        left: -5px;
        top: 5px;
        color: $white;
        width: 2.142rem;
        height: 2.142rem;
        line-height: 2.142rem;
        text-align: center;
        i {
          font-size: 1.428rem;
        }
      }
      h4 {
        font-weight: 500;
        margin: 5px 0px 0.714rem 0;
        font-size: 1rem;
      }
      p {
        margin: 0.714rem 0 1.428rem 0;
      }
      .route-timeline-status {
        max-width: 600px;
        @include flexbox;
        @include align-items(flex-start);
        flex-wrap: wrap;
        &.route-timeline-status-2 {
          box-shadow: none;
          background: $white;
          padding: 0;
          margin-bottom: 0rem;
          .route-timeline-status-list {
            width: 40% !important;
            min-width: 40% !important;
            @include justify-content(flex-start);
            @include breakpoint(small) {
              width: 100% !important;
              min-width: 100% !important;
            }
            .form_row.checkbox-area {
              display: flex;
              .mat-checkbox.small-check {
                width: 50%;
              }
            }
            h4 {
              line-height: 1.4rem;
            }
          }
        }
        .route-timeline-status-list {
          width: 50%;
          margin: 0 1.071rem 1.071rem 0;
          &.addresss-list {
            width: 20% !important;
          }
          label {
            display: block;
            margin: 0;
            font-size: 0.857rem;
            @include breakpoint(small) {
              font-size: 1rem;
            }
          }
          h4 {
            margin: 0;
          }
        }
        &.no-grey-time {
          background: none;
          border: 0px;
          padding: 5px 0;
          box-shadow: none;
          justify-content: flex-start;
          .route-timeline-status-list {
            margin-right: 2.142rem;
          }
        }
      }
      &.last-li {
        &:after {
          display: none;
        }
        p {
          margin-bottom: 0;
        }
      }
    }
  }
}

.attachment-text {
  display: flex;
  align-items: center;
  i {
    margin: 0 5px 0 0;
  }
}

.mat-menu-panel.cm-btn-action {
  box-shadow: 0px 4px 7.5px 0.2px rgba(0, 0, 0, 0.18);
  min-width: 150px;
  &:before {
    content: "";
    position: absolute;
    top: -10px;
    right: 20px;
    border-bottom: solid 10px #c3c3c3;
    border-left: solid 10px transparent;
    border-right: solid 10px transparent;
  }
  &:after {
    content: "";
    position: absolute;
    top: -10px;
    right: 20px;
    border-bottom: solid 10px #fff;
    border-left: solid 10px transparent;
    border-right: solid 10px transparent;
  }
  .mat-menu-content {
    padding: 0px !important;
    .mat-menu-item {
      display: flex;
      align-items: center;
      color: $title-color;
      font-size: 12px;
      height: auto;
      border: 0px;
      border-bottom: 1px solid #eee;
      line-height: 42px;
      &:hover {
        background: #eef5fa;
      }
      i {
        color: #7f7d8b;
        margin: 0px 10px 0px 0px;
        font-size: 14px;
      }
    }
  }
}

.icon-with-heading-4 {
  display: flex;
  align-items: center;
  .material-icons {
    margin: 0 5px 0 0;
    font-size: 22px;
  }
}

.icon-with-text-5 {
  display: flex;
  align-items: center;
  .material-icons {
    margin: 0 5px 0 0;
    font-size: 20px;
  }
}

.notes-area {
  background: #f1f1f1;
  border-radius: 3px;
  padding: 20px;
  box-shadow: inset 0 0 6px rgb(0 0 0 / 10%);
  p {
    margin: 0 0 5px 0;
  }
}

.text-paragraph {
  font-size: 1rem;
  color: $text-color-main;
}

.comment-box {
  display: block;
  margin: 20px 0 40px 0;
  position: relative;
  &:last-child {
    margin-bottom: 0;
  }
  .form_row textarea.input_fld {
    height: 6.071rem;
  }
}

.edit-textarea {
  display: flex;
  align-items: center;
  font-size: 1rem;
  position: absolute;
  right: 0;
  cursor: pointer;
  z-index: 1;
  top: -1.785rem;
  .material-icons {
    font-size: 1.1rem;
    margin: 0 5px 0 0;
  }
}

.input-message {
  margin: 2px 0 0 !important;
  text-align: right;
  display: block;
}

.decline-btn {
  opacity: 0.6;
}

.time-picker .ngx-mat-timepicker {
  width: 100%;
  height: 2.857rem;
  padding: 0px 15px;
  color: #676b7a;
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  outline: none !important;
  background: #ffffff;
  border-radius: 3px;
  border: 1px solid #999;
  display: block;
  form {
    height: 100%;
  }
  .table {
    margin: 0;
    tr {
      td {
        padding: 0;
        border: 0;
        position: relative;
        .mat-icon-button {
          height: 10px;
          width: 24px;
          line-height: 10px;
          position: absolute;
          right: 0px;
          .mat-icon {
            line-height: 8px;
          }
        }
      }
    }
  }
}

.owl-dt-container,
.owl-dt-container * {
  box-sizing: border-box;
}

.owl-dt-container {
  display: block;
  font-size: 1rem;
  background: #ffffff;
  pointer-events: auto;
  z-index: 1000;
}

.owl-dt-container-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  &:last-child {
    border-bottom: none;
  }
}

.owl-dt-calendar {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.owl-dt-calendar-control {
  display: flex;
  align-items: center;
  font-size: 1em;
  width: 100%;
  padding: 0.5em;
  color: #000000;
  .owl-dt-calendar-control-content {
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    .owl-dt-calendar-control-button {
      padding: 0 0.8em;
      &:hover {
        background-color: rgba(0, 0, 0, 0.12);
      }
    }
  }
}

.owl-dt-calendar-main {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 0 0.5em 0.5em;
  outline: 0;
}

.owl-dt-calendar-view {
  display: block;
  flex: 1 1 auto;
}

.owl-dt-calendar-multi-year-view {
  display: flex;
  align-items: center;
  .owl-dt-calendar-table {
    width: calc(100% - 3em);
    .owl-dt-calendar-header th {
      padding-bottom: 0.25em;
    }
  }
}

.owl-dt-calendar-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  .owl-dt-calendar-header {
    color: rgba(0, 0, 0, 0.4);
    .owl-dt-weekdays {
      th {
        font-size: 0.7em;
        font-weight: 400;
        text-align: center;
        padding-bottom: 1em;
      }
    }
    .owl-dt-calendar-table-divider {
      position: relative;
      height: 1px;
      padding-bottom: 0.5em;
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: -0.5em;
        right: -0.5em;
        height: 1px;
        background: rgba(0, 0, 0, 0.12);
      }
    }
  }
  .owl-dt-calendar-cell {
    position: relative;
    height: 0;
    line-height: 0;
    text-align: center;
    outline: 0;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.85);
    -webkit-appearance: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
  }
  .owl-dt-calendar-cell-content {
    position: absolute;
    top: 5%;
    left: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 90%;
    height: 90%;
    font-size: 0.8em;
    line-height: 1;
    border: 1px solid transparent;
    border-radius: 999px;
    color: inherit;
  }
  .owl-dt-calendar-cell-out {
    opacity: 0.2;
  }
  .owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected) {
    border-color: rgba(0, 0, 0, 0.4);
  }
  .owl-dt-calendar-cell-selected {
    color: hsla(0, 0%, 100%, 0.85);
    background-color: $dark-base;
    &.owl-dt-calendar-cell-today {
      box-shadow: inset 0 0 0 1px hsla(0, 0%, 100%, 0.85);
    }
  }
  .owl-dt-calendar-cell-disabled {
    cursor: default;
    & > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected) {
      color: rgba(0, 0, 0, 0.4);
    }
    & > .owl-dt-calendar-cell-content.owl-dt-calendar-cell-selected {
      opacity: 0.4;
    }
    & > .owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected) {
      border-color: rgba(0, 0, 0, 0.2);
    }
  }
  .owl-dt-calendar-cell-active:focus
    > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected),
  :not(.owl-dt-calendar-cell-disabled):hover
    > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected) {
    background-color: rgba(0, 0, 0, 0.04);
  }
  .owl-dt-calendar-cell-in-range {
    background: rgba($dark-base, 0.2);
    &.owl-dt-calendar-cell-range-from {
      border-top-left-radius: 999px;
      border-bottom-left-radius: 999px;
    }
    &.owl-dt-calendar-cell-range-to {
      border-top-right-radius: 999px;
      border-bottom-right-radius: 999px;
    }
  }
}

.owl-dt-timer {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 7em;
  padding: 0.5em;
  outline: none;
}

.owl-dt-timer-box {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
  height: 100%;
}

.owl-dt-timer-content {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0.2em 0;
  .owl-dt-timer-input {
    display: block;
    width: 2em;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 3px;
    outline: medium none;
    font-size: 1.2em;
    padding: 0.2em;
  }
}

.owl-dt-timer-divider {
  display: inline-block;
  align-self: flex-end;
  position: absolute;
  width: 0.6em;
  height: 100%;
  left: -0.3em;
  &:before,
  &:after {
    content: "";
    display: inline-block;
    width: 0.35em;
    height: 0.35em;
    position: absolute;
    left: 50%;
    border-radius: 50%;
    transform: translateX(-50%);
    background-color: currentColor;
  }
  &:before {
    top: 35%;
  }
  &:after {
    bottom: 35%;
  }
}

.owl-dt-control-button {
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  margin: 0;
  padding: 0;
  background-color: transparent;
  font-size: 1em;
  color: inherit;
  .owl-dt-control-button-content {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    outline: none;
  }
  &:focus > .owl-dt-control-button-content {
    background-color: rgba(0, 0, 0, 0.12);
  }
  &:not(:-moz-focusring):focus > .owl-dt-control-button-content {
    box-shadow: none;
  }
}

.owl-dt-control-period-button {
  .owl-dt-control-button-content {
    height: 1.5em;
    padding: 0 0.5em;
    border-radius: 3px;
    transition: background-color 100ms linear;
  }
  &:hover > .owl-dt-control-button-content {
    background-color: rgba(0, 0, 0, 0.12);
  }
  .owl-dt-control-button-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1em;
    height: 1em;
    margin: 0.1em;
    transition: transform 200ms ease;
  }
}

.owl-dt-control-arrow-button {
  .owl-dt-control-button-content {
    padding: 0;
    border-radius: 50%;
    width: 1.5em;
    height: 1.5em;
  }
  &[disabled] {
    color: rgba(0, 0, 0, 0.4);
    cursor: default;
  }
  svg {
    width: 50%;
    height: 50%;
    fill: currentColor;
  }
}

.owl-dt-inline-container,
.owl-dt-popup-container {
  position: relative;
  width: 18.5em;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  .owl-dt-calendar,
  .owl-dt-timer {
    width: 100%;
  }
  .owl-dt-calendar {
    height: 20.25em;
  }
}

.owl-dt-inline-container {
  display: inline-block;
}

.owl-dt-dialog-container {
  max-height: 95vh;
  margin: -1.5em;
  .owl-dt-calendar {
    min-width: 250px;
    min-height: 330px;
    max-width: 750px;
    max-height: 750px;
  }
  .owl-dt-timer {
    min-width: 250px;
    max-width: 750px;
  }
}

@media all and (orientation: landscape) {
  .owl-dt-dialog-container {
    .owl-dt-calendar {
      width: 58vh;
      height: 62vh;
    }
    .owl-dt-timer {
      width: 58vh;
    }
  }
}

@media all and (orientation: portrait) {
  .owl-dt-dialog-container {
    .owl-dt-calendar {
      width: 80vw;
      height: 80vw;
    }
    .owl-dt-timer {
      width: 80vw;
    }
  }
}

.owl-dt-container-buttons {
  display: flex;
  width: 100%;
  height: 2em;
  color: $dark-base;
}

.owl-dt-container-control-button {
  font-size: 1em;
  width: 50%;
  height: 100%;
  border-radius: 0;
  .owl-dt-control-button-content {
    height: 100%;
    width: 100%;
    transition: background-color 100ms linear;
  }
  &:hover {
    .owl-dt-control-button-content {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

.owl-dt-container-info {
  padding: 0 0.5em;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  .owl-dt-container-range {
    display: flex;
    justify-content: space-between;
    padding: 0.5em 0;
    font-size: 0.8em;
    &:last-child {
      border-top: 1px solid rgba(0, 0, 0, 0.12);
    }
  }
  .owl-dt-container-info-active {
    color: $dark-base;
  }
}

.owl-dt-container-disabled,
.owl-dt-trigger-disabled {
  opacity: 0.35;
  filter: Alpha(Opacity=35);
  background-image: none;
  cursor: default !important;
}

.owl-dt-timer-hour12 {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $dark-base;
  .owl-dt-timer-hour12-box {
    border: 1px solid currentColor;
    border-radius: 2px;
    transition: background 200ms ease;
    .owl-dt-control-button-content {
      width: 100%;
      height: 100%;
      padding: 0.5em;
    }
    &:focus,
    &:hover {
      .owl-dt-control-button-content {
        background: $dark-base;
        color: #ffffff;
      }
    }
  }
}

.owl-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.btn-50 {
  display: flex;
  .btn.min-width-btn {
    min-width: 50%;
  }
}

.total-job-posted {
  width: 100%;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  margin: 0px 0;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $white;
  .tag {
    width: 26px;
    height: 26px;
    background: $base2;
    color: $white;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  h3 {
    font-weight: 500;
    margin: 0;
  }
}

.profile-link {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  margin: 0px 0;
  padding: 15px 15px;
  background: $white;
  h3 {
    font-weight: 500;
    margin: 0 0 10px;
  }
  span {
    width: calc(100% - 40px);
    padding: 0px 10px 0px 10px;
    background: #f3f3f8 0% 0% no-repeat padding-box;
    border-radius: 3px;
    height: 40px;
    color: #929292;
    display: flex;
    white-space: nowrap;
    align-items: center;
    text-overflow: ellipsis;
    overflow: hidden;
    float: left;
  }
  .copy-block {
    position: relative;
    margin: 0 0 10px 0;
  }
  .material-icons {
    width: 39px;
    height: 40px;
    background: $grey-text;
    border-radius: 0px 3px 3px 0px;
    position: absolute;
    right: 0;
    top: 0;
    color: $white;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.accepting-bid {
  position: relative;
  display: flex;
  h2 {
    white-space: nowrap;
  }
  &.with-rate-text {
    justify-content: space-between;
  }
}

.location-panel {
  box-shadow: 0px 1px 2px #00000029;
  border-radius: 3px;
  background: $white;
  h3 {
    background: #f7f7f7;
    padding: 14px 20px;
    line-height: normal;
  }
  .location-panel-inner {
    padding: 15px 20px;
  }
}

.detail-grey-full {
  .location-panel {
    h3 {
      background: #ececec;
    }
  }
}

.form_row {
  &.checkbox-flex {
    display: flex;
    flex-wrap: wrap;
    .label {
      width: auto;
      margin: 0 8px 0 0;
      display: flex;
      align-items: center;
    }
    .mat-checkbox {
      margin: 0 15px 10px 0;
      label {
        margin: 0;
      }
    }
  }
}

.input-one-row {
  .form_row.input_lumper {
    position: absolute;
    left: 15px;
    width: 140px;
    z-index: 1;
    @include breakpoint(x-small) {
      position: relative;
      left: 0;
      width: 100%;
    }
  }
  .file-input-field.form_row {
    padding-left: 150px;
    @include breakpoint(x-small) {
      padding-left: 0px;
      label {
        display: none;
      }
    }
    .choose-input .choose-btn {
      margin: 0px;
    }
  }
}

.detail-main-heading {
  flex-wrap: wrap;
  white-space: nowrap;
  margin: 0 0 5px 0;
}

.heading-with-price {
  padding-right: 130px;
  .heading-area {
    min-height: 55px;
    h3 {
      line-height: normal;
      margin: 3px 0;
      img {
        position: relative;
        top: -2px;
        margin: 2px;
      }
    }
  }
  .rate {
    position: absolute;
    right: 5px;
    top: 4px;
  }
}

.document-div {
  @include flexbox;
  a {
    @include flexbox;
    @include align-items(center);
    .material-icons {
      color: $green2;
      margin: 0 5px 0 0;
      font-size: 1.6rem;
    }
  }
  span.material-icons {
    color: $green2;
    margin: 0 5px 0 0;
    font-size: 1.6rem;
  }
  span {
    color: $green2;
    font-size: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 220px;
    &.more {
      color: $grey-text;
      margin: 0 0px 0 5px;
    }
  }
}

.link-text {
  cursor: pointer;
  outline: none;
}

.large-modal-footer {
  @include breakpoint(small) {
    flex-direction: column-reverse;
    margin: 0 0 10px 0;
  }
  .btn {
    @include breakpoint(small) {
      // margin: 0 0 10px 0;
      margin: auto;
      width: 100%;
      max-width: 100%;
    }
    &.green_btn {
      min-width: 220px;
    }
  }
}

.t-info-box {
  padding: 0px 0;
  min-height: 60px;
  display: flex;
  width: 100%;
  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
  .t-img-ara {
    width: 243px;
    height: 182px;
    min-width: 243px;
    margin: 0 30px 0 0;
    border-radius: 4px;
    overflow: hidden;
    @media only screen and (max-width: 767px) {
      margin: 0 0px 20px 0;
    }
    img {
      max-width: 100%;
      background: #eeeeee;
      max-width: 100%;
      max-height: 100%;
    }
  }
  .t-info-right {
    width: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    .t-info-top {
      margin: 0;
      h2 {
        margin: 0;
      }
      .btn {
        margin: 0 0px 0 0;
        padding: 0 25px;
        width: auto;
        span {
          color: #fff;
          margin: 0;
          font-weight: 400;
        }
      }
    }
    h3 {
      margin: 0 0 15px 0;
    }
  }
}

.t-info-lising {
  width: 100%;
  @include flexbox;
  @include flex-wrap(wrap);
  @include justify-content(space-between);
  &.style-2 {
    @include justify-content(flex-start);
    @include breakpoint(medium) {
      @include justify-content(space-between);
    }
    .t-info-list {
      width: 20%;
      @include breakpoint(small) {
        width: 100%;
      }
    }
  }
  .t-info-list {
    @include flexbox;
    @include align-items(center);
    margin: 0 0 10px 0;
    .t-info-ic {
      width: 40px;
      height: 40px;
      background: #f3f3f8 0% 0% no-repeat padding-box;
      border-radius: 100%;
      @include flexbox;
      @include justify-content(center);
      @include align-items(center);
      margin: 0 15px 0 0;
      i {
        color: #888888;
        font-size: 20px;
      }
    }
    .t-info-text {
      @include flexbox;
      flex-direction: column;
      @include align-items(flex-start);
      label {
        color: #0f0f11;
        font-size: 14px;
        margin: 0;
        font-weight: 700;
        white-space: nowrap;
      }
      span {
        color: #0f0f11;
        font-size: 14px;
        margin: 0;
        &.pdf-text {
          color: $green2;
        }
        &.more {
          color: $grey-text;
        }
      }
    }
  }
}

.right_sec .main_inner .white_box {
  &.common-detail-inner {
    padding: 20px 30px;
    .divide-line {
      background: #e0e0e0;
      margin: 40px -30px;
      height: 1px;
      display: block;
    }
  }
}

.route-timeline-area {
  h4 {
    position: relative;
  }
  h3 {
    font-size: 16px !important;
    font-weight: 600;
    text-transform: uppercase;
    span {
      color: $green2;
    }
  }
  .route-timeline-status {
    max-width: 100% !important;
    justify-content: space-between;
    background: #fafafa 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 2px #00000029;
    border-radius: 3px;
    padding: 10px 15px 0px 15px;
    margin: 0 0 20px 0;
    .route-timeline-status-list {
      width: auto !important;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: left;
    }
  }
}

.small-left-right-heading {
  img {
    margin: 0 5px;
  }
}

.driver-info {
  cursor: pointer;
  .info-hover {
    position: absolute;
    left: 0px;
    top: 20px;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s;
    background: $white;
    padding: 15px 0;
    z-index: 1;
    min-width: 500px;
    border-radius: 4px;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);
    .bid-section {
      .heading-with-price {
        position: relative;
      }
      .rating-star {
        position: absolute;
        right: 15px;
        width: auto;
        top: 0px;
      }
    }
    .contact-info {
      margin: 20px 0 0;
      border-top: 1px solid #eee;
      padding: 20px 20px 0px 84px;
      h3 {
        margin: 0 0 10px 0;
      }
      .info {
        display: flex;
        flex-wrap: wrap;
        p {
          display: flex;
          flex-wrap: wrap;
          margin: 0 10px 15px 0;
          i {
            position: relative;
            font-size: 20px;
            top: 2px;
            margin: 0 5px 0 0;
          }
        }
      }
    }
  }
  &:hover {
    .info-hover {
      opacity: 1;
      pointer-events: inherit;
    }
  }
}

.heading-with-backarrow {
  @include flexbox;
  @include flex-wrap(wrap);
  .back-arrow {
    font-size: 2.142rem;
    margin: 0 0.8rem 0 0;
    cursor: pointer;
    position: relative;
    top: 0;
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    &:focus {
      outline: none;
    }
  }
}

.right_sec {
  .main_inner {
    .table-view {
      .white_box {
        @media (max-width: 991px) {
          padding: 0;
        }
      }
    }
  }
}

.phone-number-box {
  .input_block {
    padding: 0 0 0 110px;
    &.select-border-box-outer {
      max-width: 100px;
      position: absolute;
      left: 0px;
      top: 24px;
      padding: 0;
    }
  }
}

.personal-info-box {
  background: #f6f8fb;
  border-radius: 3px;
  padding: 1.428rem 1.428rem 0 1.428rem;
  margin: 0 0 2.142rem 0;
  width: 100%;
}

.profile-box-ara {
  display: flex;
  width: 100%;
  position: relative;
  @include breakpoint(small) {
    flex-direction: column;
  }
  .profile-box-left {
    width: 14rem;
    min-width: 14rem;
    margin: 0 2.142rem 0 0;
    @include breakpoint(small) {
      margin: 0 0px 1.428rem 0;
      width: 100%;

      .person-info-section {
        display: flex;
        justify-content: center;

        h6 {
          font-size: 14px;
          width: 100%;
        }

        .rating-show {
          text-align: left; //center
        }
      }
    }
    .profile-img-area {
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #e9eaeb;
      border-radius: 4px;
      width: 100%;
      height: 14rem;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      &:hover {
        .change-img {
          opacity: 1;
          pointer-events: initial;
        }
      }
      .change-img {
        position: absolute;
        top: 0px;
        bottom: 0;
        width: 100%;
        height: 100%;
        margin: auto;
        left: 0;
        right: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        cursor: pointer;
        background: rgba(0, 0, 0, 0.5);
        opacity: 0;
        pointer-events: none;
        transition: all 0.3s;
        input {
          position: absolute;
          left: 0;
          top: 0px;
          width: 100%;
          height: 100%;
          opacity: 0;
          cursor: pointer;
          z-index: 1;
        }
        i {
          color: #fff;
          margin: 0 0 10px 0;
          font-size: 30px;
        }
        span {
          font-size: 14px;
          color: #fff;
        }
      }
      .upload-img {
        position: absolute;
        top: 0px;
        bottom: 0;
        width: 100%;
        height: 100%;
        margin: auto;
        left: 0;
        right: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        cursor: pointer;
        input {
          position: absolute;
          left: 0;
          top: 0px;
          width: 100%;
          height: 100%;
          opacity: 0;
          cursor: pointer;
          z-index: 1;
        }
        i {
          color: #c9cad5;
          margin: 0 0 10px 0;
          font-size: 32px;
        }
        span {
          font-size: 14px;
          color: $green2;
        }
      }
    }
  }
}

div.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
}

@-webkit-keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-ms-keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.uil-ring-css {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 200px;
  height: 200px;
}

.uil-ring-css > div {
  position: absolute;
  display: block;
  width: 160px;
  height: 160px;
  top: 20px;
  left: 20px;
  border-radius: 80px;
  box-shadow: 0 6px 0 0 $green2;
  -ms-animation: uil-ring-anim 1s linear infinite;
  -moz-animation: uil-ring-anim 1s linear infinite;
  -webkit-animation: uil-ring-anim 1s linear infinite;
  -o-animation: uil-ring-anim 1s linear infinite;
  animation: uil-ring-anim 1s linear infinite;
}

.info-header-sl {
  display: flex;
  align-items: center;
  padding: 1.071rem 80px 1.071rem 1.429rem;
  border-bottom: 1px dashed #ddd;
  // margin: 0 -20px;
  .view-all {
    background: #c9cad5;
    border-radius: 11px;
    height: 22px;
    font-size: 10px;
    color: #ffffff;
    position: absolute;
    right: 20px;
    padding: 0px 10px;
    line-height: 22px;
    z-index: 1;
    transition: all 0.3s;
    &:hover {
      background: #888990;
    }
  }
}

.cm-shadow-box {
  box-shadow: 16px 6px 42px #00000012 !important;
  border-radius: 8px !important;
}

.d-cm-shadow-box {
  height: 100%;
  min-height: 25rem;
}

.user-pic-name {
  strong {
    font-weight: 500;
  }
  .user-images {
    width: 2.714rem;
    height: 2.714rem;
    background: #f9f9f9;
    border: 1px solid #efefef;
    border-radius: 50%;
    margin-top: 0px;
    margin-right: 1.3rem;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    @include flexbox;
    color: rgba(112, 112, 112, 0.5);
    font-size: 12px;

    .material-icons {
      font-size: 1.714rem;
    }
    img {
      max-width: inherit;
      min-height: 100%;
      min-width: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }
}
.mat-tooltip {
  font-size: 10px !important;
}
.product-images{
  overflow: hidden;
  cursor: pointer;
}
.product-images img{
  width: 100%;
  height: 200px;
  object-fit: cover;
  transition: all .5s linear;

}
.product-images img:hover{
  transform: scale(1.1);
}
.product-dummy-images{
  width: 100%;
  height: 200px;
}
.product-dummy-images img{
  width: auto;
  height: 200px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  // margin-left: 10px;
  // object-fit: cover;
  transition: all .5s linear;
}

.user-list {
  .user-pic-name {
    padding: 14px 10px;
    border-bottom: 1px solid #eee;
    position: relative;
    cursor: pointer;
    &:last-child {
      border: 0;
    }
    &:hover {
      .right-arrow {
        color: $green2;
      }
    }
    .right-arrow {
      position: absolute;
      right: 10px;
      top: 0px;
      bottom: 0;
      margin: auto;
      color: #c9cad5;
      height: 24px;
    }
  }
}

.text-underline {
  text-decoration: underline !important;
}

.form_row .input_fld.ng-pristine.ng-touched.ng-invalid,
.ng-invalid.submitted .input_fld.ng-pristine.ng-invalid {
  border-color: #ff7070;
}
.add-tags-input {
  .mat-form-field {
    border: solid 1px rgba(173, 173, 173, 0.6);
    @include border-radius(0px);
    width: 100%;
    height: 2.857rem;
    padding: 0.28rem 0.714rem;
    color: $text-color;
    font-size: 1rem;
    font-family: $base-font;
    outline: none !important;
    background: $white;
    border-radius: 3px;
    .mat-chip {
      background: $green2;
      border-radius: 3px;
      color: $white;
      font-size: 1rem;
      font-weight: 300;
      .mat-icon {
        color: $white;
        width: 1.285rem;
        height: 1.285rem;
        font-size: 1.285rem;
      }
    }
    .mat-chip-input {
      font-size: 1rem;
    }
  }
}

.close-block {
  position: absolute;
  right: -8px;
  top: -11px;
  cursor: pointer;
  color: #ec6262;
  i {
    font-size: 22px;
  }
}

.select-invited-box {
  background: #ffffff;
  box-shadow: 0px 1px 2px #0000004a;
  border-radius: 3px;
  position: relative;
  max-height: 350px;
  overflow: auto;
  .user-list {
    .checked {
      strong {
        color: $green2;
      }
      .right-arrow {
        border-color: $green2;
        color: $green2;
      }
    }
    .right-arrow {
      background: $white;
      border: 1px solid #c9cad5;
      width: 34px;
      height: 34px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      font-size: 20px;
    }
  }
}

.form_row {
  &.search-with-select {
    display: flex;
    margin: 0 0 15px 0;
    .select-border-box-outer {
      max-width: 120px;
      width: 100%;
      position: relative;
      top: -1px;
      .mat-select.select-border-box {
        border-right: 0;
        border-radius: 3px 0 0 3px;
      }
    }
    .input_fld {
      border-radius: 0px 3px 3px 0px;
    }
  }
}

.rfl-header-area {
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
  border-bottom: 1px solid #eee;
  .rfl-header-area-right {
    display: flex;
    .invited-user-list {
      margin: 0 10px 0 0;
    }
  }
}

.text-info-ic {
  display: flex;
  flex-wrap: wrap;
  label {
    color: #333;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    margin: 0 10px 10px 0;
    flex-wrap: wrap;
  }
  strong {
    font-weight: 600;
  }
  span {
    margin: 0 5px 0 0;
  }
  .material-icons {
    font-size: 20px;
  }
}

.btn {
  &.light-btn-ic {
    background: #f3f3f8;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    color: #333333;
    font-size: 14px;
    font-weight: 500;
    .material-icons {
      color: $green2;
      margin: 0 5px 0 0;
      font-weight: 400;
    }
  }
}

.rfl-detail-area {
  h1 {
    margin: 0;
    color: #0f0f11;
    font-size: 24px;
    float: none !important;
  }
  h2 {
    margin: 0 0 1.428rem 0;
    color: #0f0f11;
    font-size: 1.428rem;
    font-weight: 500;
    @include breakpoint(small) {
      font-size: 1.285rem;
    }
  }
  .white_box.box_shodow {
    min-height: 70vh;
  }
}

.rfl-innner-area {
  padding: 1.25rem 2.142rem;
  position: relative;
  display: block;
}

.cm-mat-accordian {
  .mat-expansion-panel {
    background: #ffffff;
    border: 1px solid #f3f3f8;
    border-radius: 3px;
    counter-reset: my-sec-counter;
    box-shadow: none !important;
    .mat-expansion-panel-header {
      background: #fafafa !important;
      border-radius: 3px;
      padding: 0.5rem 5.142rem;
      position: relative;
      min-height: 60px;
      height: fit-content !important;
      &.mat-expanded {
        &:before {
          background: $green2;
        }
        .mat-expansion-indicator:after {
          background: #0e0e10;
          content: "remove";
        }
      }
      &:before {
        counter-increment: my-sec-counter;
        content: counter(my-sec-counter);
        position: absolute;
        left: 1.785rem;
        top: 0;
        width: 2.142rem;
        height: 2.142rem;
        background: #888888;
        border-radius: 17px;
        text-align: center;
        line-height: 2.3rem;
        color: #fff;
        font-size: 1rem;
        font-weight: 500;
        bottom: 0;
        margin: auto;
      }
    }
    .mat-expansion-indicator {
      transform: initial !important;
      position: absolute;
      right: 2.142rem;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 1.428rem;
      height: 1.428rem;
    }
    .mat-expansion-indicator:after {
      transform: initial !important;
      content: "add";
      width: 1.428rem;
      height: 1.428rem;
      background: #888888;
      border-radius: 1.428rem;
      text-align: center;
      line-height: 1.428rem;
      color: #fff;
      font-size: 1.285rem;
      font-weight: 500;
      bottom: 0;
      margin: auto;
      font-family: "Material Icons";
      transform: initial;
      padding: 0;
      border: 0;
      cursor: pointer;
    }
  }
}

.mat-expansion-panel .mat-expansion-indicator {
  transform: inherit !important;
}

.panel-header-area {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  .panel-header-area-left {
    display: flex;
    div {
      display: flex;
      flex-direction: column;
      &.from {
        padding-right: 50px;
        position: relative;
      }
      .material-icons {
        position: absolute;
        right: 15px;
        bottom: 3px;
        font-size: 1.285rem;
      }
      .rotate-180 {
        transform: rotate(180deg);
      }
    }
    span {
      opacity: 0.5;
      color: $grey-text-333;
      font-size: 12px;
    }
    label {
      color: $grey-text-333;
      font-size: 14px;
      font-weight: 500;
      margin: 0;
    }
  }
  .panel-header-area-right {
    label {
      color: $grey-text-333;
      font-size: 1rem;
      font-weight: 500;
      margin: 0;
      align-items: center;
      display: flex;
      span {
        background: $green2;
        border-radius: 17px;
        height: 25px;
        margin: 0 0 0 8px;
        padding: 0 5px;
        color: $white;
        min-width: 50px;
        text-align: center;
        line-height: 26px;
      }
    }
  }
}

.invited-user-list {
  display: flex;
  span {
    width: 2.285rem;
    height: 2.285rem;
    border-radius: 100%;
    border: 1px solid #eee;
    overflow: hidden;
    margin: 0 4px 0 -8px;
    &:first-child {
      margin: 0 4px 0 0px;
    }
    img {
      max-width: 100%;
    }
    &.added-user {
      background: $green2;
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.divide-line-main {
  background: #e0e0e0;
  margin: 2.142rem 0px;
  height: 1px;
  display: block;
}

.clear-both {
  clear: both;
}

.rfl-main-inner {
  background: $white;
  border: 1px solid #f3f3f8;
  border-radius: 3px;
  padding: 0px 2.142rem 2.142rem 2.142rem;
  overflow: hidden;
  margin: 0 0 1.428rem 0;
}

.select-border-box-outer {
  .chip-style {
    border-color: $input-border;
    min-height: 3rem;
    height: auto;
    &:active {
      border-color: $green;
      outline: none !important;
    }
    &:hover {
      border-color: $input-hover;
    }
  }
}

.bid-edit-icon {
  width: 2.571rem;
  height: 2.571rem;
  background: #f3f3f8 0% 0% no-repeat padding-box;
  border: 1px solid #f3f3f8;
  border-radius: 100%;
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  i {
    color: #888888;
    font-size: 1.142rem;
  }
}

.profile-menu.header-profile-menu {
  @include breakpoint(large) {
    display: none !important;
  }
}

.heading-text-with-arrow {
  flex-wrap: wrap;
  margin: 0 0 10px 0;
  h1 {
    line-height: normal;
  }
}

.mat-drawer-container {
  z-index: 5;
}

.detail-grey-full {
  overflow: hidden;
  .tag-area .tag-list {
    background: #e6e6e6;
  }
  .page_header_area {
    background: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
    margin: -20px -20px 15px -20px !important;
    padding: 14px 15px;
    @include breakpoint(xxxlarge) {
      margin-top: 0 !important;
    }
    @include breakpoint(small) {
      margin: -15px -15px 15px -15px !important;
    }
    h1 {
      @include breakpoint(small) {
        font-size: 18px;
      }
      .back-arrow {
        @include breakpoint(small) {
          font-size: 22px;
          top: 0px;
        }
      }
    }
  }
  .main_inner {
    background: #fafafa;
    .white_box {
      background: none;
      box-shadow: none;
      .load-detail-section-repeat {
        padding: 5px 0px !important;
        h2 {
          @include breakpoint(small) {
            font-weight: 500 !important;
          }
        }
        .route-timeline-status {
          background: #fff !important;
          justify-content: flex-start;
          padding: 12px 18px;
          box-shadow: 0px 1px 2px #00000029;
          .route-timeline-status-list {
            // width: 100% !important;
            // margin: 0 0px 10px 0;
            .checkbox-area {
              display: flex;
              justify-content: space-between;
              .mat-checkbox {
                width: 50%;
              }
            }
            .select-misc-option .misc-button {
              padding-left: 20px;
              padding-right: 20px;
              height: 38px;
              width: 100px;
              display: flex;
              align-items: center;
              justify-content: center;
              .icon-start {
                left: 0;
                top: 0;
                position: relative;
                margin: 0 5px 0 0;
              }
            }
          }
        }
        .mat-table .mat-row {
          border-color: #fafafa;
        }
      }
    }
  }
}

.check-icon-with-circle {
  display: inline-flex;
  width: 40px;
  height: 40px;
  border: 1px solid $green;
  border-radius: 40px;
  align-items: center;
  justify-content: center;
  margin: 0 0 15px 0;
  .material-icons {
    color: $green;
  }
}

.notes-box {
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  margin: 10px 0px 15px 0px !important;
  padding: 14px 15px;
  border-radius: 4px;
  position: relative;
  .notes-date {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    span {
      color: #ababab;
      margin: 0 5px 0 0;
      font-size: 13px;
    }
    .material-icons {
      font-size: 18px;
    }
  }
  .edit-note {
    position: absolute;
    right: 0px;
    top: -36px;
    cursor: pointer;
    i {
      font-size: 20px;
    }
  }
}

.container-height.main-inner-area {
  min-height: calc(100vh - 5.714rem);
}

.select-misc-option h2 {
  white-space: nowrap;
}

.mat-drawer-content {
  z-index: inherit !important;
}

.mat-drawer.mat-drawer-side {
  z-index: inherit !important;
}

.mat-drawer-container {
  z-index: inherit !important;
}

.input-with-price {
  display: flex !important;
  width: 100% !important;
  .slider-outer {
    width: 100%;
  }
  .price-input {
    max-width: 140px;
    width: 140px;
    min-width: 140px;
    margin-left: 15px;
  }
}

.modal-body-inner {
  .route-timeline-status {
    // background: #fff !important;
    justify-content: flex-start !important;
    .route-timeline-status-list {
      width: 100% !important;
      margin: 0 0px 10px 0 !important;
      .checkbox-area {
        display: flex;
        .mat-checkbox {
          margin: 0 15px 0 0;
        }
      }
      .select-misc-option .misc-button {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }
}

.status-text.rounded {
  border-radius: 40px !important;
}

.signature-area {
  position: relative;
  .draw {
    border: 1px solid #ddd;
    border-radius: 3px;
    height: 8rem;
    padding: 5px 10px;
    background: #fff;
    signature-pad.draw {
      border: 0;
      padding: 0;
      height: 7rem;
      // width: 100%;
      display: flex;
      align-items: center;
    }
    canvas {
      height: inherit;
      width: inherit;
      // max-width: 100%;
    }
  }
  .clear {
    position: absolute;
    right: 0;
    top: -25px;
    cursor: pointer;
    z-index: 1;
    text-decoration: underline;
  }
}

.select-staff-branch {
  .person-info-section {
    width: 100%;
    display: flex;
    flex-wrap: initial;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    border: solid 1px #e5e5e5;
    margin: 0;
    border-bottom: 0;
    &:last-child {
      border-bottom: solid 1px #e5e5e5;
    }
    .checkarea {
      margin: 0 15px 0 0;
    }
    .main-info-area {
      width: 100%;
      margin: 0;
      padding: 0 0 0 65px;
      .img-area {
        width: 50px;
        height: 50px;
        margin: auto;
        bottom: 0;
      }
      h4 {
        line-height: normal;
        span {
          background: $row-type-border;
          font-size: 11px;
          font-weight: normal;
          padding: 2px 10px;
          border-radius: 3px;
        }
      }
      h5 {
        line-height: normal;
        margin: 2px 0;
      }
    }
  }
}

.btn:disabled {
  pointer-events: none !important;
}

.login-content {
  .mat-form-field {
    .mat-form-field-label {
      .mat-placeholder-required {
        display: none;
      }
    }
  }
}

.form_row .input_fld::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  opacity: 0.4;
  /* Firefox */
}

.form_row .input_fld:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  opacity: 0.4;
}

.form_row .input_fld::-ms-input-placeholder {
  /* Microsoft Edge */
  opacity: 0.4;
}

.ng-nouter {
  width: 500px;
  height: 500px;
  display: block;
  overflow: hidden;
}

.mat-menu-panel.left-sub-menu {
  min-width: 8rem;
  max-width: 17.142857142857142rem;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  max-height: calc(100vh - 5.714rem);
  border-radius: 4px;
  outline: 0;
  min-height: 4.571rem;
  width: 100%;
  left: 8.57rem;
  top: 5.714rem;
  position: fixed;
  height: calc(100vh - 5.714rem);
  border-radius: 0;
  box-shadow: 15.97px 5.81px 42px 0 rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 15.97px 5.81px 42px 0 rgba(0, 0, 0, 0.07);
  padding: 0rem 0.5rem;
  z-index: 1111 !important;
  @include breakpoint(large) {
    left: 21.57rem;
  }
  .sub-menu-trigger {
    height: 100%;
  }
  .mat-menu-content {
    height: 100%;
  }
  .mat-list-item {
    padding: 0.8rem 0;
    width: 100%;
    border-left: 0;
    font-size: 1rem;
    color: $d_black;
    font-weight: 500;
    &:hover {
      background-color: $gray-400;
      span {
        color: $green2;
      }
    }
    span.material-icons {
      width: auto;
      font-size: 1.714rem;
      padding-right: 1rem;
    }
    span {
      color: $d_black;
    }
    .mat-list-item-content {
      width: 100%;
      @include flexbox;
      @include align-items(center);
      flex-direction: row;
      box-sizing: border-box;
      padding: 0 16px;
      position: relative;
      height: inherit;
    }
  }
}

.cdk-overlay-container:empty {
  display: none;
}

.more-user-added {
  @include flexbox;
  @include align-items(center);
  .img-area:first-child {
    margin: 0;
  }
  .img-area {
    width: 2.714rem;
    height: 2.714rem;
    background: #f9f9f9;
    border-radius: 50%;
    margin-top: 0px;
    margin-right: 1.3rem;
    overflow: hidden;
    @include flexbox;
    @include justify-content(center);
    @include align-items(center);
    color: rgba(112, 112, 112, 0.5);
    font-size: 12px;
    margin: 0 0 0 -10px;
    border: 2px solid $white;
    img {
      max-width: inherit;
      max-height: 100%;
      min-height: 100%;
    }
  }
  span {
    margin: 0;
  }
  .number-text {
    opacity: 0.5;
    margin: 0 0 0 6px;
  }
}

.rating-box.rating-box-form-group {
  display: block;
  label {
    .number {
      margin: 0 0 0 2rem;
      font-weight: normal;
      opacity: 0.6;
    }
  }
}

.rating-box {
  @include flexbox;
  margin: 1rem 0;
  .rating-inner {
    position: relative;
    margin: 0 10px 0 0;
    input {
      position: absolute;
      left: 0;
      top: 0px;
      opacity: 0;
      z-index: 1;
      cursor: pointer;
      width: 100%;
      height: 100%;
    }
    input:checked + .star-icon {
      color: $white;
      background: $base2;
      opacity: 1;
    }
    &:hover {
      .star-icon {
        color: $white;
        background: $base2;
        opacity: 1;
      }
    }
    .star-icon {
      display: inline-block;
      width: 30px;
      height: 30px;
      background: transparent;
      border-radius: 3px;
      color: $text-color;
      cursor: pointer;
      opacity: 0.6;
      transition: all 0.3s;
      @include flexbox;
      @include justify-content(center);
      @include align-items(center);
      font-size: 20px;
    }
  }
}

.staff-listing {
  @include flexbox;
  flex-wrap: wrap;
  width: 100%;
  .staff-list {
    background: $white;
    border: 1px solid #f3f3f8;
    border-radius: 4px;
    padding: 1.5rem;
    margin: 0 1.5rem 1.5rem 0;
    min-width: calc(25% - 1.5rem);
    // max-width: calc(25% - 1.5rem);
    max-width: fit-content;
    @include breakpoint(large) {
      min-width: calc(33% - 1.5rem);
      // max-width: calc(33% - 1.5rem);
      max-width: fit-content;
    }
    @include breakpoint(small) {
      min-width: 100%;
      // max-width: 100%;
      max-width: fit-content;
      margin: 0 0rem 1.5rem 0;
    }
    .user-pic-name {
      padding: 0 0 1rem 0;
      border-bottom: 1px solid #f3f3f8;
      margin: 0 0 1rem 0;
    }
    .contact-info {
      label {
        line-height: 1.42rem;
        font-size: 1rem;
        font-weight: 500;
      }
      p {
        font-weight: 300;
        font-size: 1rem;
        color: $grey-text;
        @include flexbox;
        @include align-items(center);
        flex-wrap: wrap;
        white-space: nowrap;
        margin: 0;
        span {
          margin: 0 2rem 0 0;
          @include flexbox;
          @include align-items(center);
          .material-icons {
            font-size: 1.4rem;
          }
        }
      }
    }
  }
}

.green-tag {
  span {
    background: $base2;
    color: $white;
    font-size: 0.9rem;
    padding: 1px 10px;
    border-radius: 3px;
    margin: 0 1rem 0 0;
    @include flexbox;
    flex-wrap: wrap;
  }
}

.back-annual-plan {
  @include align-items(center);
  display: inline-flex;
  position: relative !important;
  margin: 1rem 0 0rem 0;
  z-index: 2;
  &.upgrade-icon{
    display: inherit !important;
    align-items: inherit;
    margin: -5px 0 0 -35px;
  }
  .material-icons {
    width: 32px;
    height: 32px;
    background: $grey-text-333;
    border-radius: 100%;
    color: $white;
    font-size: 1.4rem;
    margin: 0 1rem 0 0;
    cursor: pointer;
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
  }
  p {
    color: $white;
    margin: 0;
    span {
      color: $green-3;
    }
  }
}

.cs-stepper {
  position: relative;
  top: -30px;
  ul {
    margin: 0;
    padding: 0;
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    li {
      @include flexbox;
      @include align-items(center);
      @include justify-content(center);
      color: $white;
      opacity: 0.3;
      cursor: pointer;
      position: relative;
      &:after {
        content: "";
        background: #f5f5f5 0% 0% no-repeat padding-box;
        opacity: 0.3;
        width: 37px;
        height: 1px;
        margin: 0 0 0 1rem;
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
      &.active {
        color: $green-3;
        opacity: 1;
        .number {
          background: $green-3;
          color: $white;
        }
      }
      .number {
        background: #6d6d6d;
        width: 25px;
        height: 25px;
        color: $white;
        border-radius: 100%;
        margin: 0 1rem 0 0;
        @include flexbox;
        @include align-items(center);
        @include justify-content(center);
      }
    }
  }
}

.card-payment-detail-row {
  margin: 0;
  > div {
    padding: 0;
    .card-number {
      .mat-form-field-outline {
        .mat-form-field-outline-end {
          // border-right-color: transparent;
          border-radius: 0;
        }
      }
    }
    .card-date {
      .mat-form-field-outline {
        .mat-form-field-outline-start {
          border-radius: 0;
        }
        .mat-form-field-outline-end {
          // border-right-color: transparent;
          border-radius: 0;
        }
      }
    }
    .card-cvv {
      .mat-form-field-outline {
        .mat-form-field-outline-start {
          border-radius: 0;
        }
      }
    }
  }
}

.icon-with-label {
  margin: 4px 0 !important;
  .material-icons {
    font-size: 1.4rem;
  }
}

.name-with-like {
  @include flexbox;
  @include align-items(center);
  .like-ic {
    opacity: 0.5;
    .material-icons {
      font-size: 22px;
      @include flexbox;
      margin: 0 0 0 5px;
    }
    &.active {
      opacity: 1;
      color: $green-3;
    }
  }
}

.form-group {
  position: relative;
  margin-bottom: 1.5rem;
  .error-msg {
    position: relative;
    left: 0px;
    bottom: 0px !important;
    min-height: 21px;
    font-size: 0.9rem;
    line-height: normal;
    margin: 0rem 0 0;
    height: auto;
    clear: both;
    display: inline-block;
    width: 100%;
    color: $red_text;
  }
}

.error-msg {
  position: relative;
  left: 0px;
  bottom: 0px !important;
  min-height: 21px;
  font-size: 0.9rem;
  margin: 0rem 0 0;
  line-height: normal;
  height: auto;
  clear: both;
  display: inline-block;
  width: 100%;
  color: $red_text;
}

.chat-area {
  @include flexbox;
  @include justify-content(space-between);
  position: relative;
  width: 100%;
  height: calc(100vh - 40px);
  overflow: hidden;
  @include breakpoint(small) {
    flex-direction: column;
  }
  .chat-main-area {
    position: relative;
    width: 100%;
    background: $white;
    box-shadow: 0px 1px 2px #00000026;
    border-radius: 3px;
  }
  .chat-contract-info {
    min-width: 22.857rem;
    margin: 0 0 0 2.142rem;
    position: relative;
    @include breakpoint(small) {
      margin: 2rem 0;
    }
    h3 {
      font-weight: 600;
      margin: 0 0 2rem 0;
    }
  }
}

.chat-contract-listing {
  position: relative;
  width: 100%;
  .chat-contract-listing-inner {
    @include flexbox;
    @include justify-content(space-between);
    margin: 0 0 10px 0;
    label {
      margin: 0;
      font-weight: 600;
    }
    span {
      background: $green;
      color: $white;
      border-radius: 11px;
      padding: 3px 10px;
      height: 21px;
      font-size: 12px;
      @include flexbox;
      @include align-items(center);
    }
  }
}

.chat-header {
  @include flexbox;
  @include align-items(center);
  padding: 1rem 2rem;
  background: $gray-400;
  border: 1px solid #efefef;
  border-radius: 3px;
  @include breakpoint(small) {
    flex-direction: column;
    @include align-items(flex-start);
  }
  .back-area {
    cursor: pointer;
    margin: 0 1rem 0 0;
    @include breakpoint(small) {
      position: absolute;
      left: 18px;
      top: 26px;
      margin: 0;
    }
    span {
      color: $green;
    }
  }
  .user-pic-name {
    flex: 1;
    min-width: 14.285rem;
    @include breakpoint(small) {
      padding: 0 0 0 1.428rem;
      width: 100%;
    }
    .user-images {
      width: 3.85rem;
      height: 3.85rem;
    }
  }
  .form_row {
    max-width: 350px;
    margin: 0px 0 0 2rem;
    @include breakpoint(small) {
      max-width: 100%;
      margin: 1rem 0 0;
    }
  }
}

.chat-inner-box {
  position: relative;
  padding: 2rem;
  max-height: calc(100% - 182px);
  overflow: auto;
  margin-bottom: 0;
  @include breakpoint(small) {
    max-height: 400px;
  }
  .chat-date {
    width: 100%;
    position: relative;
    text-align: center;
    margin: 1rem 0;
    span {
      display: inline-block;
      background: $white;
      z-index: 1;
      padding: 0 20px;
      position: relative;
      color: $grey-text;
      font-size: 12px;
    }
    &:after {
      width: 100%;
      height: 1px;
      content: "";
      position: absolute;
      background: #d6d6d6;
      left: 0px;
      top: 10px;
      opacity: 0.5;
    }
  }
}

.user-chat {
  position: relative;
  margin: 0 0 2rem 0;
  .user-pic-name {
    .user-images {
      width: 3.5rem;
      height: 3.5rem;
    }
    .name {
      margin: 0 0 0.5rem 0;
      display: block;
    }
  }
  p {
    color: $light-grey;
    margin: 0;
  }
  .chat-time {
    font-size: 12px;
    color: $grey-text;
    position: absolute;
    right: 0px;
    top: 2.142rem;
  }
}

.chat-footer {
  @include flexbox;
  @include align-items(center);
  position: absolute;
  width: 100%;
  background: $chat-bg;
  border: 1px solid #eee8e8;
  border-bottom: 0;
  overflow: hidden;
  padding: 1.5rem;
  bottom: 0;
  left: 0;
  @include breakpoint(small) {
    position: relative;
  }
  .attch-ic {
    cursor: pointer;
    margin: 0 1rem 0 0;
    .material-icons {
      color: $grey-text;
    }
  }
  .mat-form-field {
    background: $white;
  }
  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin: 0;
    padding: 0px;
  }
  .text-with-action-ic {
    position: relative;
    width: 100%;
  }
  .mat-form-field-subscript-wrapper {
    display: none;
  }
  .btn {
    margin: 0 0 0 1rem;
    min-width: 9.285rem;
    height: 4rem;
  }
  .text-right-icon {
    position: absolute;
    right: 1.071rem;
    top: 1.285rem;
    @include flexbox;
    @include align-items(center);
    background: $white;
    @include breakpoint(xxxlarge) {
      top: 1rem;
    }
    .material-icons {
      color: $grey-text;
      margin: 0 0 0 1rem;
      position: relative;
      z-index: 1;
      cursor: pointer;
    }
  }
}

.route-address {
  max-width: 17.857rem !important;
  min-width: 17.857rem !important;
}

.create-question-area {
  background: #f6f8fa;
  display: block;
  position: relative;
  padding: 1.071rem;
  max-height: 300px;
  overflow: auto;
  .create-question-label {
    margin: 0px 0px 15px 0px;
    label {
      font-weight: 500;
    }
    .mat-checkbox {
      border-left: 1px solid #d3d6d9;
      margin: 0px 0px 0px 15px;
      padding: 0px 0px 0px 15px;
    }
  }
}

.card_details {
  float: left;
  width: 100%;
  @include flexbox;
  @include justify-content(space-between);
  border: solid 1px #7b7d7f;
  @include border-radius(3px);
  overflow: hidden;
  margin-bottom: 15px;
  background-color: $white;
  @include breakpoint(small) {
    display: block;
  }
  .card_input {
    height: 50px;
    padding: 10px 15px;
    width: 100%;
    border: none;
    font-size: 1.071rem;
    font-weight: 400;
    color: $title-color;
    @include placeholder {
      color: #111;
    }
    @include breakpoint(small) {
      height: 40px;
    }
  }
  .card_number {
    flex-basis: 50%;
    border-right: solid 1px #7b7d7f;
    overflow: hidden;
    @include breakpoint(small) {
      flex-basis: 100%;
      border-right: none;
      border-bottom: solid 1px #7b7d7f;
    }
    .card {
      @include flexbox;
      @include align-items(center);
      padding-left: 15px;
      .fa-credit-card {
        color: $orange;
      }
    }
  }
  .card_validity {
    flex-basis: 30%;
    border-right: solid 1px #7b7d7f;
    @include breakpoint(small) {
      flex-basis: 100%;
      border-right: none;
      border-bottom: solid 1px #7b7d7f;
    }
  }
  .card_cvv {
    flex-basis: 20%;
    @include breakpoint(small) {
      flex-basis: 100%;
      border-right: none;
    }
  }
}

.select-staff-branch .person-info-section.check-person-radio {
  @include justify-content(flex-start);
}

.action-dropdown {
  .mat-menu-item:hover {
    span {
      color: $base2 !important;
    }
    svg {
      fill: $base2 !important;
    }
  }
}
.mat-drawer-side.sidenav-list .mat-nav-list a .mat-list-item-content {
  justify-content: center;
}
@include breakpoint(xxxlarge) {
  html {
    font-size: 12px;
  }
  body {
    .login {
      .container-inner {
        margin-bottom: 4rem !important;
      }
      .login-content {
        padding: 3rem !important;
        .mat-dialog-actions {
          padding-bottom: 0 !important;
        }
        > form {
          max-width: 100%;
        }
      }
    }
    .btn.large_btn {
      height: 3.4rem;
      font-size: 1.1rem;
    }
    .mat-form-field-appearance-outline .mat-form-field-infix {
      padding: 0.5em 0 0.5em 0;
    }
    .select-account-top {
      margin-top: -2rem;
    }
    .type-outer label .label-content h2 {
      font-size: 1.2rem;
    }
    .login .heading {
      font-size: 1.4rem;
    }
    .mat-drawer-side.sidenav-list .mat-nav-list a {
      padding: 0.9rem 0;
    }
    .mat-drawer-side.sidenav-list .mat-nav-list a .mat-list-item-content {
      padding: 0 5px;
      justify-content: center;
    }
    @media only screen and (max-width: 1024px) {
      .mat-drawer-side.sidenav-list .mat-nav-list a .mat-list-item-content {
        padding: 0 5px;
        justify-content: flex-start;
      }
    }
    .mat-drawer-side.sidenav-list .mat-nav-list a .material-icons {
      font-size: 18px;
    }
    .sidenav-list {
      max-width: 7rem;
    }
    .mat-menu-panel.left-sub-menu {
      left: 7rem;
      top: 4rem;
      max-height: calc(100vh - 4rem);
    }
    .mat-drawer-content.mat-sidenav-content {
      margin: 0 0 0 7rem !important;
    }
    &.mat-typography h2 {
      font-size: 1.2rem;
      line-height: 2rem;
    }
    h1,
    .mat-typography h1.heading1 {
      font-size: 1.4rem !important;
    }
    .right_sec .main_inner {
      padding: 0rem 15px;
    }
    .top_nav.super-admin-header {
      height: 4rem;
    }
    .btn.regular_btn {
      height: 2.6rem;
      font-size: 1.142rem !important;
    }
    .page_header_area {
      margin: 1rem 0 !important;
    }
    .table-main-view .mat-cell {
      height: 3rem;
      padding: 0.3rem 1.071rem;
    }
    mat-row,
    mat-footer-row {
      min-height: 3rem;
    }
  }
  body .mat-form-field-appearance-outline .mat-form-field-label {
    top: 2rem;
    margin: 0;
    height: auto;
    line-height: normal;
    display: flex;
    align-items: center;
  }
  body
    .login-content-wrapper.registration-area
    .file-input-field
    .choose-input
    .choose-btn {
    height: 3.6rem;
  }
  .btn .rotate-right.icon-right {
    position: relative;
    top: 0px;
  }
  body .btn.large_btn .start {
    position: relative;
    top: -1px;
  }
  body .form-group .error-msg {
    bottom: 7px;
  }
  body .mat-sidenav.sidenav-list .mat-nav-list a {
    font-size: 0.9rem;
  }
  body .mat-menu-panel.left-sub-menu .mat-list-item {
    font-size: 0.9rem;
  }
  .chat-footer {
    .text-with-action-ic {
    }
    .btn.large_btn {
      height: 3.4rem;
    }
  }
}

@include breakpoint(large) {
  // html {
  //     font-size: 13px;
  // }
  body .mat-drawer-content.mat-sidenav-content {
    margin: 0 0 0 0rem !important;
  }
  body .table-main-view .mat-cell {
    height: auto;
    padding: 1rem 1.071rem;
    min-height: 3.4rem;
  }
  body .mat-column-shipmentStatus {
    text-align: right !important;
  }
  body .action-cell {
    text-align: right !important;
  }
}

@include breakpoint(small) {
  html {
    font-size: 12.5px;
  }
  body
    .load-detail-services.load-detail-services-col
    .load-detail-services-list {
    min-width: 100%;
    max-width: 100%;
  }
  body .detail-grey-full .page_header_area {
    margin-top: 0 !important;
  }
  body h1,
  body .mat-typography h1.heading1 {
    font-size: 1.2rem !important;
  }
  body {
    .btn.large_btn {
      height: 4rem;
      font-size: 1.2rem;
      box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
    }
  }
}

.small-no-data {
  margin-top: 1.5rem;
  .no-data-come {
    margin: 0px 0px;
    img {
      max-width: 60px;
    }
    .heading2 {
      font-size: 0.9rem;
      margin-top: 1rem !important;
    }
    p {
      display: none;
    }
  }
}

.iti__country-list {
  flex-direction: column;
  @include flexbox;
}

.iti.iti--allow-dropdown {
  width: 100%;
}

.bg-grey-aaa {
  .btn-50 {
    .btn.min-width-btn {
      min-width: calc(50% - 5px);
      margin: 0 5px !important;
    }
  }
}

.account-setting {
  position: relative;
}

.mat-typography .heading-with-icon {
  font-size: 1.2rem;
  font-weight: 500;
  @include flexbox;
  @include align-items(center);
  position: relative;
  .material-icons {
    color: $base2;
    margin: 0 5px 0 0;
  }
}

.account-setting-inner {
  background: $white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  margin: 0 0 1.429rem 0;
  padding: 1rem 1.071rem;
  .account-link {
    @include flexbox;
    @include align-items(center);
    @include justify-content(space-between);
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }
    .material-icons {
      opacity: 0.6;
    }
    .arrow-right {
      cursor: pointer;
    }
  }
}

.full-circle-toggle {
  &.mat-checked {
    div.mat-slide-toggle-thumb-container {
      transform: translate3d(20px, 0, 0);
    }
  }
  div.mat-slide-toggle-thumb-container {
    width: 20px;
    height: 20px;
    top: 2px;
    left: 3px;
    .mat-slide-toggle-thumb {
      width: 100%;
      height: 100%;
    }
  }
}

.vechicle-info {
  @include flexbox;
  @include align-items(center);
  @include justify-content(space-between);
  // position: relative;
  // width: calc(100% + 20px);
  // border-bottom: 1px solid $border-color;
  padding: 10px 0;
  // line-height: normal;
  // margin: 0 -10px;
  .vechicle-left {
    @include flexbox;
    @include align-items(center);
    position: relative;
    .icon-area {
      margin: 0 6px 0 0;
    }
    .vehicle-name {
      @include flexbox;
      @include align-items(center);
    }
  }
  .vechicle-right {
    @include flexbox;
    @include align-items(center);
    @include justify-content(space-between);
    margin: 0 0 0 1rem;
    @include breakpoint(small) {
      margin: 0 0 0 0.5rem;
    }
    .vehicle-img {
      margin: 0 1rem 0 0;
      max-width: 10rem;
      min-width: 10rem;
      @include flexbox;
      @include justify-content(center);
      @include align-items(center);
      @include breakpoint(small) {
        max-width: 3rem;
        min-width: 3rem;
        margin: 0 0.5rem 0 0;
      }
    }
  }
  label {
    margin: 0;
    font-weight: 500;
    font-size: 1rem;
  }
  p {
    margin: 0;
    font-size: 1rem;
    color: $light-grey;
  }
  .small_btn {
    .material-icons {
      font-size: 1.2rem;
    }
  }
}

.dashboard-chart {
  max-height: 300px;
  max-width: 300px;
  // height: 250px;
  margin: 0px auto;
  @include breakpoint(xxxlarge) {
    max-height: inherit;
  }
}

.chatbox-modal {
  padding: 5.242rem 0;
  max-width: 1530px !important;
  width: 100%;
  margin-left: -14px !important;
  @include breakpoint(xxxlarge) {
    padding: 4.242rem 2.071rem;
    margin-left: 0 !important;
  }
  .chat-area {
    height: 100%;
  }
}

.chat-box-page {
  padding-top: 1rem !important;
  @include breakpoint(xxxlarge) {
    overflow: visible;
  }
  .chat-area {
    height: calc(100vh - 8.6rem);
    overflow: visible;
    @include breakpoint(xxxlarge) {
      height: calc(100vh - 6rem);
    }
    @include breakpoint(small) {
      min-height: 200px;
      height: auto;
    }
  }
}

app-root[aria-hidden="true"] {
  mat-sidenav-container {
    z-index: 5 !important;
  }
}

.heading-with-back-arrow {
  display: flex;
  align-items: center;
  width: 100%;
  text-align: center;
  position: relative;
  padding: 0 3.5rem;
  .back-annual-plan {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 1;
  }
}

.right-about-section {
  position: relative;
  top: -0.571rem;
}

.main-info-area {
  .number {
    font-size: 1rem;
  }
}

.load-detail-section-repeat {
  h4 {
    font-size: 1.071rem;
  }
}

.route-timeline-status-list {
  .mat-menu-trigger.material-icons {
    font-size: 1.114rem;
  }
}

body.mat-typography .bid-pop-heading {
  font-size: 1.142rem;
}

.table-outer-height {
  min-height: auto;
}

.right_sec {
  .main_inner {
    form {
      .row {
        clear: both;
      }
    }
  }
}

.listing-loader {
  min-height: 90px;
  align-items: center;
}

.ph-item > * {
  flex: 1 1 auto;
  display: flex;
}

.ph-item {
  @include flexbox;
  overflow: hidden;
}

.ph-item,
.ph-item *,
.ph-item ::after,
.ph-item ::before {
  position: relative;
  box-sizing: border-box;
}

.ph-picture {
  width: 4rem;
  height: 4rem;
  background-color: #efefef;
  border-radius: 5rem;
  flex-shrink: 0;
}

.ph-item::before {
  content: " ";
  position: absolute;
  top: 0;
  left: 50%;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 500%;
  margin-left: -250%;
  -webkit-animation: phAnimation 1.2s linear infinite;
  animation: phAnimation 1.2s linear infinite;
  overflow: hidden;
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 46%,
      rgba(255, 255, 255, 0.35) 50%,
      rgba(255, 255, 255, 0) 54%
    )
    50% 50%;
}

@keyframes phAnimation {
  0% {
    transform: translate3d(-30%, 0, 0);
  }
  100% {
    transform: translate3d(30%, 0, 0);
  }
}

.ph-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  width: 100%;
  align-items: center;
  padding-left: 3rem;
  padding: 0 0px 0 3rem;
  div {
    height: 10px;
    margin: 5px 0;
    background-color: #efefef;
  }
}

.loader-bg {
  width: 9.285rem;
  height: 3.571rem;
  background: $dark-base;
  border-radius: 5px;
  position: relative;
  margin: 3.571rem auto;
  z-index: 9999;
  display: flex;
  align-items: center;
  .circle-loader {
    position: relative;
    top: 0.928rem;
    left: 2.142rem;
    transform: translate(-50%, -50%);
    width: 1.714rem;
    height: 1.714rem;
    border-radius: 50%;
    border: 2px solid $dark-base;
    border-top: 2px solid $white;
    -webkit-animation: animate 1s infinite linear;
    animation: animate 1s infinite linear;
  }
  span {
    color: $white;
    padding-left: 2rem;
    font-weight: normal;
    font-size: 0.9rem;
  }
}

@keyframes animate {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.active-lead-iframe {
  agm-map {
    height: 28rem;
    @include breakpoint(xxxlarge) {
      height: 24rem;
    }
    @include breakpoint(small) {
      height: 20rem;
    }
  }
}

.document-limit {
  max-width: 14.285rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.password-input {
  .password-icon {
    position: absolute;
    right: 3px;
    top: -3px;
    bottom: 0px;
    margin: auto;
    width: 30px;
    height: 30px;
    @include flexbox;
    @include justify-content(center);
    @include align-items(center);
    z-index: 2;
  }
}

.profile-box {
  margin-top: 5rem;
  .profile-inner-info {
    text-align: center;
    .img-area {
      width: 7.142rem;
      height: 7.142rem;
      border-radius: 100%;
      overflow: hidden;
      position: relative;
      @include flexbox;
      @include justify-content(center);
      @include align-items(center);
      margin: -4.5rem auto 1rem auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
    h3 {
      margin: 0 0 0;
      font-weight: 500;
    }
    p {
      color: $grey-text;
    }
    .rating-box {
      @include justify-content(center);
      @include align-items(center);
      margin: 0;
      span {
        color: $grey-text;
        font-size: 1.4rem;
        &.active {
          color: $green2;
        }
      }
      .black-text {
        color: $black;
        margin: 0 0 0 4px;
      }
    }
  }
}

.profile-contact-box {
  margin: 1rem 0;
  .profile-contact-inner {
    @include flexbox;
    @include justify-content(space-between);
    margin: 0;
    border-bottom: 1px solid $light-border-color;
    padding: 1rem 0;
    &:last-child {
      border-bottom: 0;
    }
    label {
      font-weight: 600;
      font-size: 1.1rem;
      color: $black;
      margin: 0 5px 0 0;
    }
    span {
      font-size: 1.1rem;
      color: $grey-text;
    }
  }
}

.profile-manage-btn {
  max-width: 100%;
  text-align: center;
  clear: both;
  margin: 2rem auto;
  display: inline-block;
  width: 100%;
  .btn {
    width: 100%;
    max-width: 220px;
    height: 3rem;
    .icon-left {
      margin: 0 10px 0 0;
    }
    &:disabled {
      opacity: 0.3;
    }
  }
}

@include breakpoint(small) {
  body {
    .box_shodow {
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
    }
  }
}

.disabled-form {
  pointer-events: none;
  opacity: 0.5;
}

.border-hide {
  .input_fld {
    border-color: transparent;
  }
}

.edit-profile-img {
  position: relative;
  .pick-img {
    position: absolute;
    right: -60px;
    left: 0;
    margin: auto;
    bottom: -3px;
    background: $green2;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    border-radius: 100%;
    cursor: pointer;
    span {
      font-size: 1.4rem;
    }
    input {
      position: absolute;
      top: 0px;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      cursor: pointer;
      opacity: 0;
    }
  }
}

.two-btn-sections {
  padding: 0 5px;
  button {
    margin: 0 5px;
  }
}

.text-grey-medium {
  word-break: break-word;
}

.fix-chat-area {
  width: 3.8rem;
  height: 3.8rem;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.2);
  border-radius: 100%;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  cursor: pointer;
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);
  z-index: 99;
  background: $white;
  .material-icons {
    color: $green;
    font-size: 1.8rem;
  }
  .msg-count {
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    right: -3px;
    top: -6px;
    background: $red;
    border-radius: 100%;
    color: $white;
    font-size: 0.9rem;
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
  }
}

.collapse-icon {
  position: relative !important;
  left: 0px;
}

.main_inner_over {
  overflow: hidden;
}

.form-group
  .mat-form-field-appearance-outline
  .mat-form-field-subscript-wrapper {
  display: none;
}

.mat-select-panel {
  &.mat-select-search-panel {
    padding-top: 1.2rem !important;
  }
}

.add-area {
  position: relative;
  width: 100%;
  padding: 1.5rem;
  .edit-area {
    @include flexbox;
    @include align-items(center);
    font-size: 14px;
    position: absolute;
    right: 0;
    cursor: pointer;
    z-index: 1;
    top: -31px;
  }
}

.add-mutliple-doc {
  @include flexbox;
  @include flex-wrap(wrap);
  .added-files-tag {
    margin: 2px 5px 2px 0 !important;
    width: fit-content;
  }
}

.mat-select-search-no-entries-found {
  padding: 0px !important;
  position: relative;
  top: -8px;
  left: 15px;
  font-size: 0.9rem;
}

.remove-rating-cursor {
  star-rating {
    > div {
      cursor: auto !important;
    }
  }
  .star {
    cursor: auto !important;
  }
}
.right-side {
  float: right;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .d-flex {
    //  display: block!important;
  }
}

.search {
  position: relative;
  float: right;
  min-height: 2rem;
  width: 125px;
  border: 1px solid #80ff00;
  border-top: none;
  border-right: none;
  border-left: none;
  box-shadow: 0px 1px;
  position: relative;
  text-align: left;
}
.mat-input-element {
  font: inherit;
  background: transparent;
  color: currentColor;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: 100%;
  vertical-align: bottom;
  text-align: inherit;
}

//rfp badge

.rfp-badge-parent {
  position: relative;

  .rfp-badge {
    font-size: 10px;
    background: #d5c6c6;
    line-height: 1.5;
    transform: rotate(-20%);
    left: -34px;
    top: -16px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(-45deg);
    position: absolute;
    display: block;
    width: 59px;
    padding: 0;
    background-color: #ff0000;
    box-shadow: 0 3px 10px rgb(0 0 0 / 19%);
    color: #fff;
    text-shadow: 0 1px 1px rgb(0 0 0 / 20%);
    text-transform: uppercase;
    text-align: center;
  }
}

@media only screen and (max-width: 1368px) {
  .rfp-badge-parent {
    .rfp-badge {
      font-size: 8px;
      left: -33px;
      top: -10px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .rfp-badge-parent {
    .rfp-badge {
      font-size: 10px;
      left: -34px;
      top: -16px;
    }
  }
}

@media only screen and (max-width: 960px) {
  .rfp-badge-parent {
    .rfp-badge {
      right: -29px;
      top: -6px;
      left: auto;
      transform: rotate(45deg);
    }
  }
}

// dashboard-updated code

.edit-info-wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.vechicle-buton-wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 1rem 1rem;
  align-items: center;
  border-bottom: 1px solid $border-color;
}

.profile-box-right {
  width: 100%;
}

.fit-content-block {
  max-width: fit-content;
  white-space: nowrap !important;
  text-overflow: ellipsis;
  overflow: hidden;
}

.panel-header-area .panel-header-area-left {
  flex-wrap: wrap;
}

.rfp-right-btn {
  position: absolute;
  right: 2rem;
}

.user-image-detail {
  overflow: hidden;
}

@media only screen and (max-width: 960px) {
  .more-user-added {
    justify-content: flex-end;
  }

  .action-cell .action-icon-dots {
    justify-content: flex-end;
  }
}

//create account new css

.center-radio {
  text-align: center;
}

.login-content-wrapper.registration-area .mat-select {
  height: 4rem !important;
}

@media only screen and (max-width: 1368px) {
  .login-content-wrapper.registration-area .mat-select {
    height: 3.2rem !important;
  }
}

@media only screen and (max-width: 767px) {
  .input_block.select-border-box-outer {
    margin-left: 0rem;
  }
  .back-annual-heading{
    flex-wrap: wrap;
    justify-content: center;
  }
  .back-annual-plan.upgrade-icon{
    margin: 0 0 15px 0;
  }
}

.phn-code {
  .mat-select-trigger {
    padding-top: 5px;
  }
}

.contact-mail-wrap {
  display: flex;
  flex-wrap: wrap;
}

.upArrow {
  position: absolute;
  top: 41%;
  left: 5px;
  transform: rotate(180deg) !important;
  /* transform: translateY(-50%) !important; */
  border-top: solid 7px #ffffff;
  border-left: solid 6px transparent;
  border-right: solid 6px transparent;
  border-bottom: none;
}

.warning {
  font-size: 12px;
  color: #f90000a6;
}

.clearNotifications {
  // margin-left: 80px;
}

.readNotifications {
  background-color: #d3d3d37a;
}
.error-icon {
  font-size: 17px;
  color: #f90000a6;
}

.select-opt {
  .btn.small_btn {
    border-radius: 3px;
    height: 3rem;
  }
}
.filter-box {
  .filter-item {
    padding: 3px 10px;
    font-size: 12px;
    border: 0.6px solid #b8b8b8;
    box-shadow: 0px 10px 50px -20px rgb(0 0 0 / 10%);
    border-radius: 4px;
    margin-right: 7px;
    background-color: #fff;

    mat-icon {
      margin: 0;
      margin-left: 7px;
      height: 12px;
      width: 12px;
      font-size: 12px;

      &:hover {
        cursor: pointer;
      }
    }
  }
  mat-icon {
    height: 14px;
    width: 14px;
    font-size: 14px;
    margin-right: 7px;
  }
}

/*****tab-view *****/
.tab-list-navigation{
  float:left!important;
}
.view-type {
  margin-top: 5px;
  .mat-icon {
    font-size: 28px !important;
    height: 28px !important;
    width: 28px !important;
  }
}
.tabs-view {
  min-height:70vh;
  mat-card {
    box-shadow: 0px 0 22px rgb(0 0 0 / 5%) !important;
    color: #555555;
    .rfp-badge-parent {
      overflow: hidden;
      .rfp-badge {
        left: -18px;
        top: 5px;
      }
    }
    agm-map {
      height: 200px;
    }

    &:hover {
      box-shadow: 0px 0 22px rgb(0 0 0 / 11%) !important;
    }
    .mat-icon {
      height: 18px;
      width: 18px;
      font-size: 18px;
      line-height: 18px;
      color: #000000de;
    }

    .track {
      background: #f3f3f8;
      padding: 5px 15px;
      &.box_shodow {
        box-shadow: 0px 1px 2px rgb(224 224 224);
      }

      p {
        margin: 0;
        font-size: 13px;
        padding: 0px 4px;
      }
    }
    .linkid {
      border-bottom: 1px solid #e0e0e0;
      padding: 10px 15px 10px 22px;

      .linkid-name {
        font-size: 13px;
        img {
          width: 35px;
          height: 35px;
          border-radius: 50%;
          object-fit: cover;
        }
      }
      .mat-icon-button {
        width: auto !important;
        height: auto !important;
        line-height: 20px;
      }
    }

    .update-time {
      padding: 5px 15px;
      p {
        margin: 0;
        font-size: 13px;
      }
    }
    .link-details {
      padding: 10px 15px;
      p {
        margin: 0;
        font-size: 15px;
      }
      h5 {
        margin: 0;
        font-size: 13px;
        color: #0f0f11;
        font-weight: bold;
        line-height: 1.7;
      }
      .col-sm-6 {
        margin: 7px 0px;
      }
    }
  }
}

.map-wrapper{
  position: relative;
  .spinner{
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    justify-content: center;
    align-items: center;
    filter: blur(3x);
    -webkit-filter: blur(3x);
    z-index: 1;
    background: #ffffffbd;
    position: absolute;
    margin:0!important;
    
    
  }
  .loader {
    border: 2px solid #0b71e8;
    border-top: 2px solid #fff;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    animation: spin 1s linear infinite;
    z-index: 2;
    position: absolute;
    top:45%;
    left:45%;
    transform:translate(-50%,-50%);
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


@media only screen and (max-width: 1700px) {
  .load-item {
    flex: 0 0 50% !important;
    max-width: 50% !important;

    .white_box {
      background-color: #fff !important;
    }
  }
}

@media only screen and (max-width: 1366px) {
  .tabs-view{
    .col-xl-3{
     // flex: 0 0 33.333333%;
    //  max-width: 33.333333%;  
    }
  }
  .info-item {
    margin-bottom: 30px !important;
    .sub-heading {
      margin-bottom: 6px !important;
      font-size: 11px !important;
    }
    .sub-info {
      font-size: 13px !important;
    }
  }
  .avatar {
    margin: 20px 0px !important;
  }
  .sidebarleft {
    li {
      padding: 20px 35px !important;
      .profile-head {
        font-size: 12px !important;
      }
      .profile-head-info {
        font-size: 13px !important;
      }
    }
  }
  .profile-details {
    padding: 30px !important;
    min-height: 97% !important;
  }
}
.profile-details-heading{
    border-bottom: 2px solid #3ecb3e;
    padding-bottom: 10px;
    font-size: 14px !important;
    font-weight: 500 !important;
    display: inline-block;
    margin-bottom: 25px !important;
}
@media only screen and (max-width: 1024px) {
  .doc-item {
    flex-direction: column;
    align-items: flex-start !important;
    .download {
      margin-top: 20px;
      a {
        font-size: 12px;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .sidebarleft {
    li {
      padding: 30px !important;
    }
  }
  .profile-details {
    padding: 30px !important;
    margin-bottom: 30px !important;
    min-height: auto !important;
  }
  .load-item {
    .mat-cell {
      padding: 5px 0px !important;
      height: auto !important;
      min-height: auto !important;
    }
    .mat-row {
      border-bottom: 1px solid #f0f0f0 !important;
      &:last-child {
        border: none !important;
      }
    }
  }

  .doc-size {
    padding: 20px 0px;
  }
  .download {
    margin-top: 0px !important;
  }

  .profile-box-ara {
    .profile-box-left {
      margin-top:0;
      .profile-img-area
        {
          height: auto;
        }
        .avatar{
          width: 100%;
          margin: 0px !important;
          img{
            width: 100%;
          }
        }
    }
  }
}
@media only screen and (max-width: 474px) {
  div.notification-menu {
    max-width: 375px !important;
    .mat-menu-content {
      width: 375px !important;
    }
  }
  .active-lead-iframe {
    padding: 15px !important;
    .select-opt {
      flex-direction: column;
      mat-form-field {
        margin-bottom: 10px;
        width: 100% !important;
      }
    }
  }
}

@media only screen and (max-width: 425px) {
  .profile-details {
    padding: 30px 20px !important;
  }
  .load-item {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

@media only screen and (max-width: 390px) {
  div.notification-menu {
    max-width: 357px !important;
    .mat-menu-content {
      width: 357px !important;
    }
  }
  .profile-details {
    padding: 15px !important;
  }
  .load-item {
    padding: 0;
  }
}
@media only screen and (max-width: 360px) {
  div.notification-menu {
    max-width: 339px !important;
    .mat-menu-content {
      width: 339px !important;
    }
  }
}

@media only screen and (max-width: 320px) {
  div.notification-menu {
    max-width: 314px !important;
    .mat-menu-content {
      width: 314px !important;
    }
  }
}
// bottom right chat icon button and chat box
.bottom-btn{
  position: fixed;
  bottom: 40px;
  right: 40px;
  height: 60px;
  width: 60px;
  z-index: 9;
  background-color: $green2;
  padding: 18px 14px 14px 10px;
  border-radius: 50%;
  .mat-button.mat-button-base{
    min-width: 0;
    margin: 0 !important;
    padding: 0;
    .mat-button-wrapper{
      .material-icons{
        font-size: 29px;
        margin-bottom: 7px;
        margin-left: -3px;
      }
    }
  }
}
.message-box-heading{
  display: flex;
  align-items: center;
}
.messages-search{
  position: relative;
  float: right;
  min-height: 2rem;
  width: 175px;
  border: 1px solid #80ff00;
  border-top: none;
  border-right: none;
  border-left: none;
  box-shadow: 0px 1px;
  position: relative;
  text-align: left;
  .mat-input-element{
    padding-left: 24px;
    margin-top: 2px;
  }
  .mat-icon{
    position:absolute;
    left: 0;
    font-size: 1.5rem;
    top: 2px;
  }
}
.messages-heading{
  font-size: 2rem;
}
.d-block{
  display: block !important;
}
.message-sender{
  font-size: 1.1rem;
  font-weight: 800;
  color: #000000 !important;
}
.message-unchecked{
  font-size: 14px;
  font-weight: 500;
  color: #000000 !important;
}
.no-content{
  height: 250px;
  width: 100%;
  position: relative;
  .empty-text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    .empty-icon{
      height: 50px;
      width: 50px;
      margin: auto;
      font-size: 4rem;
      margin-bottom: 17px;
      color: #939393;
    }
  }
}
@media only screen and (max-width: 1400px){
  .bottom-btn{
    padding: 18px 14px 14px 13px;
  }
  .heading-with-back-arrow.upgrade-section{
    padding: 0 22px !important;
  }
}
@media only screen and (max-width: 1368px){
  .heading-with-back-arrow.upgrade-section{
    padding: 0 0px !important;
  }
}
@media only screen and (max-width: 768px) {
  .bottom-btn{
    width: 50px;
    height: 50px;
    padding: 8px 9px 10px 7px; 
    .mat-button.mat-button-base{
      .mat-button-wrapper{
        .material-icons{
          font-size: 2rem;
        }
      }
    }
  }
}
@media only screen and (max-width: 425px) {
  .bottom-btn{
    bottom: 13px;
    right: 13px;
  }
}
.bottom-chat-menu.mat-menu-panel.profile-dropdown{
  margin-bottom: 15px;
  overflow-y: auto !important;
}
.bottom-chat-menu.mat-menu-panel.profile-dropdown:before{
  top: inherit;
  bottom: -9px;
  right: 22px !important;
  transform: rotate(180deg);
}
.imager-wrapper {
  min-width: 50px;
  max-width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid #e9e9e9;
  img{
    object-fit: cover;
  }
}

.chat-msg-wrapper{
  max-height: calc(91vh - 210px);
  overflow: auto;
}
.badge.msg-badge{
  width: 18px;
  height: 18px;
  padding: 3px;
  font-weight: bold;
  font-size: 12px;
}
.notification-menu.bottom-chat-menu.no-scroll.profile-dropdown {
  margin-top: 30px !important;
  overflow-y: inherit !important;
  .mat-menu-content{
    width: 433px;
    min-height: inherit;
    max-height: inherit;
    // overflow-y: hidden;
  }
} 
@media only screen and (max-width: 320px){
  .posted-date{
    font-size: 12px;
  }
  .posted-date span.material-icons {
    font-size: 12px;
    margin-bottom: 18px;
}
}
@media only screen and (max-width: 425px){
  .posted-date span.material-icons {
    font-size: 14px;
    margin-bottom: 18px;
}
}