@use "sass:math";
// font size
@mixin fluid-font($min-vw, $max-vw, $min-font-size, $max-font-size) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-font-size);
    $u4: unit($max-font-size);
    @if $u1==$u2 and $u1==$u3 and $u1==$u4 {
        & {
            font-size: $min-font-size;
            @media screen and (min-width: $min-vw) {
                font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})) !important;
            }
            @media screen and (min-width: $max-vw) {
                font-size: $max-font-size !important;
            }
        }
    }
}

//Padding mixin
@mixin padding($top, $right, $bottom, $left) {
    padding-top: $top !important;
    padding-right: $right !important;
    padding-bottom: $bottom !important;
    padding-left: $left !important;
}

//Margin mixin
@mixin margin($top, $right, $bottom, $left) {
    margin-top: $top !important;
    margin-right: $right !important;
    margin-bottom: $bottom !important;
    margin-left: $left !important;
}


/* border-radius */

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius !important;
}


/* border-bottom-radius */

@mixin border-bottom-radius($radius) {
    -webkit-border-bottom-right-radius: $radius;
    border-bottom-right-radius: $radius;
    -webkit-border-bottom-left-radius: $radius;
    border-bottom-left-radius: $radius;
    background-clip: padding-box;
}


/* border-top-radius */

@mixin border-top-radius($radius) {
    -webkit-border-top-right-radius: $radius;
    border-top-right-radius: $radius;
    -webkit-border-top-left-radius: $radius;
    border-top-left-radius: $radius;
    background-clip: padding-box;
}


/* border-top-radius */

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}


/* Placeholder */

@mixin placeholder {
    input::-webkit-input-placeholder {
        @content;
    }
    input::-moz-placeholder {
        @content;
    }
    input::-moz-placeholder {
        @content;
    }
    input::-ms-input-placeholder {
        @content;
    }
}

@mixin placeholder-input {
     ::-webkit-input-placeholder {
        @content;
    }
     ::-moz-placeholder {
        @content;
    }
     ::-moz-placeholder {
        @content;
    }
     ::-ms-input-placeholder {
        @content;
    }
}


/* @include placeholder {
	  font-style:italic;
	  color: white;p
	  font-weight:100;
  } */


/* Box Sizing */

@mixin box-sizing($box-model) {
    -webkit-box-sizing: $box-model; // Safari <= 5
    -moz-box-sizing: $box-model; // Firefox <= 19
    box-sizing: $box-model;
}


/* box-shadow */

@mixin box-shadow($box-shadow) {
    -webkit-box-shadow: $box-shadow;
    box-shadow: $box-shadow;
    -moz-box-shadow: $box-shadow;
}

@mixin text-shadow($text-shadow) {
    -webkit-text-shadow: $text-shadow;
    text-shadow: $text-shadow;
    -moz-text-shadow: $text-shadow;
}


/* breakpoints */

@mixin breakpoint($point) {
    @if $point==xxxxxlarge {
        @media (max-width: 1600px) {
            @content;
        }
        /* 1600 px*/
    }
    @if $point==xxxxlarge {
        @media (max-width: 1400px) {
            @content;
        }
        /* 1400 px*/
    }
    @if $point==xxxlarge {
        @media (max-width: 1368px) {
            @content;
        }
        /* 1368 px*/
    }
    @if $point==xxlarge {
        @media (max-width: 1300px) {
            @content;
        }
        /* 1242 px*/
    }
    @if $point==xlarge {
        @media (max-width: 1110px) {
            @content;
        }
        /* 1110 px*/
    }
    @if $point==large {
        @media (max-width: 1024px) {
            @content;
        }
        /* 1024 px*/
    }
    @else if $point==medium {
        @media (max-width: 991px) {
            @content;
        }
        /* 991 px*/
    }
    @else if $point==small {
        @media (max-width: 767px) {
            @content;
        }
        /* 767 px*/
    }
    @else if $point==x-small {
        @media (max-width: 520px) {
            @content;
        }
        /* 480 px*/
    }
}


/* Retina */

@mixin image-2x($image, $width, $height) {
    @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
        background-image: url($image);
        /* on retina, use image that's scaled by 2 */
        background-size: $width $height;
        /*@include image-2x("url", 100px, 25px);*/
    }
}


/* print */

@mixin print {
    @media print {
        @content;
    }
}


/* transition */

@mixin transition($transition...) {
    -moz-transition: $transition;
    -o-transition: $transition;
    -webkit-transition: $transition;
    transition: $transition;
}

@mixin transition-property($property...) {
    -moz-transition-property: $property;
    -o-transition-property: $property;
    -webkit-transition-property: $property;
    transition-property: $property;
}

@mixin transition-duration($duration...) {
    -moz-transition-property: $duration;
    -o-transition-property: $duration;
    -webkit-transition-property: $duration;
    transition-property: $duration;
}

@mixin transition-timing-function($timing...) {
    -moz-transition-timing-function: $timing;
    -o-transition-timing-function: $timing;
    -webkit-transition-timing-function: $timing;
    transition-timing-function: $timing;
}

@mixin transition-delay($delay...) {
    -moz-transition-delay: $delay;
    -o-transition-delay: $delay;
    -webkit-transition-delay: $delay;
    transition-delay: $delay;
}


/* ===== transformation ===== */

@mixin transform($transforms) {
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms !important;
}


/* rotate */

@mixin rotate ($deg) {
    @include transform(rotate(#{$deg}deg));
}


/* scale */

@mixin scale($scale) {
    @include transform(scale($scale));
}


/* translate */

@mixin translate ($x, $y) {
    @include transform(translate($x, $y));
}

@mixin translate ($x) {
    @include transform(translateX($x));
}

@mixin translate ($y) {
    @include transform(translateY($y));
}


/* skew */

@mixin skew ($x, $y) {
    @include transform(skew(#{$x}deg, #{$y}deg));
}


/* transform origin */

@mixin transform-origin ($origin) {
    moz-transform-origin: $origin;
    -o-transform-origin: $origin;
    -ms-transform-origin: $origin;
    -webkit-transform-origin: $origin;
    transform-origin: $origin;
}


/* ===== transformation Ends ===== */


/* ===== Linear Gradients ===== */

@mixin linearGradient($top, $bottom) {
    background: $top;
    /* Old browsers */
    background: -moz-linear-gradient(top, $top 0%, $bottom 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $top), color-stop(100%, $bottom));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, $top 0%, $bottom 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, $top 0%, $bottom 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, $top 0%, $bottom 100%);
    /* IE10+ */
    background: linear-gradient(to bottom, $top 0%, $bottom 100%);
    /* W3C */
}


/* ===== Linear Gradients Ends ===== */


/* breakpoints */

@mixin breakpoint($point) {
    @if $point==xxxxxxxlarge {
        @media (max-width: 1700px) {
            @content;
        }
        /* 1700 px*/
    }
    @if $point==xxxxxxlarge {
        @media (max-width: 1600px) {
            @content;
        }
        /* 1600 px*/
    }
    @if $point==xxxxxlarge {
        @media (max-width: 1500px) {
            @content;
        }
        /* 1500 px*/
    }
    @if $point==xxxxlarge {
        @media (max-width: 1400px) {
            @content;
        }
        /* 1400 px*/
    }
    @if $point==xxxlarge {
        @media (max-width: 1368px) {
            @content;
        }
        /* 1368 px*/
    }
    @if $point==xxlarge {
        @media (max-width: 1280px) {
            @content;
        }
        /* 1280 px*/
    }
    @if $point==xlarge {
        @media (max-width: 1199px) {
            @content;
        }
        /* 1199 px*/
    }
    @if $point==large {
        @media (max-width: 1024px) {
            @content;
        }
        /* 1024 px*/
    }
    @else if $point==medium {
        @media (max-width: 991px) {
            @content;
        }
        /* 991 px*/
    }
    @else if $point==small {
        @media (max-width: 767px) {
            @content;
        }
        /* 767 px*/
    }
    @else if $point==x-small {
        @media (max-width: 480px) {
            @content;
        }
        /* 480 px*/
    }
}

@mixin grid-generator($size, $grid-columns) {
    @for $i from 1 through $grid-columns {
        .col-#{$size}-#{$i}-#{$grid-columns} {
            width: percentage(math.div($i, $grid-columns));
            float: left;
        }
    }
}

@mixin respond-from($media) {
    @if $media==xs {
        @media (min-width: 0px) {
            @content;
        }
    }
    @else if $media==sm {
        @media (min-width: 768px) {
            @content;
        }
    }
    @else if $media==md {
        @media (min-width: 1200) {
            @content;
        }
    }
    @else if $media==lg {
        @media (min-width: 1440) {
            @content;
        }
    }
    @else if $media==xl {
        @media (min-width: 1800) {
            @content;
        }
    }
}

@include grid-generator(xs, 12);
@include respond-from(sm) {
    @include grid-generator(sm, 12);
}

@include respond-from(md) {
    @include grid-generator(md, 12);
}

@include respond-from(lg) {
    @include grid-generator(lg, 12);
}

@include respond-from(xl) {
    @include grid-generator(xl, 10);
    @include grid-generator(xl, 12);
}

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}

@mixin justify-content($justify) {
    -webkit-justify-content: $justify;
    -moz-justify-content: $justify;
    -ms-justify-content: $justify;
    justify-content: $justify;
    -ms-flex-pack: $justify;
}

@mixin flex($values) {
    -moz-box-flex: $values;
    -webkit-flex: $values;
    -ms-flex: $values;
    flex: $values;
}

@mixin flex-wrap($wrap) {
    -webkit-flex-wrap: $wrap;
    -moz-flex-wrap: $wrap;
    -ms-flex-wrap: $wrap;
    flex-wrap: $wrap;
}

@mixin flexbox {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

@mixin align-items($properties) {
    @if $properties=='flex-start' {
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
    @else if $properties=='flex-end' {
        -webkit-align-items: flex-end;
        -ms-flex-align: end;
        align-items: flex-end;
    }
    @else if $properties=='center' {
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }
    @else if $properties=='baseline' {
        -webkit-align-items: baseline;
        -ms-flex-align: baseline;
        align-items: baseline;
    }
    @else if $properties=='stretch' {
        -webkit-align-items: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
    }
}