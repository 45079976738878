/* You can add global styles to this file, and also import other style files */

// @import './assets/styles/main.scss';
.bg-body {
    background-image: url("../src/assets/images/login-bg.svg");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: auto;
}
.img-area img{
    object-fit: cover;
}
.user_img img{
   object-fit: cover
}

@import "~ng-pick-datetime/assets/style/picker.min.css";
.action-dropdown {
    .mat-menu-content:not(:empty) {
        padding-top: 0;
        padding-bottom: 0;
    }
    .edit-button {
        img {
            width: 20px;
            margin-right: 12px;
        }
    }
    .delete-button {
        img {
            width: 14px;
            margin-right: 12px;
        }
    }
    .material-icons {
        margin-right: 12px;
        font-size: 20px;
        vertical-align: middle;
        color: rgba(0, 0, 0, 0.87) !important;
    }
    svg {
        margin-right: 12px;
    }
}
