* {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
}

html {
    position: relative;
    min-height: 100%;
}

body {
    height: 100%;
    background-color: $bg;
    font-family: $base-font;
    color: $d_black;
}

textarea {
    resize: none !important;
    ;
}

.md-container {
    max-width: 1176px;
    padding: 0px;
    margin: 0px auto;
    clear: both;
}

.row {
    margin: 0px -15px;
    .col {
        padding: 0px 15px;
    }
}

.white-section {
    background: $white !important;
}

.mat-raised-button {
    &.mat-dark-blue {
        background-color: $dark-blue;
        color: $white;
    }
}

.mat-raised-button {
    &.mat-dark-yellow {
        background-color: #EBD282;
        color: $white;
    }
}

.no-shadow {
    box-shadow: none !important;
}

.font-weight-light {
    font-weight: 300 !important;
}

.font-weight-regular {
    font-weight: 400 !important;
}

.font-weight-medium {
    font-weight: 500 !important;
}

input {
    outline: none;
}

ul {
    li {
        display: inline-block;
        margin-right: 15px;
        &:last-child {
            margin-right: 0px;
        }
    }
}

a,
button {
    cursor: pointer;
    outline: none;
    text-decoration: none;
    @include transition(all ease 0.3s);
    outline: none !important;
    &:hover,
    &:focus,
    &:active {
        cursor: pointer;
        outline: none;
        @include transition(all ease 0.3s);
    }
}

.clearfix {
    clear: both;
}

.w-auto {
    width: auto !important;
}

.w-100 {
    width: 100% !important;
}

.m-height-100 {
    min-height: 100%;
}

.no-border-radius {
    border-radius: 0px !important;
}

.h-vh {
    height: 100vh;
}

.white-text {
    color: $white !important;
}

.bg-grey {
    background: $light-grey;
}

.box-75 {
    width: 75px;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rounded-0 {
    @include border-radius(0 !important);
}

.rounded-circle {
    @include border-radius(50% !important);
}

.d-none {
    display: none !important;
}

.d-flex {
    display: -webkit-box!important;
    display: -ms-flexbox!important;
    display: flex!important;
}

.flex-column {
    -webkit-box-orient: vertical!important;
    -webkit-box-direction: normal!important;
    -ms-flex-direction: column!important;
    flex-direction: column!important;
}

.justify-content-start {
    -webkit-box-pack: start!important;
    -ms-flex-pack: start!important;
    justify-content: flex-start!important;
}

.justify-content-end {
    -webkit-box-pack: end!important;
    -ms-flex-pack: end!important;
    justify-content: flex-end!important;
}

.justify-content-center {
    -webkit-box-pack: center!important;
    -ms-flex-pack: center!important;
    justify-content: center!important;
}

.justify-content-between {
    -webkit-box-pack: justify!important;
    -ms-flex-pack: justify!important;
    justify-content: space-between!important;
}

.justify-content-around {
    -ms-flex-pack: distribute!important;
    justify-content: space-around!important;
}

.align-items-start {
    -webkit-box-align: start!important;
    -ms-flex-align: start!important;
    align-items: flex-start!important;
}

.align-items-end {
    -webkit-box-align: end!important;
    -ms-flex-align: end!important;
    align-items: flex-end!important;
}

.align-items-center {
    -webkit-box-align: center!important;
    -ms-flex-align: center!important;
    align-items: center!important;
}

.align-items-baseline {
    -webkit-box-align: baseline!important;
    -ms-flex-align: baseline!important;
    align-items: baseline!important;
}

.align-items-stretch {
    -webkit-box-align: stretch!important;
    -ms-flex-align: stretch!important;
    align-items: stretch!important;
}

.flex-wrap {
    -ms-flex-wrap: wrap!important;
    flex-wrap: wrap!important;
}

.flex-wrap-reverse {
    -ms-flex-wrap: wrap-reverse!important;
    flex-wrap: wrap-reverse!important;
}

.flex-nowrap {
    -ms-flex-wrap: nowrap!important;
    flex-wrap: nowrap!important;
}

.red_text {
    color: $red_text !important;
}

.font-normal {
    font-weight: normal !important;
}

.green_text {
    color: $green2 !important;
}

.yellow_text {
    color: $orange !important;
}

.blue_text {
    color: $d_blue !important;
}

.pink-bg {
    background: $pink !important;
}

.light-blue {
    background: $light-blue !important;
}

.green-bg {
    background: $base2 !important;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.pointer {
    cursor: pointer !important;
    &:focus {
        outline: none;
    }
}

.mx-220 {
    max-width: 220px !important;
}

.cs_row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    clear: both;
    .row {
        margin: 0px -15px;
    }
    &.cs_row_sm {
        margin-right: -10px;
        margin-left: -10px;
        .col {
            padding-right: 10px;
            padding-left: 10px;
        }
    }
    &.cs_row_xs {
        margin-right: -5px;
        margin-left: -5px;
        .col {
            padding-right: 5px;
            padding-left: 5px;
        }
    }
    .col-12 {
        flex: 0 0 100%;
        max-width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        @include breakpoint(small) {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .col-11 {
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
        padding-right: 15px;
        padding-left: 15px;
        @include breakpoint(small) {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .col-10 {
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
        padding-right: 15px;
        padding-left: 15px;
        @include breakpoint(small) {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .col-9 {
        flex: 0 0 75%;
        max-width: 75%;
        padding-right: 15px;
        padding-left: 15px;
        @include breakpoint(small) {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .col-8 {
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
        padding-right: 15px;
        padding-left: 15px;
        @include breakpoint(small) {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .col-7 {
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
        padding-right: 15px;
        padding-left: 15px;
        @include breakpoint(small) {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .col-6 {
        flex: 0 0 50%;
        max-width: 50%;
        padding-right: 15px;
        padding-left: 15px;
        @include breakpoint(small) {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .col-5 {
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
        padding-right: 15px;
        padding-left: 15px;
        @include breakpoint(small) {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .col-4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
        padding-right: 15px;
        padding-left: 15px;
        @include breakpoint(small) {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .col-3 {
        flex: 0 0 25%;
        max-width: 25%;
        padding-right: 15px;
        padding-left: 15px;
        @include breakpoint(small) {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .col-2 {
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
        padding-right: 15px;
        padding-left: 15px;
        @include breakpoint(small) {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .col-1 {
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
        padding-right: 15px;
        padding-left: 15px;
        @include breakpoint(small) {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}

.label {
    font-size: 1rem;
    font-weight: 500;
    color: $title-color;
    line-height: 18px;
    display: block;
    .info-icon {
        font-size: 20px;
        position: relative;
        top: 4px;
        margin: 0px 0px 0px 5px;
        color: $light-blue-color;
        white-space: normal;
    }
}

.text-label {
    color: $title-color;
    font-size: 1rem;
    font-weight: 400;
    display: block;
    @include breakpoint(small) {
        line-height: 18px;
    }
}

.cs_grid_two {
    .col {
        padding: 0px 15px;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        @include breakpoint(small) {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}

.cs_grid_three {
    .col {
        padding: 0px 15px;
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
        @include breakpoint(small) {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}

.cs_grid_four {
    .col {
        padding: 0px 15px;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
        @include breakpoint(small) {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}

.no-table-heading {
    .mat-header-row {
        display: none !important;
    }
}

.no-border {
    border: 0px !important;
}

.no-border-select {
    .mat-select {
        border-bottom: solid 1px $grey-text;
        border-radius: 0px;
        width: 100%;
        height: 44px;
        padding: 0px;
        color: $text-color;
        font-size: 1rem;
        font-family: "Roboto";
        outline: none !important;
        .mat-select-trigger {
            height: 100%;
            text-align: left;
            padding: 0px 0px 0px 0px;
            .mat-select-value {
                padding: 0px;
                text-align: left;
                height: 100%;
                vertical-align: middle;
                .mat-select-placeholder {
                    text-align: left;
                }
            }
        }
    }
}

mat-select.select-border-box.mat-select {
    padding: 0 15px;
    // height: auto;
}

.select-border-box.mat-select,
.chip-style {
    border: solid 1px rgba(173, 173, 173, .6);
    border-radius: 3px;
    width: 100%;
    height: 3rem;
    padding: 0px;
    color: $text-color;
    font-size: 1rem;
    font-family: "Roboto";
    background: $white;
    outline: none !important;
    &.small-select {
        height: 2.571rem;
    }
    &.large-select {
        height: 2.571rem;
    }
    &.selected-value {
        .mat-select-placeholder {
            color: #111;
        }
    }
    .mat-select-trigger {
        height: 100%;
        text-align: left;
        padding: 0px;
        .mat-select-value {
            padding: 0px;
            text-align: left;
            height: 100%;
            vertical-align: middle;
            display: flex;
            align-items: center;
            .mat-select-placeholder {
                text-align: left;
            }
        }
        .mat-select-placeholder::before {
            display: none;
        }
        .mat-select-arrow-wrapper {
            .mat-select-arrow {
                border: 0px !important;
                width: 22px;
                height: 18px;
                position: relative;
                top: -2px;
                margin: 0px;
                right: -10px;
                &:before {
                    content: "arrow_drop_down";
                    font-family: 'Material Icons';
                    font-weight: normal;
                    font-style: normal;
                    font-size: 22px;
                    line-height: 20px;
                    letter-spacing: normal;
                    text-transform: none;
                    display: inline-block;
                    white-space: nowrap;
                    word-wrap: normal;
                    direction: ltr;
                    -webkit-font-feature-settings: 'liga';
                    -webkit-font-smoothing: antialiased;
                    position: relative;
                    right: 24px;
                    color: $black;
                    background: none;
                    top: 2px;
                    opacity: 1;
                }
            }
        }
    }
}

.mat-radio-group {
    .mat-radio-button {
        &.mat-radio-checked {
            .mat-radio-container {
                .mat-radio-outer-circle {
                    border-color: $green2;
                }
                .mat-radio-inner-circle {
                    background: $green2;
                }
                .mat-radio-ripple .mat-radio-persistent-ripple {
                    background-color: $green2;
                }
            }
        }
    }
}

body .mat-radio-outer-circle {
    @include breakpoint(large) {
        width: 18px;
        height: 18px;
    }
}

body .mat-radio-inner-circle {
    @include breakpoint(large) {
        width: 18px;
        height: 18px;
    }
}

.date-input-field {
    .mat-form-field {
        .mat-form-field-flex {
            .mat-form-field-infix {
                border-top: 0px;
            }
            .mat-form-field-suffix {
                .mat-button-wrapper {
                    svg {
                        fill: $mute-blue-color;
                    }
                }
            }
        }
    }
    input {
        border: 0;
        padding: 14px 0;
        border-bottom: 1px solid #7f7f7f;
        width: 100%;
        font-size: 1rem;
        font-weight: 400;
        outline: none !important;
        color: $d_black;
        &:focus,
        &:hover {
            outline: none !important;
        }
    }
    .input-border {
        input {
            border-bottom: 1px solid #7f7f7f;
        }
        .mat-form-field-label {
            display: none;
        }
        .mat-datepicker-toggle {
            right: 6px;
            top: -32px;
        }
    }
    .mat-datepicker-toggle {
        position: absolute;
        right: 0px;
        top: -30px;
        .mat-icon-button {
            width: 30px;
            height: 30px;
            .mat-datepicker-toggle-default-icon {
                width: 24px;
            }
        }
    }
}

.ic_w_input {
    input {
        border: 0;
        padding: 14px 0;
        border-bottom: 1px solid #7f7f7f;
        width: 100%;
        font-size: 1.143rem;
        font-weight: 400;
        outline: none !important;
        &:focus,
        &:hover {
            outline: none !important;
        }
    }
    .mat-form-field-suffix {
        position: absolute;
        right: 4px;
        top: 27px;
        .mat-icon {
            color: #757575;
        }
    }
}

.mat-dialog-actions {
    margin-bottom: 0px !important;
    padding: 0px !important;
}

.cdk-overlay-dark-backdrop {
    background: rgba(0, 0, 0, 0.5) !important;
}

.owl-dt-calendar-table .owl-dt-calendar-cell-selected {
    background-color: $base1 !important;
}

.owl-dt-control .owl-dt-control-content {
    color: $black;
}

.common_date_time_input {
    .mat-form-field-infix {
        z-index: 2;
    }
    .mat-form-field-suffix {
        z-index: 1;
    }
}

.schedule-modal .modal_body {
    overflow: hidden;
}

.input-with-btn {
    .btn {
        min-width: 125px;
    }
}

.input-row-type {
    .inner-row-type {
        background: $row-type-bg;
        border: 1px solid $row-type-border;
        padding: 15px 75px 15px 20px;
        border-radius: 3px;
        overflow: hidden;
        position: relative;
        .row-type-text {
            color: $black;
            font-size: 1rem;
        }
        &:hover {
            .row-type-action {
                right: 5px;
                opacity: 1;
            }
        }
        .row-type-action {
            position: absolute;
            right: 0px;
            top: 0px;
            bottom: 0px;
            margin: auto;
            height: 20px;
            display: flex;
            align-items: center;
            right: -20px;
            opacity: 0;
            transition: all 0.3s;
            cursor: pointer;
            a {
                margin: 0px 0px 0px 10px;
                i {
                    font-size: 1.429rem;
                }
                &.edit-ic {
                    i {
                        color: $edit-color;
                    }
                }
                &.delete-ic {
                    i {
                        color: $delete_ic;
                    }
                }
            }
        }
    }
}

.btn.delete-btn {
    background: $delete_ic;
    color: $white;
}

.confirm-box {
    .icon {
        i {
            margin: 0px 0px 20px 0px;
            font-size: 4.286rem;
        }
    }
    h3 {
        font-size: 2rem;
    }
    .confirm-btn-action {
        align-content: center;
        justify-content: center;
        .btn {
            min-width: 200px;
        }
    }
}

.delete-box {
    .icon {
        i {
            margin: 0px 0px 20px 0px;
            font-size: 4.286rem;
        }
    }
}

.text_underline {
    text-decoration: underline !important;
}

.mat-menu-panel.cm-btn-action {
    box-shadow: 0px 12px 19.5px 0.2px rgba(0, 0, 0, 0.28);
    min-width: 180px;
    position: relative;
    &:before {
        content: "";
        position: absolute;
        top: -10px;
        right: 20px;
        border-bottom: solid 10px #c3c3c3;
        border-left: solid 10px transparent;
        border-right: solid 10px transparent;
    }
    &:after {
        content: '';
        position: absolute;
        top: -10px;
        right: 20px;
        border-bottom: solid 10px #fff;
        border-left: solid 10px transparent;
        border-right: solid 10px transparent;
    }
    .mat-menu-content {
        padding: 0px !important;
        .mat-menu-item {
            display: flex;
            align-items: center;
            color: $title-color;
            font-size: 1.071rem;
            height: auto;
            border: 0px;
            &:hover {
                background: #eef5fa;
            }
            i {
                color: #7f7d8b;
                margin: 0px 10px 0px 0px;
                font-size: 1.214rem;
            }
        }
    }
}

.mute-btn {
    opacity: 0.6;
}

.file-input-field {
    &.fill-added {
        .choose-input {
            &:hover {
                .choose-btn {
                    background: #fff;
                }
            }
            .click-text {
                display: none;
            }
            input[type=file] {
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                cursor: pointer;
            }
        }
    }
    .choose-input {
        display: flex;
        clear: both;
        position: relative;
        align-items: center;
        overflow: hidden;
        &.attached-doc {
            input[type=file] {
                width: 42px;
            }
        }
        &:hover {
            .choose-btn div {
                color: $text-color !important;
            }
        }
        .choose-btn div {
            color: $text-color !important;
        }
        &.error-choose-input {
            .choose-btn {
                border-color: $red_text !important;
                .material-icons {
                    background: $red_text !important;
                }
                .click-text {
                    color: $red_text !important;
                }
            }
        }
        cursor: pointer;
        &:hover {
            .choose-btn {
                border-color: #C9CAD5;
                background: #C9CAD5;
                color: $white;
            }
        }
        input[type="file"] {
            position: absolute;
            left: 0px;
            top: 0px;
            z-index: 1;
            opacity: 0;
            height: 100%;
            cursor: pointer;
        }
        .choose-btn {
            background: $white;
            border: 1px dashed #B7B7B7;
            margin: 0px 10px 0px 0px;
            padding: 8px 10px;
            color: $text-color;
            font-size: 1rem;
            @include transition(all ease 0.5s);
            border-radius: 3px;
            width: 100%;
            display: flex;
            align-items: center;
            .added-files-tag {
                display: inline-flex;
                background: $green2;
                border-radius: 2px;
                align-items: center;
                padding: 0px 30px 0 4px;
                min-height: 24px;
                margin: 0 5px 0 0;
                // max-width: calc(100% - 36px);
                max-width: fit-content;
                position: relative;
                cursor: pointer;
                z-index: 2;
                &:hover {
                    background: $hover_green !important;
                }
                span {
                    color: $white;
                    white-space: nowrap;
                    width: 100%;
                    text-overflow: ellipsis;
                    display: block;
                    overflow: hidden;
                }
                i {
                    font-size: 16px;
                    margin: 0 0 0 5px;
                    cursor: pointer;
                    position: absolute;
                    right: 4px;
                    color: $white !important;
                }
            }
        }
        span {
            font-size: 0.9rem;
            color: $grey-text;
            &.material-icons {
                background: #C9CAD5;
                height: 1.714rem;
                width: 1.714rem;
                min-width: 1.714rem;
                color: #fff;
                align-items: center;
                display: flex;
                justify-content: center;
                margin-right: 10px;
                font-size: 1.285rem;
                border-radius: 2px;
            }
            &.file-active {
                color: $text-color;
            }
        }
    }
}

.rect-spin-dot {
    position: relative;
    display: inline-block;
    font-size: 20px;
    width: 20px;
    height: 20px;
    i {
        width: 9px;
        height: 9px;
        border-radius: 100%;
        background-color: $base1;
        -webkit-transform: scale(0.75);
        -ms-transform: scale(0.75);
        transform: scale(0.75);
        display: block;
        position: absolute;
        opacity: 0.3;
        -webkit-animation: antSpinMove 1s infinite linear alternate;
        animation: antSpinMove 1s infinite linear alternate;
        -webkit-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
    }
    i:nth-child(1) {
        left: 0;
        top: 0;
    }
    i:nth-child(2) {
        right: 0;
        top: 0;
        -webkit-animation-delay: 0.4s;
        animation-delay: 0.4s;
    }
    i:nth-child(3) {
        right: 0;
        bottom: 0;
        -webkit-animation-delay: 0.8s;
        animation-delay: 0.8s;
    }
    i:nth-child(4) {
        left: 0;
        bottom: 0;
        -webkit-animation-delay: 1.2s;
        animation-delay: 1.2s;
    }
}

.rect-spin-dot-spin {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-animation: antRotate 1.2s infinite linear;
    animation: antRotate 1.2s infinite linear;
}

@-webkit-keyframes antRotate {
    to {
        -webkit-transform: rotate(405deg);
        transform: rotate(405deg);
    }
}

@keyframes antRotate {
    to {
        -webkit-transform: rotate(405deg);
        transform: rotate(405deg);
    }
}

@-webkit-keyframes antSpinMove {
    to {
        opacity: 1;
    }
}

@keyframes antSpinMove {
    to {
        opacity: 1;
    }
}

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialicons/v47/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}

.gradient_bg {
    background: rgba(30, 150, 210, 1);
    background: -moz-linear-gradient( -129deg, rgb(22, 96, 232) 0%, rgb(7, 13, 89) 100%);
    background: -webkit-linear-gradient( -129deg, rgb(22, 96, 232) 0%, rgb(7, 13, 89) 100%);
    background: -webkit-linear-gradient( -129deg, rgb(22, 96, 232) 0%, rgb(7, 13, 89) 100%);
    background: -o-linear-gradient( -129deg, rgb(22, 96, 232) 0%, rgb(7, 13, 89) 100%);
    background: -ms-linear-gradient( -129deg, rgb(22, 96, 232) 0%, rgb(7, 13, 89) 100%);
    background: linear-gradient( -129deg, rgb(22, 96, 232) 0%, rgb(7, 13, 89) 100%);
}

.gradient {
    background: rgba(30, 150, 210, 1);
    background: -moz-linear-gradient(left, rgba(30, 150, 210, 1) 0%, rgba(107, 39, 233, 1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(30, 150, 210, 1)), color-stop(100%, rgba(107, 39, 233, 1)));
    background: -webkit-linear-gradient(left, rgba(30, 150, 210, 1) 0%, rgba(107, 39, 233, 1) 100%);
    background: -o-linear-gradient(left, rgba(30, 150, 210, 1) 0%, rgba(107, 39, 233, 1) 100%);
    background: -ms-linear-gradient(left, rgba(30, 150, 210, 1) 0%, rgba(107, 39, 233, 1) 100%);
    background: linear-gradient(to right, rgba(30, 150, 210, 1) 0%, rgba(107, 39, 233, 1) 100%);
    color: $white;
    @include transition(all ease 0.5s);
    &:hover,
    &:focus,
    &:active {
        @include transition(all ease 0.5s);
        background: rgba(30, 150, 210, 1);
        background: -moz-linear-gradient(left, rgb(27, 136, 190) 0%, rgb(96, 35, 207) 100%);
        background: -webkit-gradient(left top, right top, color-stop(0%, rgb(27, 137, 191)), color-stop(100%, #6023cf));
        background: -webkit-linear-gradient(left, rgb(27, 136, 190) 0%, rgba(96, 35, 207, 1) 100%);
        background: -o-linear-gradient(left, rgb(27, 136, 190) 0%, rgba(96, 35, 207, 1) 100%);
        background: -ms-linear-gradient(left, rgb(27, 136, 190) 0%, rgba(96, 35, 207, 1) 100%);
        background: linear-gradient(to right, rgb(27, 136, 190) 0%, rgba(96, 35, 207, 1) 100%);
    }
}

.no-border {
    border: 0px !important;
}

.no-hover-bg {
    &:hover {
        background: transparent !important;
    }
}

.box_shodow {
    box-shadow: 0px 0 22px rgba(0, 0, 0, 0.11);
}

.box_shodow_section {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

.carousel {
    .carousel-list {
        transition: all 0.5s;
    }
}

span.mat-select-placeholder::before {
    display: none;
}

[tooltip] {
    position: relative;
    /* opinion 1 */
}


/* Applies to all tooltips */

[tooltip]::before,
[tooltip]::after {
    text-transform: none;
    /* opinion 2 */
    font-size: .7em;
    /* opinion 3 */
    line-height: 1;
    user-select: none;
    pointer-events: none;
    position: absolute;
    display: none;
    opacity: 0;
    font-family: 'Roboto', sans-serif;
}

[tooltip]::before {
    content: '';
    border: 8px solid transparent;
    /* opinion 4 */
    z-index: 1001;
    /* absurdity 1 */
}

[tooltip]::after {
    content: attr(tooltip);
    text-align: center;
    font-size: 0.9rem;
    min-width: 150px;
    max-width: 320px;
    padding: 10px;
    border-radius: .3ch;
    box-shadow: 0 1em 2em -0.5em rgba(0, 0, 0, 0.35);
    background: #191a1f;
    color: #fff;
    z-index: 1000;
}


/* Make the tooltips respond to hover */

[tooltip]:hover::before,
[tooltip]:hover::after {
    display: block;
}


/* don't show empty tooltips */

[tooltip='']::before,
[tooltip='']::after {
    display: none !important;
}


/* FLOW: UP */

[tooltip]:not([flow])::before,
[tooltip][flow^="up"]::before {
    bottom: 100%;
    border-bottom-width: 0;
    border-top-color: #333;
}

[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::after {
    bottom: calc(100% + 5px);
}

[tooltip]:not([flow])::before,
[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::before,
[tooltip][flow^="up"]::after {
    left: 50%;
    transform: translate(-50%, -.5em);
}


/* FLOW: DOWN */

[tooltip][flow^="down"]::before {
    top: 100%;
    border-top-width: 0;
    border-bottom-color: #333;
}

[tooltip][flow^="down"]::after {
    top: calc(100% + 5px);
}

[tooltip][flow^="down"]::before,
[tooltip][flow^="down"]::after {
    left: 50%;
    transform: translate(-50%, .5em);
}


/* FLOW: LEFT */

[tooltip][flow^="left"]::before {
    top: 50%;
    border-right-width: 0;
    border-left-color: #333;
    left: calc(0em - 5px);
    transform: translate(-.5em, -50%);
}

[tooltip][flow^="left"]::after {
    top: 50%;
    right: calc(100% + 5px);
    transform: translate(-.5em, -50%);
}


/* FLOW: RIGHT */

[tooltip][flow^="right"]::before {
    top: 50%;
    border-left-width: 0;
    border-right-color: #191a1f;
    right: calc(0em - 5px);
    transform: translate(.5em, -50%);
}

[tooltip][flow^="right"]::after {
    top: 50%;
    left: calc(100% + 5px);
    transform: translate(.5em, -50%);
}


/* KEYFRAMES */

@keyframes tooltips-vert {
    to {
        opacity: .9;
        transform: translate(-50%, 0);
    }
}

@keyframes tooltips-horz {
    to {
        opacity: .9;
        transform: translate(0, -50%);
    }
}


/* FX All The Things */

[tooltip]:not([flow]):hover::before,
[tooltip]:not([flow]):hover::after,
[tooltip][flow^="up"]:hover::before,
[tooltip][flow^="up"]:hover::after,
[tooltip][flow^="down"]:hover::before,
[tooltip][flow^="down"]:hover::after {
    animation: tooltips-vert 300ms ease-out forwards;
}

[tooltip][flow^="left"]:hover::before,
[tooltip][flow^="left"]:hover::after,
[tooltip][flow^="right"]:hover::before,
[tooltip][flow^="right"]:hover::after {
    animation: tooltips-horz 300ms ease-out forwards;
}

.backdrop {
    border-radius: 0px !important;
}

.heading-with-icon {
    display: flex;
    align-items: center;
    .icon-box {
        width: 2.857rem;
        height: 2.857rem;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $sky-base-1;
        margin: 0px 15px 0px 0px;
        i {
            color: $white;
            font-size: 1.714rem;
        }
    }
}

.full-shared-inner {
    max-width: 1600px;
    margin: 0px auto;
    padding: 0px 15px;
}

.spinner {
    stroke: $text-color;
    fill: $text-color;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 20px 0px;
    &.full-width-loader {
        position: fixed;
        top: 0px;
        z-index: 1111;
        height: 100%;
        background: rgba(255, 255, 255, 0.8);
        margin: 0;
    }
    svg {
        width: 30px !important;
        height: 30px !important;
        margin: 0px 10px 0px 0px;
    }
}

.spinner-loader-svg {
    animation: rotate 0.8s linear infinite;
    -webkit-animation: rotate 0.8s linear infinite;
    -moz-animation: rotate 0.8s linear infinite;
    width: 174px;
    height: 174px;
    position: relative;
}

@keyframes rotate {
    to {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes rotate {
    to {
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes rotate {
    to {
        transform: rotate(360deg);
    }
}

.spinner-loader-svg .path {
    stroke-dasharray: 170;
    stroke-dashoffset: 20;
}

.green-text {
    color: $green-3 !important;
}

.red-text {
    color: $red !important;
}

.blue-text {
    color: $base1 !important;
}

.danger-text {
    color: $delete_ic !important;
}

.yellow-text {
    color: $yellow !important;
}

.bt-0 {
    border-top: 0px !important;
}

.bl-0 {
    border-left: 0px !important;
}

.br-0 {
    border-right: 0px !important;
}

.bb-0 {
    border-bottom: 0px !important;
}

.p-relative {
    position: relative;
}

.mat-hint.mat-right {
    position: absolute;
    clear: both;
    bottom: -22px;
    right: 0px;
    font-size: 12px;
    color: #909090;
    opacity: 0.7;
}

.grid-margin {
    margin-bottom: 1.786rem !important;
}

.td-name {
    white-space: nowrap;
    font-weight: 500;
}

.first-info-cell {
    width: 60px !important;
    max-width: 60px !important;
    min-width: 60px !important;
}

.fl-loading {
    margin: 0px;
    position: fixed;
    z-index: 9999;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    .fl-loading-inner {
        width: 120px;
        height: 120px;
        margin: auto;
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
    }
    p {
        position: absolute;
        z-index: 99;
        color: black;
        width: 62px;
        overflow: hidden;
        animation: dots 2s steps(2) linear infinite;
        -webkit-animation: dots 2s steps(2) infinite;
        -moz-animation: dots 2s steps(2) linear infinite;
        -o-animation: dots 2s steps(2) linear infinite;
        top: 0px;
        bottom: 0px;
        margin: auto;
        height: 14px;
        left: 28px;
        font-size: 12px;
        text-transform: uppercase;
    }
    .fl-loading-rotate {
        height: 100%;
        margin: auto;
        border-right: 5px solid #D20000;
        border-bottom: 5px solid transparent;
        border-radius: 100%;
        animation: spin 0.9s linear infinite;
        -webkit-animation: spin 0.9s linear infinite;
        -moz-animation: spin 0.9s linear infinite;
        -o-animation: spin 0.9s linear infinite;
    }
}

@keyframes dots {
    0% {
        width: 50px;
    }
    50% {
        width: 56px;
    }
    100% {
        width: 62px;
    }
}

@-webkit-keyframes dots {
    0% {
        width: 50px;
    }
    50% {
        width: 56px;
    }
    100% {
        width: 62px;
    }
}

@-moz-keyframes dots {
    0% {
        width: 50px;
    }
    50% {
        width: 56px;
    }
    100% {
        width: 62px;
    }
}

@-o-keyframes dots {
    0% {
        width: 50px;
    }
    50% {
        width: 56px;
    }
    100% {
        width: 62px;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
        border-right-color: $base2;
    }
    50% {
        transform: rotate(180deg);
        border-right-color: $green-3;
    }
    100% {
        transform: rotate(359deg);
        border-right-color: $base2;
    }
}

@-webkit-keyframes spin {
    0% {
        transform: rotate(0deg);
        border-right-color: $base2;
    }
    50% {
        transform: rotate(180deg);
        border-right-color: $green-3;
    }
    100% {
        transform: rotate(359deg);
        border-right-color: $base2;
    }
}

@-moz-keyframes spin {
    0% {
        transform: rotate(0deg);
        border-right-color: $base2;
    }
    50% {
        transform: rotate(180deg);
        border-right-color: $green-3;
    }
    100% {
        transform: rotate(359deg);
        border-right-color: $base2;
    }
}

@-o-keyframes spin {
    0% {
        transform: rotate(0deg);
        border-right-color: $base2;
    }
    50% {
        transform: rotate(180deg);
        border-right-color: $green-3;
    }
    100% {
        transform: rotate(359deg);
        border-right-color: $base2;
    }
}

.search-loader {
    top: -5px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 02);
    padding: 50px;
    min-height: 300px;
    &.page-inner-loader {
        height: calc(100vh - 164px);
    }
}

.search-loader .rect {
    &,
    &:before,
    &:after {
        position: absolute;
        margin: auto;
        font-size: 10px;
        height: 1em;
        width: 1em;
        box-shadow: 0 1em $base2;
        animation-mode: forwards;
        -webkit-animation: pulse 1s infinite ease-in-out;
        animation: pulse 1s infinite ease-in-out;
        bottom: 0px;
        left: 0px;
        right: 0px;
        top: 0px;
    }
}

.search-loader .rect:before,
.search-loader .rect:after {
    content: "";
    display: block;
    position: absolute;
}

.search-loader .rect:before {
    transform: translateX(-1.5em);
    animation-delay: -.1s;
}

.search-loader .rect:after {
    transform: translateX(1.5em);
    animation-delay: .1s;
}

@keyframes pulse {
    25% {
        box-shadow: 0 1.65em #fff;
    }
    50% {
        box-shadow: 0 1em #fff;
    }
}

.p-text {
    font-size: 0.9286rem;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.create-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 15px 10px 10px;
    .create-header-left {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .create-header-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .go-back-btn {
        color: $black;
        display: flex;
        align-items: center;
        background: none;
        font-size: 1.1rem;
        i {
            background: $white;
            width: 44px;
            height: 44px;
            border-radius: 40px;
            text-align: center;
            display: flex;
            color: $black;
            align-items: center;
            justify-content: center;
            box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
            margin: 0px 10px 0px 0px;
            &:hover {
                background: $gray_bg;
            }
            @include breakpoint(large) {
                width: 38px;
                height: 38px;
                font-size: 22px;
            }
        }
    }
    button {
        &:hover {
            background: $gray_bg;
        }
    }
    .dehaze {
        background: $white;
        width: 64px;
        height: 44px;
        border-radius: 20px 0px 0px 20px;
        text-align: center;
        display: flex;
        color: $black;
        align-items: center;
        justify-content: center;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
        margin: 0px 8px 0px 0px;
        @include breakpoint(large) {
            width: 50px;
            height: 38px;
        }
        i {
            @include breakpoint(large) {
                font-size: 22px;
            }
        }
    }
    .close {
        background: $white;
        width: 64px;
        height: 44px;
        border-radius: 0px 20px 20px 0px;
        text-align: center;
        display: flex;
        color: $light-blue-color;
        align-items: center;
        justify-content: center;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
        margin: 0;
        border: 0px;
        @include breakpoint(large) {
            width: 50px;
            height: 38px;
        }
        i {
            @include breakpoint(large) {
                font-size: 22px;
            }
        }
    }
}

.bp-crawler {
    transform: scale(1.5);
    text-align: center;
    div {
        animation: box-crawler .66s infinite ease-in-out;
        background-color: #fff;
        border-radius: 4px;
        display: inline-block;
        height: 10px;
        width: 2px;
    }
    div:nth-child(2) {
        animation-delay: .1s;
        margin-left: 2px;
    }
    div:last-child {
        animation-delay: .2s;
        left: 8px;
        margin-left: 2px;
    }
}

@keyframes box-crawler {
    0%,
    80%,
    to {
        opacity: .5;
        transform: scaleY(1.2)
    }
    40% {
        opacity: 1;
        transform: scaleY(1.6)
    }
}

small {
    font-size: 13px;
}

.min-200 {
    min-width: 200px !important;
}

.min-150 {
    min-width: 150px !important;
}

.min-120 {
    min-width: 120px !important;
}

.min-100 {
    min-width: 100px !important;
}

.btn.sad-icon-btn {
    height: 4.5rem;
    font-size: 1.3rem;
    text-transform: uppercase;
    width: 15.71rem;
    background: #d5dee7;
    overflow: hidden;
    i {
        opacity: 0.3;
        font-size: 6.5rem;
        position: absolute;
        left: -18px;
        top: -13px;
        color: #fff;
    }
}

.btn.smile-icon-btn {
    height: 4.5rem;
    font-size: 1.3rem;
    text-transform: uppercase;
    width: 15.71rem;
    overflow: hidden;
    i {
        opacity: 0.3;
        font-size: 6.5rem;
        position: absolute;
        right: -18px;
        top: -13px;
        color: #fff;
    }
}

.ul-listing {
    display: block;
    position: relative;
    margin: 0px;
    padding: 0px;
    li {
        display: block;
        position: relative;
        margin: 0px 0px 10px 0px;
        ul {
            padding: 0px 0px 0px 30px;
            margin: 10px 0px 0px;
        }
    }
}

.green-checkbox {
    &.mat-checkbox-checked {
        .mat-checkbox-label {
            color: $green !important;
        }
        &.mat-accent {
            .mat-checkbox-background {
                background-color: $green !important;
            }
        }
    }
}

.green-checkbox-cs {
    &.mat-checkbox-checked {
        .mat-checkbox-label {
            color: $black !important;
        }
        &.mat-accent {
            .mat-checkbox-background {
                background-color: $green !important;
            }
        }
    }
}

.receipt-block-inner {
    ol {
        li {
            margin: 0px 0px 15px 0px;
            ol {
                margin: 15px 0px 0px 25px;
            }
        }
    }
}

.form_row {
    .mat-select-value {
        color: $d_black;
    }
}

.row.table-grid-card {
    margin-right: -20px;
    margin-left: -20px;
    .col {
        margin-bottom: 40px;
        padding-right: 20px;
        padding-left: 20px;
    }
    .mat-card-custom {
        min-height: 100%;
        .mat-card-header {
            display: block;
            h3 {
                text-overflow: ellipsis;
                max-width: calc(100% - 33px);
                white-space: nowrap;
                overflow: hidden;
                line-height: normal;
                margin: 0px 0px 5px 0px;
            }
            p {
                margin: 10px 0px;
                color: $light-grey-2;
                strong {
                    color: $black;
                }
            }
            .row-status {
                position: absolute;
                top: 1.5rem;
                right: 1.5rem;
            }
        }
        .mat-card-content {
            a {
                color: $grey-text;
            }
            .city-name {
                p {}
            }
        }
    }
}

.customer-info-outer {
    position: relative;
    .edit-detail {
        position: absolute;
        right: 0px;
        top: 0px;
        height: 2.857rem;
        width: 2.857rem;
        display: flex;
        align-items: center;
        justify-content: center;
        .material-icons {
            font-size: 1.571rem;
            cursor: pointer;
            color: $black;
            background-color: #f2f2f2;
            height: 2.857rem;
            width: 2.857rem;
            opacity: 0.7;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.3s;
            &:hover {
                opacity: 0.9;
            }
        }
    }
    .customer-info-inner {
        display: flex;
        justify-content: space-between;
        text-align: left;
        @include breakpoint(large) {
            flex-wrap: wrap;
            margin: 0px !important;
            justify-content: flex-start;
        }
        .customer-info-list {
            width: 25%;
            @include breakpoint(large) {
                margin-bottom: 20px;
                min-width: 33.33%;
                max-width: 33.33%;
                padding-right: 10px;
                br {
                    display: none;
                }
            }
            @include breakpoint(small) {
                min-width: 50%;
                max-width: 50%;
            }
            @include breakpoint(x-small) {
                min-width: 100%;
                max-width: 100%;
            }
            &.name-info {
                display: -ms-flexbox;
                display: flex;
                -ms-flex-align: center;
                align-items: center;
                position: relative;
                .name-circle {
                    width: 2.857rem;
                    height: 2.857rem;
                    border-radius: 2.857rem;
                    background: #619565;
                    color: #fff;
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-align: center;
                    align-items: center;
                    position: relative;
                    -ms-flex-pack: center;
                    justify-content: center;
                    margin: 0px 10px 0px 0px;
                    font-size: 14px;
                    text-transform: uppercase;
                }
                label {
                    color: #33a67c;
                    margin: 0px 0px 0px 0px;
                }
                p {
                    color: $black;
                    display: block;
                    font-weight: 600;
                    margin: 0px 0px 3px 0px;
                }
            }
        }
        label {
            color: $grey_text;
            font-size: 1rem;
            display: block;
            margin: 0px 0px 8px 0px;
            font-weight: 400;
            line-height: normal;
        }
        p {
            color: $black;
            font-size: 1rem;
            display: block;
            line-height: normal;
            font-weight: 600;
        }
    }
}

.card-header {
    @include flexbox;
    align-items: center;
    position: relative;
    margin: 0px 0px 25px 0px;
    background-color: rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0);
    .card-header-icon {
        width: 2.285rem;
        height: 2.285rem;
        border-radius: 3px;
        background: $blue;
        color: $white;
        @include flexbox;
        align-items: center;
        position: relative;
        justify-content: center;
        margin: 0px 10px 0px 0px;
        i {
            font-size: 1.285rem;
            color: $white;
        }
    }
    .card-header-text {
        font-size: 1.143rem;
        color: $black;
        font-weight: 600;
    }
}

.select_plan_list {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    padding: 0px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    // align-items: flex-start;
    @include breakpoint(small) {
        margin: 20px auto;
        flex-direction: column;
    }
    .plan_box {
        padding: 1.785rem 2.857rem;
        overflow: hidden;
        position: relative;
        margin: 0px 15px;
        width: calc(100% - 30px);
        background: $white;
        border-radius: 5px;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.31);
        text-align: center;
        transition: all 0.3s;
        max-width: 320px;
        padding-bottom: 5.714rem !important;
        &:before {
            position: absolute;
            content: '';
            height: 6px;
            background-color: #5A6276;
            width: 100%;
            left: 0;
            top: 0;
        }
        @include breakpoint(large) {
            padding: 20px 15px;
        }
        @include breakpoint(small) {
            width: 100%;
            margin: 0px 0px 20px 0px;
        }
        &.highlights-package {
            // margin-top:0px;
        }
        h3 {
            @include fluid-font(300px, 1920px, 13px, 14px);
            color: $d_black;
            text-transform: uppercase;
            letter-spacing: 2px;
            margin: 0px 0px 10px 0px;
            font-weight: 400;
            @include breakpoint(large) {
                font-size: 1.2rem;
            }
        }
        h4 {
            font-size: 1.7rem;
            color: $black;
            margin: 10px 0px;
            padding: 0px 0px;
            text-transform: inherit;
            font-weight: 400;
            @include breakpoint(large) {
                font-size: 1.3rem;
            }
        }
        h5 {
            font-size: 1.2rem;
            color: #505050;
            line-height: normal;
            margin: 20px 0px;
            padding: 0px 20px;
            text-transform: inherit;
            font-weight: 600;
            @include breakpoint(large) {
                font-size: 1rem;
            }
        }
        p {
            color: $black;
            margin: 10px 0px 20px 0px;
            &.label {
                color: $green2;
                font-size: 13px;
            }
        }
        .value {
            @include fluid-font(300px, 1920px, 28px, 40px);
            color: $d_black;
            line-height: normal;
            margin: 10px 0px;
            padding: 0px;
            font-weight: 700;
            sup,
            sub {
                color: $light-grey;
                position: relative;
            }
            sup {
                top: 0;
                left: -2px;
                font-size: 18px;
                font-weight: 700;
            }
            sub {
                bottom: 11px;
                font-size: 14px;
                letter-spacing: 2px;
                font-weight: 400;
            }
        }
        .year {
            font-size: 1.143rem;
            margin: 0px 0px 0px 4px;
        }
        .large_btn {
            width: 100%;
            max-width: 150px;
            text-align: center;
            font-size: 1.1rem;
            font-weight: 400;
            cursor: pointer;
            position: absolute;
            bottom: 20px;
            left: 0px;
            right: 0px;
            margin: auto;
            z-index: 1;
        }
        ul {
            margin: 0px 0px 3rem;
            padding: 15px 0px 0px;
            li {
                display: block;
                position: relative;
                text-align: left;
                padding: 5px 0px 5px 32px;
                font-size: 14px;
                color: $d_black;
                line-height: normal;
                margin: 0px;
                text-transform: inherit;
                font-weight: 400;
                border-top: 0;
                @include breakpoint(large) {
                    font-size: 1rem;
                }
                i {
                    position: absolute;
                    left: 0px;
                    top: 8px;
                    font-size: 1.286rem;
                    color: $d_black;
                }
            }
        }
    }
}

.border-1 {
    border-color: #20c2db !important;
}

.border-2 {
    border-color: #756ceb !important;
}

.border-3 {
    border-color: #f04a58 !important;
}

.border-4 {
    border-color: #fbbf2b !important;
}

.border-5 {
    border-color: #30a87a !important;
}

.md_view_change {
    border: 1px solid $input-border;
    border-radius: 3px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    .mat-button {
        border-radius: 0px;
        padding: 0px 10px;
        min-width: inherit;
        color: $input-border;
        &.active {
            background-image: linear-gradient(-68deg, #2ea3f7 0%, #1c4be1 100%);
            color: $white;
        }
        &:last-child {
            border-left: 1px solid $input-border;
        }
    }
}

.search-top-heading {
    .table-search {
        @include breakpoint(medium) {
            max-width: 200px;
            min-width: 200px;
        }
        @include breakpoint(x-small) {
            display: none !important;
        }
    }
    @include breakpoint(medium) {
        width: 100%;
        margin-top: 15px;
    }
    .button-rounded {
        @include breakpoint(small) {
            margin-top: 0;
        }
    }
}

.table-search {
    max-width: 300px;
    width: 100%;
    min-width: 300px;
    .mat-form-field {
        width: 100%;
    }
    .mat-form-field-wrapper {
        margin-bottom: 0;
        padding-bottom: 0;
        border: 1px solid #e7e7e7;
        border-radius: 2px;
        padding: 0 1.2rem;
        background: #fff;
        height: 38px;
        .mat-form-field-flex {
            width: 100%;
            align-items: center;
        }
        .mat-form-field-infix {
            border-top: 0;
            input.mat-input-element {
                padding-left: 0px;
                line-height: 1.6;
                height: 29px;
            }
        }
        .mat-form-field-subscript-wrapper {
            display: none;
        }
        .mat-form-field-underline {
            display: none;
        }
        .mat-form-field-label-wrapper {
            padding-top: 0;
            margin-top: 12px;
            .mat-form-field-label {
                margin-top: -6px;
                margin-left: 0px;
                font-size: 14px;
                height: 30px;
            }
        }
    }
}

.cursor-pointer {
    cursor: pointer;
}

.clearfix {
    clear: both;
}

.clearfix:after {
    content: " ";
    /* Older browser do not support empty content */
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
}

.material-icons {
    font-size: 1.714rem;
}

.action-icon-dots {
    img {
        width: 24px;
    }
}


