.btn.filter_btn {
  box-shadow: none !important;
  background: #f2f2f2;
  height: 40px;
  min-height: 40px !important;
  line-height: 32px !important;
  font-size: 1rem;
  padding: 0px 25px;
  color: $black;
  @include breakpoint(x-small) {
    height: 30px;
    min-height: 30px !important;
    line-height: 30px !important;
  }
  i {
    font-size: 1.429rem;
  }
}

.add_more_btn {
  padding: 0px;
  color: $base1;
  border: 0px;
  background: none;
  font-size: 1rem;
  @include flexbox;
  @include align-items(center);
  i {
    color: $base1;
    margin: 0px 5px 0px 0px;
    font-size: 1.429rem;
  }
}

// shadow
.btn_shadow {
  @include box-shadow(0px 1px 3px rgba(0, 0, 0, 0.1));
}

// buttons
.rounded_btn {
  @include border-radius(4px);
}

.sml_rounded_btn {
  @include border-radius(4px);
}

.btn.vertical_middle_btn {
  display: flex !important;
  @include align-items(center);
  @include align-items(center);
}

.no_btn {
  padding: 10px 0px;
  min-height: 56px;
  font-size: 1.143rem;
  font-weight: 400;
  line-height: 35px;
  background: none;
  color: $grey-text;
  i {
    color: $grey-text;
  }
  @include breakpoint(small) {
    min-height: 50px;
    font-size: 1.071rem;
    padding: 10px 25px;
  }
  @include breakpoint(x-small) {
    min-height: 46px;
    padding: 10px 15px;
  }
}

.btn {
  display: inline-block;
  border: none;
  cursor: pointer;
  text-align: center;
  font-family: $base-font;
  outline: none !important;
  &:hover,
  &:focus,
  &:active {
    outline: none !important;
  }
}

.skip_btn {
  @include flexbox;
  align-items: center;
  position: relative;
  input {
    color: $grey-text;
    background: none;
    font-size: 1.143rem;
    font-weight: 400;
    cursor: pointer;
    padding-right: 40px;
    text-align: left;
    position: relative;
    z-index: 2;
  }
  i {
    -ms-transform: rotate(180deg);
    /* IE 9 */
    -webkit-transform: rotate(180deg);
    /* Safari 3-8 */
    transform: rotate(180deg);
    color: $grey-text;
    margin: 0px 0px 0px 10px;
    position: absolute;
    left: 30px;
    z-index: 1;
  }
  button {
    color: #888888;
    background: none;
    font-size: 1.143rem;
    font-weight: 400;
    cursor: pointer;
    padding-right: 40px;
    text-align: left;
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    font-family: "Roboto";
    border: 0px;
    height: 55px;
    @include breakpoint(x-small) {
      width: 100%;
      text-align: center;
      justify-content: center;
      height: 40px;
      font-size: 1rem;
    }
  }
}

.btn.large_btn {
  &.huge-btn {
    height: 4.285rem;
    @include breakpoint(xxxlarge) {
      height: 3.5rem;
    }
  }
  padding: 0px 2.143rem;
  height: 3.571rem;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 36px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  @include breakpoint(large) {
    // min-height: 40px;
    font-size: 1rem;
    line-height: 30px;
  }
  @include breakpoint(x-small) {
    font-size: 1.071rem;
    padding: 0px 15px;
  }
  .start {
    margin: 0px 8px 0px 0px;
    font-size: 1.286rem;
  }
  .end {
    margin: 0px 0px 0px 8px;
    font-size: 1.286rem;
  }
}

.btn.regular_btn {
  padding: 0px 1.786rem;
  height: 3.285rem;
  @include fluid-font(300px, 1920px, 14px, 16px);
}

.btn.md_btn {
  padding: 0px 1.786rem;
  height: 4.071rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 32px !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  @include breakpoint(large) {
    min-height: 36px;
    padding: 0px 15px;
    line-height: 36px !important;
  }
  @include breakpoint(x-small) {
    font-size: 1rem;
    min-height: 34px;
    line-height: 34px !important;
  }
  .start {
    margin: 0px 8px 0px 0px;
    font-size: 1.5rem;
  }
  .end {
    margin: 0px 0px 0px 8px;
    font-size: 1.5rem;
  }
}

.btn.xs_small_btn {
  padding: 0;
  height: 24px;
  width: 72.98px;
  @include fluid-font(300px, 1920px, 12px, 12px);
  font-weight: 500;
  line-height: 24px !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  .material-icons {
    font-size: 14px;
    // display: none;
  }
  &.green_border_btn {
    .material-icons {
      font-size: 16px;
    }
  }
  .start {
    margin: 0px 8px 0px 0px;
    font-size: 1.5rem;
  }
  .end {
    margin: 0px 0px 0px 8px;
    font-size: 1.5rem;
  }
  &.applied {
    opacity: 0.6;
    .material-icons {
      display: inline-block;
    }
  }
}

.btn.green_btn {
  background-color: $green2;
  color: $white;
  opacity: 1;
  white-space: nowrap;
  @include transition(all ease 0.5s);
  .mat-button-focus-overlay {
    display: none;
  }
  &:hover,
  &:active {
    background-color: $d_green !important;
    color: $white !important;
  }
  &.add-project-btn {
    background: rgb(10, 216, 136);
    background: linear-gradient(
      146deg,
      rgba(10, 216, 136, 1) 0%,
      rgba(55, 186, 119, 1) 100%
    );
    box-shadow: none !important;
    font-size: 1.071rem;
    padding: 6px 20px;
    line-height: 28px !important;
    min-height: inherit;
    font-weight: 500;
    transition: all 0.3s;
    @include breakpoint(x-small) {
      padding: 6px 8px;
      font-size: 0px;
    }
    i {
      margin-right: 5px;
      font-size: 1.714rem;
      @include breakpoint(x-small) {
        margin-right: 0px;
      }
    }
    &:hover {
      background: rgb(10, 216, 136);
      background: linear-gradient(
        146deg,
        rgba(10, 216, 136, 1) 0%,
        rgba(10, 216, 136, 1) 100%
      );
    }
  }
}

.btn.d_green_btn {
  background-color: $green2;
  color: $white;
  min-height: 42px;
  @include transition(all ease 0.5s);
  &:hover {
    background-color: $black;
    color: $white;
    @include transition(all ease 0.5s);
  }
  @include breakpoint(small) {
    min-height: 30px;
  }
}

.btn.red_btn {
  background-color: $red;
  color: $white;
  // height: 40px;
  // font-weight: 400;
  @include transition(all ease 0.5s);
  box-shadow: 1px 0px 3px rgba(0, 0, 0, 0.1);
  &:hover,
  &:focus,
  &:active {
    background-color: #e73d3d;
    color: $white;
    @include transition(all ease 0.5s);
  }
  @include breakpoint(small) {
    min-height: 30px;
  }
}

.btn.small_btn {
  padding: 0px 1rem;
  height: 2.357rem;
  @include fluid-font(300px, 1920px, 13px, 14px);
  font-weight: 500;
  line-height: 2.285rem !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  @include breakpoint(large) {
    min-height: 2.571rem;
    padding: 0px 15px;
    line-height: 2.571rem !important;
  }
  @include breakpoint(x-small) {
    font-size: 1rem;
    min-height: 2.428rem;
    line-height: 2.428rem !important;
  }
  .material-icons {
    font-size: 1.14rem;
    @include breakpoint(x-small) {
      font-size: 1.4rem;
    }
  }
  &.green_border_btn {
    .material-icons {
      font-size: 1.5rem;
    }
  }
  .start {
    margin: 0px 8px 0px 0px;
    font-size: 1.5rem;
  }
  .end {
    margin: 0px 0px 0px 8px;
    font-size: 1.5rem;
  }
}

.btn.cancel_btn {
  background-color: $cancel-color;
  color: $light-grey-2;
  box-shadow: none;
  white-space: nowrap;
  @include transition(all ease 0.5s);
  &:hover,
  &:focus,
  &:active {
    background-color: #deeaf5;
    color: $text-color;
  }
}

.btn.blue_btn {
  background-color: $base1;
  color: $white;
  white-space: nowrap;
  @include transition(all ease 0.5s);
  &:hover,
  &:focus,
  &:active {
    background-color: $dark-base;
    color: $white;
    @include transition(all ease 0.5s);
  }
}

.btn.border_btn {
  background-color: $white;
  border: solid 1px $d_black;
  color: $d_black;
  // height: 3.143rem;
  font-weight: 400;
  @include transition(all ease 0.5s);
  box-shadow: 1px 0px 3px rgba(0, 0, 0, 0.1);
  &:hover {
    background-color: $d_black;
    color: $white;
    @include transition(all ease 0.5s);
  }
  @include breakpoint(small) {
    min-height: 30px;
  }
}

.btn.green_border_btn {
  background-color: $white;
  border: solid 1px $green2;
  color: $green2;
  // height: 44px;
  font-weight: 400;
  @include transition(all ease 0.5s);
  box-shadow: 1px 0px 3px rgba(0, 0, 0, 0.1);
  &:hover {
    background-color: $green2;
    color: $white;
    @include transition(all ease 0.5s);
  }
  @include breakpoint(small) {
    min-height: 30px;
  }
}

.btn.red_border_btn {
  background-color: $white;
  border: solid 2px $red;
  color: $red;
  height: 40px;
  font-weight: 500;
  @include transition(all ease 0.5s);
  box-shadow: 1px 0px 3px rgba(0, 0, 0, 0.1);
  &:hover {
    background-color: $red;
    color: $white;
    @include transition(all ease 0.5s);
  }
  @include breakpoint(small) {
    min-height: 30px;
  }
}

.btn.grey_border_btn {
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.09);
  color: #b5b5b5;
  min-height: 42px;
  white-space: nowrap;
  @include transition(all ease 0.5s);
  &:hover {
    border-color: $para;
    color: $white;
    background: $para;
    @include transition(all ease 0.5s);
  }
  @include breakpoint(small) {
    min-height: 34px;
  }
}

.btn.transparent_btn {
  background-color: transparent;
  border: solid 1px transparent;
  color: $grey-text;
  min-height: 42px;
  align-items: center;
  display: inline-flex;
  white-space: nowrap;
  @include transition(all ease 0.5s);
  i {
    margin: 0px 8px 0px 0px;
  }
  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    border: solid 1px transparent;
    color: $grey-text;
    @include transition(all ease 0.5s);
  }
  @include breakpoint(small) {
    min-height: 34px;
  }
  .cross_icon {
    font-size: 2rem;
    line-height: 0;
    font-weight: 300;
    position: relative;
    bottom: -3px;
  }
}

.btn.white_btn {
  background-color: $white;
  border: solid 1px #e6e8ed;
  color: $grey-text;
  min-height: 42px;
  @include transition(all ease 0.5s);
  &:hover,
  &:focus,
  &:active {
    background-color: $base1;
    border: solid 1px $base1;
    color: $white;
    @include transition(all ease 0.5s);
  }
  .cross_icon {
    font-size: 2rem;
    line-height: 0;
    font-weight: 300;
    position: relative;
    bottom: -3px;
  }
}

.full_btn {
  width: 100%;
}

.icon_btn {
  position: relative;
  i {
    margin: 0px 5px 0px 0px;
    font-size: 1.571rem;
  }
  .mat-icon {
    margin: 0px 5px 0px 0px;
    font-size: 1.571rem;
    display: inline-flex;
    align-items: center;
  }
}

.skip_btn {
  input {
    @include breakpoint(x-small) {
      font-size: 1rem;
    }
  }
  i {
    @include breakpoint(x-small) {
      right: 0px;
      margin: 0px auto;
      width: 30px;
    }
  }
}

.btn-spinner {
  width: 2.857rem;
  height: 2.857rem;
  border-radius: 50%;
  margin: 50px auto;
  border: 0.357rem solid rgba(255, 255, 255, 0.1);
  border-left: 0.357rem solid $white;
  -webkit-animation: spinner 0.8s linear infinite;
  animation: spinner 0.8s linear infinite;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  bottom: 0px;
  @include breakpoint(xxxlarge) {
    width: 2.5rem;
    height: 2.5rem;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    // border-left: 5px solid $green;
  }
}

.noborder-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $base1;
  i {
    margin: 0px 10px 0px 0px;
  }
  &:hover {
    color: $green2;
  }
}
.mat-badge.hide-text .mat-badge-content {
  color: transparent !important;
  size: 1rem !important;
  width: 10px;
  height: 10px;
  line-height: 10px;
}
.mat-badge {
  left: -2px !important;
  top: 4px !important;
}
